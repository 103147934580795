import React, { useEffect, useState } from "react";
import { get } from 'lodash';
import moment from "moment";
import { Spinner, Button } from "reactstrap";

import { closeIcon, recorded, edit, save } from "../../../utils/iconStatics";
import { API_HOST } from "../../../utils/constants";
import { requestApi } from "../../../utils/requestHelper";
import AvtDefault from "../../../assets/images/defaultImage.png";
import { convertPhoneHref } from "../../../utils/functionHelper";

const ModalDetail = (props) => {
  const { data, toggle, callTypeIconMapping } = props;
  const attributes = data.attributes || {};

  const [contactDetail, setContactDetail] = useState(null);
  const [loading, setLoading] = useState(false)
  const [isEditMemo, setEditMemo] = useState(false);
  const [memo, setMemo] = useState('');

  useEffect(() => {
    if (attributes.sipCallId) {
      const url = `${API_HOST}contact/detail/${attributes.sipCallId}`;
      // setLoading(true);
      setContactDetail(null);
      requestApi(url).then(result => {
        if (!result.error && !result.message) {
          const detail = result.body && result.body.length ? result.body[0] : null;
          setContactDetail(detail);
        }
        // setLoading(false);
      })
    }
  }, []);

  const handleUpdateMemo = () => {
    setEditMemo(false);
    if (!data.id || !memo || memo === attributes.memo) {
      return;
    }
    setLoading(true);
    const url = `${API_HOST}history`;
    requestApi(url, 'PUT', JSON.stringify({ id: data.id, memo })).then(result => {
      setLoading(false);
      if (!result.error && !result.message) {
        props.onUpdateHistorySuccess(data.id, { memo });
      }
    })
  }

  const handleCancelMemo = () => {
    setMemo('')
    setEditMemo(false)
  }

  return (
    <div className="modal-detail">
      <div className="modal-detail__close" onClick={toggle}>{closeIcon()}</div>

      {loading ? <div className="text-center" style={{ marginTop: "20px" }}>
        <Spinner animation="grow" variant="info" />
      </div> : (
        <>
          <div className="modal-detail__contact">
            <img src={get(contactDetail, 'attributes.image', '') || AvtDefault} />
            <div className="modal-detail__contact__info">
              <div className="modal-detail__contact__info__name">{attributes.remoteDisplayName}</div>
              <a href="#"
                onClick={() => { props.handleDialPhone(attributes.remoteNumber, {}, "") }}
                className="modal-detail__contact__info__phone">{attributes.remoteNumber}</a>
            </div>
          </div>

          <div className="modal-detail__history">
            <div className="talking-date">{attributes.talkingStartDate && moment(attributes.talkingStartDate).format('YYYY/MM/DD HH:mm:ss')}</div>
            <div className="modal-detail__history__info">
              <div>
                <div className="modal-detail__history__info__contact"><span className={`name ${attributes.callType === 'missed' ? 'missed' : ''}`}>{attributes.remoteDisplayName}</span>
                  <a href="#"
                    onClick={() => { props.handleDialPhone(attributes.remoteNumber, {}, "") }}>{` (${attributes.remoteNumber})`}</a></div>
                <div>
                  <div>{callTypeIconMapping[attributes.callType]}</div>
                </div>
              </div>
              <div className="modal-detail__history__info__talking-duration">{attributes.talkingDuration ? new Date(attributes.talkingDuration * 1000).toISOString().substr(11, 8) : ''}</div>
            </div>
            {attributes.recordFileName && (
              <div className="modal-detail__history__record-file">
                <div>{recorded()}</div>
                <div className="modal-detail__history__record-file__file-name">{attributes.recordFileName}</div>
              </div>
            )}
            {attributes.memo && (
              <div className="modal-detail__history__memo form-group">
                <textarea className="form-control" disabled={!isEditMemo} rows={5} value={memo || attributes.memo} onChange={(e) => setMemo(e.target.value)} />
                {!isEditMemo ? (
                  <div className="modal-detail__history__memo__icon" onClick={() => setEditMemo(true)}>{edit()}</div>
                ) : (
                  <div className="modal-detail__history__memo__icon">
                    <div className="" onClick={handleUpdateMemo}>{save()}</div>
                    <div className="" onClick={handleCancelMemo}>{closeIcon()}</div>
                  </div>
                )}

              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ModalDetail;