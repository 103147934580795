import { useEffect, useState } from "react";
import { iconCamera, iconMessage, iconRemoveAvt } from "../utils/iconStatics";
import md5 from 'md5';
const Avatar = ({ contact, style, filePath, edit, onRemove, colorAvatar, isMessage }) => {
  const [error, setError] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false)
  const { image, firstName, lastName, email } = contact.attributes;
  const abb = `${lastName}${firstName}`.slice(0, 2);
  let showImageUrl = !!image && !edit;
  let showImageFilePath = edit && !!filePath;
  let showEditImage = edit && showEdit;
  let showGravatar = ((!image && !edit) || (!filePath && edit)) && !!email && !error;
  let showNameAvt = (((!image && !edit) || (!filePath && edit)) && !email) || error ||
    (!showImageUrl && !showEditImage && loading)

  return (
    <div className="avatar" onMouseOver={() => {
      if (isMessage)
        setShowMessage(true)
      setShowEdit(true)
    }} onMouseLeave={() => {
      setShowMessage(false)
      setShowEdit(false)
    }}>
      {showImageUrl && <img src={image} style={{ ...style, border: `3px solid ${colorAvatar}` }}></img>}
      {showImageFilePath && <img src={filePath} style={{ ...style, border: `3px solid ${colorAvatar}` }}></img>}
      {showGravatar && <img
        style={{ ...style, border: `3px solid ${colorAvatar}`, display: loading ? 'none' : 'block' }}
        src={`https://www.gravatar.com/avatar/${md5(email, { encoding: "binary" })}?d=404`}
        onError={(e) => {
          setError(true)
          setLoading(false)
        }}
        onLoad={() => {
          setLoading(false)
        }}
      />}
      {showNameAvt && !showImageFilePath && !showImageUrl &&
        <div className="avatar__name" style={colorAvatar ? { ...style, background: colorAvatar } : { ...style }}>
          {abb}
        </div>}
      {showEditImage && <div className="avatar__action">
        {iconCamera()}
        <div className="avatar__action__remove">
          {!!filePath &&
            <div className="icon" onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}>
              {iconRemoveAvt()}
            </div>
          }
        </div>
      </div>}
      {showMessage && <div className="avatar__action icon-white">
        {iconMessage()}
      </div>}
    </div>
  )
}
export default Avatar;