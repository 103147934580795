import Avatar from "./Avatar"
import "./PopupCalling.scss"
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useEffect, useState } from "react";
import { API_HOST, CONTACT_CALLING_STORAGE } from "../utils/constants";
import { iconEndCall, iconHolding, iconNotHolding, iconUnMute, iconMute, iconPhoneCall } from "../utils/iconStatics";
import { decrypt, encrypt } from '../utils/xTea';
import { msToTime } from "../utils/functionHelper";
import { requestApi } from "../utils/requestHelper";
import { isEmpty } from "lodash";

const PopupCalling = ({ showDial, toggle, holding, mute, setMute, numberCalling, timeCall, callEventStatus, remoteUser, incoming }) => {
  const [attributes, setAttributes] = useState({})
  const [duration, setDuration] = useState(0);
  const [color, setColor] = useState('');
  const [loading, setLoading] = useState(false)
  let interval;
  const fetchContactByNumber = () => {
    setLoading(true);
    const url = `${API_HOST}/contact/phone/search?${new URLSearchParams({ phoneNumber: numberCalling })}`;
    requestApi(url).then(result => {
      result.sort(function (a, b) {
        return a.attributes.lastName.localeCompare(b.attributes.lastName, 'en');
      })
      setLoading(false);
      if (!result.error && !result.message) {
        const contact = result.length && result[0] || {};
        if (contact.subId) {
          if (contact.subId.includes('@Private')) {
            setColor("#8d8d8d");
          }
          else {
            setColor("#138eff");
          }
        }
        if (isEmpty(attributes)) {
          setAttributes(contact.attributes || {})
        }
      }
    })
  }
  useEffect(() => {
    if (numberCalling)
      fetchContactByNumber();
  }, [numberCalling])
  useEffect(() => {
    if (!showDial)
      setAttributes({});
  }, [showDial])
  useEffect(() => {
    if (showDial) {
      setDuration(1000);
      interval = setInterval(() => {
        setDuration(new Date().getTime() - timeCall);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setDuration(0);
      return () => clearInterval(interval);
    }
  }, [timeCall])
  return (
    <Modal show={showDial} onHide={toggle} className="popup-calling">
      <ModalBody>
        <div className="popup-calling__content">
          <Avatar contact={{ attributes }} colorAvatar={color}></Avatar>
          <div className="popup-calling__content__info">
            {!loading ? attributes.lastName || attributes.firstName ? <div className="name">
              {`${attributes.lastName || ""} ${attributes.firstName || ""}`}&nbsp;&nbsp;{`(${numberCalling})`}
            </div> : <div className="name">{remoteUser} &nbsp;&nbsp;{numberCalling === remoteUser ? '' : `(${numberCalling})`}</div> : null}
            {attributes.organization || attributes.department || attributes.title ?
              <div className="company">{`${attributes.organization || ""} ${attributes.department || ""} ${attributes.title || ""}`}</div> : null}
          </div>
          <div className="popup-calling__content__timer">
            {callEventStatus ? <div className="timer">{callEventStatus}</div> : !!timeCall && <div className="timer">{msToTime(duration)}</div>}
            <div className="popup-calling__content__icons">
              {incoming && <div className="popup-calling__content__icon -accept" onClick={() => {
                window.ws.send(encrypt(`answer`, 'key'));
              }}>
                {iconPhoneCall()}
              </div>}
              <div className={`popup-calling__content__icon ${mute ? '' : 'active'}`} onClick={() => {
                setMute(!mute);
                window.ws.send(encrypt(mute ? `micunmute` : `micmute`, 'key'));
              }}>
                {mute ? iconMute() : iconUnMute()}
              </div>
              <div className={`popup-calling__content__icon ${holding ? 'active' : ""}`} onClick={() => {
                window.ws.send(encrypt(`hold`, 'key'));
              }}>{holding ? iconNotHolding() : iconHolding()}</div>
              <div className="popup-calling__content__icon -danger" onClick={toggle}>{iconEndCall()}</div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
export default PopupCalling;