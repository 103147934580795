import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
const TooltipDenwa = ({ children, title, placement }) => {
  const renderTool = (props) => (
    <Tooltip {...props}>
      {title}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement={placement || "bottom"}
      overlay={renderTool}
    >
      {children}
    </OverlayTrigger>
  )
}
export default TooltipDenwa;