import { LOGIN, LOGIN_FAILED, LOGIN_SUCCESS } from '../actions/user.action';
const initialState = {
  user: {},
  loading: false
};
export default function loadUser(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, {
        user: {},
        loading: true
      });
    case LOGIN_FAILED:
      return Object.assign({}, state, {
        loading: false
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        user: action.payload.user,
        loading: false
      })
    default:
      return state;
  }
}
