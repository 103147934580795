import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

const ModalDelete = (props) => {
  const [confirmNested, setConfirmNested] = useState(false);
  return (
    <Modal show={props.show} className={props.className}>
      <ModalBody>
        {confirmNested ? props.nestedTitle : props.title || props.t("reallySure")}
      </ModalBody>
      {props.isGroup ?
        !confirmNested ?
          <ModalFooter>
            <Button variant="danger" onClick={() => {
              if (props.isExistNestedGroup)
                setConfirmNested(true);
              else
                props.confirm();
            }}>{props.t("deleteEntireGroup")}</Button>
            <Button variant="outline-danger" onClick={props.confirmGroup}>{props.t("leaveGroup")}</Button>
            <Button variant="secondary" onClick={props.toggle}>{props.t("cancel")}</Button>
          </ModalFooter> : <ModalFooter>
            <Button variant="danger" onClick={props.confirm}>{props.t("yes")}</Button>
            <Button variant="secondary" onClick={() => { setConfirmNested(false) }}>{props.t("no")}</Button>
          </ModalFooter>
        : <ModalFooter>
          <Button variant="danger" onClick={props.confirm}>{props.t("yes")}</Button>
          <Button variant="secondary" onClick={props.toggle}>{props.t("no")}</Button>
        </ModalFooter>
      }
    </Modal>
  )
}
export default withTranslation('common')(ModalDelete);