export function returnResult(url, options) {
  return fetch(url, options)
    .then(function handleErrors(res) {
      if ((res.status === 401 || res.status === 403) && !window.location.pathname.includes("/view")) {
        localStorage.removeItem("AccessToken");
        window.location.href = "/login";
      }
      return res.json().then(json => {
        if (res.status === 401 || res.status === 403)
          json.statusCode = res.status;
        return json
      });
    }).catch(error => {
      return { error: error }
    })
}

export function requestApi(url, method = "GET", body, contentType = "application/json") {
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", contentType);
  if (localStorage.getItem("AccessToken")) {
    headers.append("Authorization", localStorage.getItem("AccessToken"))
  }
  if (method === "GET") {
    return returnResult(url, {
      method,
      headers,
    });
  }
  return returnResult(url, {
    method,
    headers,
    body
  });
}

export function apiRequestFilePost(url, files, body, method = "POST") {
  const formData = new FormData();
  const headers = new Headers();
  if (localStorage.getItem("AccessToken")) {
    headers.append("Authorization", localStorage.getItem("AccessToken"))
  }
  if (files) {
    for (let key in files) {
      files[key].map(f => {
        if (f) {
          formData.append(key, f, f.name);
        } else {
          formData.append(key, 'null');
        }
      });
    }
  }
  for (let key in body) {
    formData.append(key, body[key] || (`${body[key]}` === "false" && `${body[key]}`) || null)
  }
  const options = {
    method,
    headers,
    body: formData
  };
  return returnResult(url, options)
}

export function convertObjectsToParams(requestBody) {
  let param = '';
  if (requestBody) {
    param = Object.keys(requestBody).map(function (k) {
      return k + '=' + requestBody[k];
    }).join('&');
  }
  return param;
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getToken() {
  return localStorage.getItem("AccessToken");
}
