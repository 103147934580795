import React, { useEffect, useState } from "react";
import {
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { toast } from "react-toastify";
import { API_HOST } from "../../../utils/constants";
import { apiRequestFilePost, requestApi } from "../../../utils/requestHelper";
import Dropzone from "react-dropzone";
import Dimmer from "../../../shared/Dimmer";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { jsonToCSV } from "../../../utils/functionHelper";
import moment from "moment";
import { withTranslation } from 'react-i18next';

const ModalBlackList = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingDimmer, setLoadingDimmer] = useState(false);
  const [dialList, setDialList] = useState([]);

  const fetchDialList = () => {
    const url = `${API_HOST}dial-list`;
    setLoading(true);
    requestApi(url).then(result => {
      setLoading(false)
      if (!result.error && !result.message) {
        setDialList(result);
      }
    })
  }
  useEffect(() => {
    if (props.show) {
      fetchDialList();
    }
  }, [props.show])

  const onDropItem = async (files) => {
    const file = files.length && files[0] || null;
    if (file) {
      if (!file.name.includes(".csv"))
        return toast.error(props.t("csvIncorrect"));
      setLoadingDimmer(true);
      const url = `${API_HOST}admin/import-csv-to-dial-list`;
      apiRequestFilePost(url, { file: [file] }, {}, "POST").then(result => {
        setLoadingDimmer(false);
        if (!result.error && !result.message) {
          toast.success(props.t("dialSucceedMessage"));
          props.fetchDialList();
          props.toggle();
        } else {
          toast.error(props.t(`${result.error}`));
        }
      })
    }
  }

  const removeDialList = () => {
    const url = `${API_HOST}admin/history-dial-list`;
    setLoadingDimmer(true);
    requestApi(url, 'DELETE', JSON.stringify({})).then(result => {
      setLoadingDimmer(false)
      if (!result.error && !result.message) {
        toast.success(props.t("dialDeletedMessage"));
        props.removeDialList();
        props.toggle();
      }
    })
  }

  const exportDialListToCsv = () => {
    const url = `${API_HOST}dial-list`;

    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        const csvData = result.map(item => ({
          id: item.id || '',
          callType: item.attributes.callType || '',
          localDisplayName: item.attributes.localDisplayName || '',
          localNumber: item.attributes.localNumber || '',
          localUserId: item.attributes.localUserId || '',
          memo: item.attributes.memo || '',
          recordFileName: item.attributes.recordFileName || '',
          remoteDisplayName: item.attributes.remoteDisplayName || '',
          remoteNumber: item.attributes.remoteNumber || '',
          sipCallId: item.attributes.sipCallId || '',
          talkingDuration: item.attributes.talkingDuration || '',
          talkingStartDate: item.attributes.talkingStartDate || '',
          tenantId: item.attributes.tenantId || '',
        }));

        const csv = jsonToCSV(csvData);
        const fileName = `dial-list_${moment().format("YYYY-MM-DD-HH:mm:ss")}`;
        const uri = "data:text/csv;charset=utf-8," + encodeURI(csv);
        const link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        props.toggle();
        toast.success(props.t("dialExportSucceedMessage"));
      } else {
        toast.error(props.t(`${result.error}`));
      }
    })
  }

  return (
    <Modal isOpen={props.show} className="modal-csv">
      <ModalHeader>
        {props.t("uploadDialListMessage")}
      </ModalHeader>
      <ModalBody>
        {loading && <div className="text-center" style={{ marginTop: "20px" }}>
          <Spinner animation="grow" variant="info" />
        </div>}
        {loadingDimmer && <Dimmer />}
        {!loading && <div className="blacklist-csv">
          <Dropzone onDrop={acceptedFiles => onDropItem(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>

                <Button variant="outline-primary">{props.t("importCsv")}</Button>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
          <Button disabled={dialList.length === 0} variant="outline-info" onClick={exportDialListToCsv} >{props.t("exportCsv")}</Button>
          <Button disabled={dialList.length === 0} variant="danger" onClick={removeDialList} >{props.t("deleteCsv")}</Button>
        </div>}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => { props.toggle() }}>{props.t("cancel")}</Button>
      </ModalFooter>
    </Modal>
  )
}
export default withTranslation('common')(ModalBlackList);