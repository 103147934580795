import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Spinner } from "reactstrap";
import { API_HOST, CONTACT_CALLING_STORAGE } from "../../../utils/constants";
import { apiRequestFilePost, requestApi } from "../../../utils/requestHelper";
import { validateEmail, validatePassword, validateRequired } from "../../../utils/validateForm";
import Dropzone from "react-dropzone";
import { closeIcon, plusIcon, trashIcon } from "../../../utils/iconStatics";
import "./Detail.scss";
import Avatar from "../../../shared/Avatar";
import moment from 'moment'
import { convertCountry, convertPhoneHref } from "../../../utils/functionHelper";
import PopupCalling from "../../../shared/PopupCalling";
import { decrypt, encrypt } from '../../../utils/xTea';
import Style from 'style-it';
import { withTranslation } from 'react-i18next';
import countries from "../../../assets/countries.json";
import TooltipDenwa from "../../../shared/TooltipDenwa";

const Detail = ({ history, match, t }) => {
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [groups, setGroups] = useState([]);
  let [contact, setContact] = useState(null);
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  let [newGroups, setNewGroups] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [histories, setHistories] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [groupSelected, setGroupSelected] = useState(groups.length ? groups[0].id : "");
  let [addresses, setAddresses] = useState([])
  let [imageInfo, setImageInfo] = useState({
    file: null,
    filePath: ""
  })
  let [unAuth, setUnAuth] = useState(false)
  const [defaultName, setDefaultName] = useState({ firstName: "", lastName: "" })
  const [user, setUser] = useState({});

  const [contactDial, setContactDial] = useState({ contact: {}, color: "", numberCalling: "" })
  const [showDial, setShowDial] = useState(false)
  const [holding, setHolding] = useState(false)
  const [tenant, setTenant] = useState({});
  const [mute, setMute] = useState(false)
  const [timeCall, setTimeCall] = useState(null)
  const [callEventStatus, setCallEventStatus] = useState('')
  const [remoteUser, setRemoteUser] = useState('')

  const [tenantStyle, setTenantStyle] = useState("");
  const [incoming, setIncoming] = useState(false)
  const [groupsParent, setGroupsParent] = useState([]);
  const convertTenantStyle = (tenant) => {
    let style = "";
    const attributes = tenant.attributes || {};
    if (attributes.linkedColor) {
      style = `a {
        color: ${attributes.linkedColor} !important;
      }
      `
    }
    if (attributes.buttonColor) {
      style += `.button-primary {
        background: ${attributes.buttonColor} !important;
        background-color: ${attributes.buttonColor} !important;
        border: 1px solid ${attributes.buttonColor} !important;
      }
      .button-light {
        background: ${attributes.buttonColor}66 !important;
        background-color: ${attributes.buttonColor}66 !important;
        border: 1px solid ${attributes.buttonColor} !important;
        color: ${attributes.buttonColor} !important;
      }`
    }
    if (attributes.selectedColor) {
      style += `.item.active {
        background: ${attributes.selectedColor} !important;
      }
      `
    }
    if (attributes.linkedColorDark || attributes.buttonColorDark || attributes.selectedColorDark) {
      style += "@media (prefers-color-scheme: dark) {"
      if (attributes.linkedColorDark) {
        style += `a {
                color: ${attributes.linkedColorDark} !important;
              }
              `
      }
      if (attributes.buttonColorDark) {
        style += `.button-primary {
          background: ${attributes.buttonColorDark} !important;
          background-color: ${attributes.buttonColorDark} !important;
          border: 1px solid ${attributes.buttonColorDark} !important;
        }
        .button-light {
          background: ${attributes.buttonColorDark}66 !important;
          background-color: ${attributes.buttonColorDark}66 !important;
          border: 1px solid ${attributes.buttonColorDark} !important;
          color: ${attributes.buttonColorDark} !important;
        }`
      }

      if (attributes.selectedColorDark) {
        style += `.item.active {
          background: ${attributes.selectedColorDark} !important;
        }
        `
      }
      style += "}";
    }
    setTenantStyle(style)
  }

  const fetchUser = () => {
    const url = `${API_HOST}user/token/detail`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setUser(result)
      }
    })
  }
  const fetchTenant = () => {
    const url = `${API_HOST}tenant/detail`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        const tenantResult = result.length ? result[0] : {}
        convertTenantStyle(tenantResult);
        setTenant(tenantResult)
        if (tenantResult.attributes && tenantResult.attributes.enableConnect) {
          wsstart();
        }
      }
    })
  }

  const handleStatusText = (state) => {
    if (state == 1) {
      setIncoming(true)
      setCallEventStatus("着信中")
    } else if (state == 2) {
      setIncoming(false)
      setCallEventStatus("発信中")
    } else if (state == 3) {
      setIncoming(false)
      setCallEventStatus("通話接続中")
    } else if (state == 5) {
      setIncoming(false)
      setCallEventStatus("切断")
    } else if (state == 6) {
      setIncoming(false)
      setCallEventStatus("保留")
    } else if (state != 0) {
      setIncoming(false)
      setCallEventStatus('')
    }
  }

  const wsstart = () => {
    const ws = new WebSocket('ws://' + '127.0.0.1' + ':5062', 'agephone');
    window.ws = ws;
    ws.onclose = function () {
      setTimeout(function () {
        wsstart()
      }, 3000);
    };
    ws.onmessage = function (n) {
      var message = decrypt(String(n.data), 'key');
      var msg = message.split('\t');
      if (msg[0] == 'stat') {
        if (!contactDial.numberCalling)
          setContactDial({ ...contactDial, numberCalling: msg[3] })
        let callstate = Math.floor(msg[2]);
        handleStatusText(callstate);
        if (!remoteUser) {
          setRemoteUser(msg[4]);
        }
        if (callstate == 5) {
          localStorage.removeItem(CONTACT_CALLING_STORAGE)
          if (msg[12] === "404") {
            setTimeout(function () {
              setHolding(false)
              setShowDial(false)
              setContactDial({ ...contactDial, numberCalling: '' })
            }, 3000);
          } else {
            setHolding(false)
            setTimeCall(null)
            setShowDial(false)
            setContactDial({ ...contactDial, numberCalling: '' })
          }
        }
        else if (callstate != 5 && !contactDial.showDial) {
          setShowDial(true)
        }
        if (callstate == 4) {
          setTimeCall(new Date().getTime())
        }
        if (callstate == 6 || callstate == 4) {
          if (msg[11] === '1') {
            setCallEventStatus("保留")
            setHolding(true);
          } else if (msg[11] === '0') {
            setCallEventStatus("")
            setHolding(false)
          }
        }
      }
    };
  }

  useEffect(() => {
    convertTenantStyle(tenant);
  }, [tenant])

  const handleDialPhone = (number, contact, colorAvatar) => {
    if (tenant.attributes && tenant.attributes.enableConnect && window.ws && window.ws.readyState === 1) {
      setContactDial({ contact, color: colorAvatar })
      localStorage.setItem(CONTACT_CALLING_STORAGE, JSON.stringify({ contact, color: colorAvatar }))
      window.ws.send(encrypt(`dial ${convertPhoneHref(number, user.attributes || {}, true)}`, 'key'));
    } else {
      window.location.href = convertPhoneHref(number, user.attributes || {})
    }
  }
  useEffect(() => {
    let allow = new URLSearchParams(history.location.search).get("edit");
    fetchUser();
    setAllowUpdate(allow === "on");
    fetchGroups();
    fetchContactByNumber();
    fetchTenant();
  }, [])
  const fetchGroups = () => {
    const url = `${API_HOST}group/user`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setGroups(result);
        const parent = result.filter(g => { return !g.attributes.groupIndex });
        setGroupsParent(parent);
      }
    })
  }
  const fetchHistory = () => {
    const url = `${API_HOST}history?${new URLSearchParams({ numbers: [match.params.number].join(','), limit: 10 })}`;
    setHistories([])
    setLoadingHistory(true)
    requestApi(url).then(result => {
      setLoadingHistory(false)
      if (!result.error && !result.message) {
        setHistories(result);
      }
    })
  }
  const fetchContactByNumber = () => {
    const { number } = match.params;
    const url = `${API_HOST}/contact/phone/search?${new URLSearchParams({ phoneNumber: number })}`;
    setLoading(true);
    requestApi(url).then(result => {
      setLoading(false);
      if (!result.error && !result.message) {
        const name = new URLSearchParams(history.location.search).get("name");
        result.sort(function (a, b) {
          return a.attributes.lastName.localeCompare(b.attributes.lastName, 'en');
        })
        if (!result.length)
          setIsEdit(true);
        if (name) {
          let nameSplit = name.split(" ");
          setDefaultName({ firstName: nameSplit[0], lastName: nameSplit.length > 1 && nameSplit[1] || "" })
        }
        setContact(result.length && result[0] || {})
      }
      else if (result.statusCode === 401 || result.statusCode === 403) {
        setUnAuth(true)
      }
    })
  }
  const onChangeAddressAttr = (value, name, i) => {
    addresses[i][name] = value;
    setAddresses([...addresses])
  }
  const submitForm = (event, errors, values) => {
    if (!errors.length) {
      if (contact.id)
        updateContact(values)
      else
        createContact(values)
    }
  }
  const onDropItem = async (files) => {
    const file = files.length && files[0] || null;
    if (file) {
      if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg")
        return toast.error(t("imageIncorrect"));
      await Promise.all(files.map(async (file) => {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = await (e => {
          let filePath = e.target.result;
          let image = { filePath, file };
          setImageInfo(image);
        });
      }))
    }
  }
  const updateContact = (values) => {
    const url = `${API_HOST}contact`;
    const files = imageInfo.file ? { image: [imageInfo.file] } : null
    const phoneNumbers = [{ type: "phoneNumber", number: values.phoneNumber }, { type: "extensionNumber", number: values.extensionNumber }, { type: "mobileNumber", number: values.mobileNumber }];
    const addressesUpdate = addresses.map(add => {
      delete add.index;
      return add;
    })
    let body = {
      id: contact.id,
      firstName: values.firstName,
      lastName: values.lastName,
      department: values.department,
      email: values.email,
      memo: values.memo,
      organization: values.organization,
      phoneticLastName: values.phoneticLastName,
      phoneticFirstName: values.phoneticFirstName,
      groups: JSON.stringify(newGroups),
      addresses: JSON.stringify(addressesUpdate),
      phoneNumbers: JSON.stringify(phoneNumbers)
    }
    apiRequestFilePost(url, files, body, "PUT").then(result => {
      if (!result.error && !result.message) {
        body.groups = newGroups;
        body.phoneNumbers = phoneNumbers;
        body.image = imageInfo.filePath || contact.image;
        body.addresses = addresses;
        contact.attributes = { ...contact.attributes, ...body };
        setContact({ ...contact });
        setIsEdit(false);
      } else {
        toast.error(t(`${result.error}`));
      }
    })
  }

  const createContact = (values) => {
    const url = `${API_HOST}contact`;
    const files = imageInfo.file ? { image: [imageInfo.file] } : null
    const phoneNumbers = [{ type: "phoneNumber", number: values.phoneNumber }, { type: "extensionNumber", number: values.extensionNumber }, { type: "mobileNumber", number: values.mobileNumber }];
    let body = {
      firstName: values.firstName,
      lastName: values.lastName,
      department: values.department,
      email: values.email,
      memo: values.memo,
      organization: values.organization,
      phoneticLastName: values.phoneticLastName,
      phoneticFirstName: values.phoneticFirstName,
      groups: JSON.stringify(newGroups),
      addresses: JSON.stringify(addresses),
      phoneNumbers: JSON.stringify(phoneNumbers)
    }
    apiRequestFilePost(url, files, body, "POST").then(result => {
      if (!result.error && !result.message) {
        body.groups = newGroups;
        body.phoneNumbers = phoneNumbers;
        body.image = imageInfo.filePath || contact.image || "";
        body.addresses = addresses;
        contact = { id: result.id, attributes: body };
        setContact({ ...contact });
        setIsEdit(false);
      } else {
        toast.error(t(`${result.error}`));
      }
    })
  }

  const convertGroups = (ids) => {
    let names = "";
    ids.map(id => {
      const findGroup = groups.find(g => { return g.id === id });
      if (findGroup) names += `${findGroup.attributes.groupName}, `;
    })
    names = names.slice(0, -2);
    return names;
  }

  const submitFormLogin = (event, errors, values) => {
    if (!errors.length) {
      const url = `${API_HOST}user/login`;
      requestApi(url, "POST", JSON.stringify(values)).then(result => {
        if (!result.message && !result.error) {
          localStorage.setItem("AccessToken", result.token);
          let allow = new URLSearchParams(history.location.search).get("edit");
          setUnAuth(false);
          setAllowUpdate(allow === "on");
          fetchGroups();
          fetchContactByNumber();
        } else {
          toast.error(t(`${result.error}`));
        }
      })
    }
  }

  const renderGroupOptions = (group) => {
    const parents = groups.filter(g => { return g.attributes.parent === group.id });
    const groupsArchitecture = () => {
      if (group.attributes.groupIndex === 1) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else if (group.attributes.groupIndex === 2) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else if (group.attributes.groupIndex === 3) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else {
        return <option value={group.id}>{group.attributes.groupName}</option>
      }
    }
    return (
      <>
        {groupsArchitecture()}
        {parents.map(g => {
          return renderGroupOptions(g);
        })}
      </>
    )
  }

  const existAttributes = contact && contact.attributes;
  const mobileNumber = existAttributes && contact.attributes.phoneNumbers && contact.attributes.phoneNumbers.find(p => { return p.type == "mobileNumber" }) || {}
  const extensionNumber = existAttributes && contact.attributes.phoneNumbers && contact.attributes.phoneNumbers.find(p => { return p.type == "extensionNumber" }) || {}
  const phoneNumber = existAttributes && contact.attributes.phoneNumbers && contact.attributes.phoneNumbers.find(p => { return p.type == "phoneNumber" }) || {}

  return (
    <div className="detail-container">
      {!!tenantStyle && <Style>
        {tenantStyle}
      </Style>}
      {unAuth ? <AvForm className="login-page__left" onSubmit={submitFormLogin}>
        <div className="form">
          <div className="form-title">ログイン</div>
          <AvField name="email" validate={{ ...validateEmail, ...validateRequired }} label={t("email")}></AvField>
          <AvField type="password" name="password" validate={{ ...validatePassword, ...validateRequired }} label={t("password")}></AvField>
          <div className="form__button">
            <Button className="button-primary" type="submit">ログイン</Button>
          </div>
        </div>
      </AvForm> :
        <div className="detail-page">
          {loading && <div className="text-center" style={{ marginTop: "20px" }}>
            <Spinner animation="grow" variant="info" />
          </div>}
          {contact && !isEdit &&
            <>
              <div className="detail-page__left">
                <div className="detail-page__left__top">
                  <div>
                    <div><a href="#" onClick={() => handleDialPhone(match.params.number, contact, "")}>{match.params.number}</a></div>
                    <div>{convertGroups(existAttributes && contact.attributes.groups || [])}</div>
                  </div>
                  {allowUpdate && <div className="button-light detail-mobile" onClick={() => {
                    setIsEdit(true);
                    setNewGroups(existAttributes ? [...contact.attributes.groups] : null);
                    setAddresses(existAttributes ? [...contact.attributes.addresses] : null);
                    setImageInfo({ filePath: existAttributes && contact.attributes.image || "", file: null })
                  }}>
                    {t("edit")}
                  </div>}
                </div>
                <div className="detail-page__left__info">
                  <Avatar contact={existAttributes ? contact : { attributes: {} }} colorAvatar={contact.subId && contact.subId.includes("Public") ? "#138eff" : "#8d8d8d"} style={{ width: "150px", height: "150px", fontSize: "55px" }}></Avatar>
                  <div className="text-name">{`${existAttributes && contact.attributes.lastName || ""} ${existAttributes && contact.attributes.firstName || ""}`}</div>
                  <div className="text-group">{`${existAttributes && contact.attributes.phoneticLastName || ""} ${existAttributes && contact.attributes.phoneticFirstName || ""}`}</div>
                </div>
                <div>{existAttributes && contact.attributes.organization || ""}</div>
                <div>{existAttributes && contact.attributes.department || ""}</div>
                <div>{existAttributes && contact.attributes.title || ""}</div>
                <div>{existAttributes && contact.attributes.division || ""}</div>
                <div>{existAttributes && contact.attributes.sections || ""}</div>
              </div>
              <div className="detail-page__right">
                {allowUpdate && <div className="button-light detail-desktop" onClick={() => {
                  setIsEdit(true);
                  setNewGroups(existAttributes ? [...contact.attributes.groups] : null);
                  setAddresses(existAttributes ? [...contact.attributes.addresses] : null);
                  setImageInfo({ filePath: existAttributes && contact.attributes.image || "", file: null })
                }}>
                  {t("edit")}
                </div>}
                <div className="detail-page__attributes">
                  <div className="detail-page__attributes__item" style={{ marginBottom: "10px" }}>
                    <div className="detail-page__attributes__item__title">{t("mobile")}</div>
                    <div className="detail-page__attributes__item__content">
                      <a href="#" onClick={() => handleDialPhone(mobileNumber.number, contact, "")}>{mobileNumber.number}</a>
                    </div>
                  </div>
                  <div className="detail-page__attributes__item" style={{ marginBottom: "10px" }}>
                    <div className="detail-page__attributes__item__title">{t("extension")}</div>
                    <div className="detail-page__attributes__item__content">
                      <a href="#" onClick={() => handleDialPhone(extensionNumber.number, contact, "")}>{extensionNumber.number}</a>
                    </div>
                  </div>
                  <div className="detail-page__attributes__item" style={{ marginBottom: "10px" }}>
                    <div className="detail-page__attributes__item__title">{t("extension")}</div>
                    <div className="detail-page__attributes__item__content">
                      <a href="#" onClick={() => handleDialPhone(phoneNumber.number, contact, "")}>{phoneNumber.number}</a>
                    </div>
                  </div>
                  <div className="detail-page__attributes__item" style={{ marginBottom: "10px" }}>
                    <div className="detail-page__attributes__item__title">{t("email")}</div>
                    <div className="detail-page__attributes__item__content">{existAttributes && contact.attributes.email || ""}</div>
                  </div>
                  {existAttributes && contact.attributes.addresses.map((address, i) => {
                    return (
                      <div className="detail-page__address detail-desktop">
                        <div className="detail-page__attributes__item">
                          <div className="detail-page__attributes__item__title">{t("zip")}</div>
                          <div className="detail-page__attributes__item__content">{address.zip}</div>
                        </div>
                        <div>{`${address.country} ${address.state} ${address.city} ${address.street1} ${address.street2}`}</div>
                        <div className="memo" style={{ marginBottom: "10px" }}>
                          {existAttributes && contact.attributes.memo || ""}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {existAttributes && contact.attributes.addresses.map((address, i) => {
                  return (
                    <div className="detail-page__address detail-mobile">
                      {!!address.zip && <div className="detail-page__attributes__item">
                        <div className="detail-page__attributes__item__title">〒</div>
                        <div className="detail-page__attributes__item__content">{address.zip}</div>
                      </div>}
                      <div>{`${convertCountry(address.country)} ${address.state} ${address.city} ${address.street1} ${address.street2}`}</div>
                      <div className="memo" style={{ marginBottom: "10px" }}>
                        {existAttributes && contact.attributes.memo || ""}
                      </div>
                    </div>
                  )
                })}
                <div className="detail-page__history">
                  <div className="detail-page__history__title" onClick={() => {
                    if (!showHistory) {
                      fetchHistory();
                    }
                    setShowHistory(!showHistory)
                  }}>
                    <span>{t("history")}</span>
                    <span>{showHistory ? "▲" : "▼"}</span>
                  </div>
                  {showHistory && <div className="detail-page__history__items">
                    {loadingHistory && <Spinner animation="grow" variant="info" />}
                    {histories.map((history, i) => {
                      return (
                        <div className="item" key={i}>
                          <span className="item__type" style={{
                            color: history.attributes.callType === "missed" ? "#ca1f29" :
                              history.attributes.callType === "in" ? "#56ccf2" : "#1fca82"
                          }}>{history.attributes.callType === "out" ? "発" : "着"}</span>
                          <span className="item__date">{moment(history.attributes.talkingStartDate).format("MM/DD")}</span>
                          <span className="item__number">{history.attributes.remoteNumber}</span>
                          <span className="item__memo">{history.attributes.memo}</span>
                        </div>
                      )
                    })}
                  </div>}
                </div>
              </div>
            </>
          }
          {isEdit && contact &&
            <AvForm onSubmit={submitForm}>
              <div className="detail-page__info">
                <div className="detail-page__info__avt">
                  <Dropzone onDrop={acceptedFiles => { onDropItem(acceptedFiles) }}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="buyer-request-form__attach-inner" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Avatar colorAvatar="#4285f4" contact={existAttributes ? contact : { attributes: {} }} filePath={imageInfo.filePath} edit={true}
                          onRemove={() => {
                            setImageInfo({
                              file: null,
                              filePath: ""
                            })
                          }}></Avatar>
                      </div>
                    )}
                  </Dropzone>
                  {/* <div className="detail-page__info__avt__detail">
                  <div className="text-group">
                    <AvField name="phoneticName" placeholder="PhoneticName" value={contact && contact.attributes.phoneticName || ""}></AvField>
                  </div>
                </div> */}
                </div>
                {existAttributes && <div className="button-light" onClick={() => { setIsEdit(false) }} style={{ width: "130px" }}>
                  {t("cancel")}
                </div>}
              </div>
              <div className="detail-page__attributes">
                <div className="detail-page__attributes__item" style={{ alignItems: "unset" }}>
                  <div className="detail-page__attributes__item__title" style={{ paddingTop: "10px" }}>{t("group")}</div>
                  <div className="detail-page__attributes__item__content">
                    <div className="detail-page__attributes__item__content__group">
                      <Input type="select" onChange={(e) => { setGroupSelected(e.target.value) }} value={groupSelected}>
                        <option value="" disabled={true}>{t("groupSelection")}</option>
                        {groupsParent.map(group => {
                          return renderGroupOptions(group);
                        })}
                      </Input>
                      {groupSelected && <div className="detail-page__attributes__item__content__group__icon"
                        onClick={() => {
                          if (!newGroups.includes(groupSelected)) {
                            newGroups.push(groupSelected);
                            setNewGroups([...newGroups])
                          }
                        }}>{plusIcon()}</div>}
                    </div>
                    {newGroups.map((group, index) => {
                      const findGroup = groups.find(g => { return g.id === group })
                      if (!findGroup) return null
                      return (
                        <div className="denwa-badge">
                          {findGroup.attributes.groupName}
                          <div className="icon-remove icon-white" onClick={() => {
                            newGroups.splice(index, 1);
                            setNewGroups([...newGroups])
                          }}>{closeIcon()}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("lastName")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField validate={validateRequired} name="lastName" placeholder={t("lastName")} value={existAttributes && contact.attributes.lastName || defaultName.lastName}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("firstName")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField validate={validateRequired} name="firstName" placeholder={t("firstName")} value={existAttributes && contact.attributes.firstName || defaultName.firstName}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("phoneticLastName")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="phoneticLastName" placeholder={t("phoneticLastName")} value={existAttributes && contact.attributes.phoneticLastName || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("phoneticFirstName")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="phoneticFirstName" placeholder={t("phoneticFirstName")} value={existAttributes && contact.attributes.phoneticFirstName || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("mobile")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="mobileNumber" placeholder={t("mobile")} value={
                      existAttributes && contact.attributes.phoneNumbers ?
                        contact.attributes.phoneNumbers.find(p => { return p.type === "mobileNumber" }) &&
                        contact.attributes.phoneNumbers.find(p => { return p.type === "mobileNumber" }).number || ""
                        : match.params.number
                    }></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item" style={{ marginBottom: "10px" }}>
                  <div className="detail-page__attributes__item__title">{t("extension")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="extensionNumber" placeholder={t("extension")} value={
                      existAttributes && contact.attributes.phoneNumbers &&
                      contact.attributes.phoneNumbers.find(p => { return p.type === "extensionNumber" }) &&
                      contact.attributes.phoneNumbers.find(p => { return p.type === "extensionNumber" }).number || ""
                    }></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item" style={{ marginBottom: "10px" }}>
                  <div className="detail-page__attributes__item__title">{t("phone")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="phoneNumber" placeholder={t("phone")} value={
                      existAttributes && contact.attributes.phoneNumbers &&
                      contact.attributes.phoneNumbers.find(p => { return p.type === "phoneNumber" }) &&
                      contact.attributes.phoneNumbers.find(p => { return p.type === "phoneNumber" }).number || ""
                    }></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("email")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="email" placeholder={t("email")} value={existAttributes && contact.attributes.email || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("organization")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="organization" placeholder={t("organization")} value={existAttributes && contact.attributes.organization || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("department")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="department" placeholder={t("department")} value={existAttributes && contact.attributes.department || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("division")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="division" placeholder={t("division")} value={existAttributes && contact.attributes.division || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("section")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="sections" placeholder={t("section")} value={existAttributes && contact.attributes.sections || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("title")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="title" placeholder={t("title")} value={existAttributes && contact.attributes.title || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__attributes__item border-top">
                  <div className="detail-page__attributes__item__title">{t("memo")}</div>
                  <div className="detail-page__attributes__item__content">
                    <AvField name="memo" placeholder={t("memo")} value={existAttributes && contact.attributes.memo || ""}></AvField>
                  </div>
                </div>
                <div className="detail-page__add-address" onClick={() => {
                  addresses.push({ type: "mobile" });
                  setAddresses([...addresses])
                }}>
                  <span>{t("addAddress")}</span>
                  <div>{plusIcon()}</div>
                </div>
                {addresses.map((address, i) => {
                  return (
                    <div className="detail-page__address">
                      <div className="detail-page__address__title">
                        <div className="detail-page__address__title__icon" onClick={() => {
                          addresses.splice(i, 1);
                          setAddresses([...addresses])
                        }}>
                          <TooltipDenwa title={t("tooltipRemoveAddress")}>
                            {trashIcon()}
                          </TooltipDenwa>
                        </div>
                      </div>
                      <div className="detail-page__attributes__item border-top">
                        <div className="detail-page__attributes__item__title">{t("country")}</div>
                        <div className="detail-page__attributes__item__content" style={{ width: "205px" }}>
                          <Input type="select" onChange={(e) => {
                            onChangeAddressAttr(e.target.value, "country", i)
                          }} value={convertCountry(address.country) ? address.country : ""}>
                            <option value="" disabled={true}>{t("country")}</option>
                            {countries.map(c => {
                              return <option value={c.code}>{`${c.code} ${c.japanese}`}</option>
                            })}
                          </Input>
                        </div>
                      </div>
                      <div className="detail-page__attributes__item border-top">
                        <div className="detail-page__attributes__item__title">{t("zip")}</div>
                        <div className="detail-page__attributes__item__content">
                          <AvField value={address.zip || ""} name={"zip" + `.${i}`} placeHolder={t("zip")}
                            onChange={(e) => { onChangeAddressAttr(e.target.value, "zip", i) }}></AvField>
                        </div>
                      </div>
                      <div className="detail-page__attributes__item border-top">
                        <div className="detail-page__attributes__item__title">{t("state")}</div>
                        <div className="detail-page__attributes__item__content">
                          <AvField value={address.state || ""} name={"state" + `.${i}`} placeHolder={t("state")}
                            onChange={(e) => { onChangeAddressAttr(e.target.value, "state", i) }}></AvField>
                        </div>
                      </div>
                      <div className="detail-page__attributes__item border-top">
                        <div className="detail-page__attributes__item__title">{t("city")}</div>
                        <div className="detail-page__attributes__item__content">
                          <AvField value={address.city || ""} name={"city" + `.${i}`} placeHolder={t("city")}
                            onChange={(e) => { onChangeAddressAttr(e.target.value, "city", i) }}></AvField>
                        </div>
                      </div>
                      <div className="detail-page__attributes__item border-top">
                        <div className="detail-page__attributes__item__title">{t("street1")}</div>
                        <div className="detail-page__attributes__item__content">
                          <AvField value={address.street1 || ""} name={"street1" + `.${i}`} placeHolder={t("street1")}
                            onChange={(e) => { onChangeAddressAttr(e.target.value, "street1", i) }}></AvField>
                        </div>
                      </div>
                      <div className="detail-page__attributes__item border-top">
                        <div className="detail-page__attributes__item__title">{t("street2")}</div>
                        <div className="detail-page__attributes__item__content">
                          <AvField value={address.street2 || ""} name={"street2" + `.${i}`} placeHolder={t("street2")}
                            onChange={(e) => { onChangeAddressAttr(e.target.value, "street2", i) }}></AvField>
                        </div>
                      </div>
                      <div className="detail-page__attributes__item border-top">
                        <div className="detail-page__attributes__item__title">{t("type")}</div>
                        <div className="detail-page__attributes__item__content">
                          <AvField type="select" name={"type" + `.${i}`}
                            value="mobile" onChange={(e) => { onChangeAddressAttr(e.target.value, "type", i) }}>
                            <option key={i} value="mobile">{t("mobileType")}</option>
                            <option key={i} value="business">{t("businessType")}</option>
                          </AvField>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {(contact && isEdit || !contact) && <div className="detail-page__submit">
                <Button className="button-primary" type="submit">{t("newSave")}</Button>
              </div>}
            </AvForm>}
        </div>
      }
      <PopupCalling {...contactDial} showDial={showDial} holding={holding} mute={mute} setMute={setMute} timeCall={timeCall}
        callEventStatus={callEventStatus} remoteUser={remoteUser} incoming={incoming}
        toggle={() => {
          window.ws.send(encrypt(`hangup`, 'key'));
          setShowDial(false)
        }}></PopupCalling>
    </div >
  )
}
export default withTranslation('common')(Detail);