import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { SketchPicker } from "react-color";
import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { validateRequired } from "../utils/validateForm";
import { withTranslation } from 'react-i18next';

const ModalColorGroup = (props) => {
  const [colorGroup, setColorGroup] = useState(props.group.attributes && props.group.attributes.color || "#2f7590");
  const groupName = props.group.attributes && props.group.attributes.groupName || "";
  const renderGroupOptions = (group) => {
    const parents = props.groups.filter(g => { return g.attributes.parent === group.id });
    const groupsArchitecture = () => {
      if (group.id === props.group.id || group.attributes.groupIndex === 3) {
        return null;
      }
      else if (group.attributes.groupIndex === 1) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      }
      else if (group.attributes.groupIndex === 2) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      }
      else {
        return <option value={group.id}>{group.attributes.groupName}</option>
      }
    }
    return (
      <>
        {groupsArchitecture()}
        {parents.map(g => {
          return renderGroupOptions(g);
        })}
      </>
    )
  }
  const onSubmit = (event, errors, values) => {
    if (!errors.length) {
      props.confirm(props.group.id, { color: colorGroup, ...values })
    }
  }
  return (
    <Modal show={true} className="modal-group">
      <AvForm onSubmit={onSubmit}>
        <ModalBody>
          <div style={{ display: 'flex' }}>
            <SketchPicker color={colorGroup} onChange={(color) => {
              setColorGroup(color.hex)
            }} />
            <div className="modal-group__form">
              <AvField type="select" name="parent" label={props.t("parentGroup")} defaultValue={props.group.attributes && props.group.attributes.parent || ""}>
                <option value="">{props.t("nestedNextGroup")}</option>
                {props.groupsParent.map(group => {
                  return renderGroupOptions(group);
                })}
              </AvField>
              <div className="color-item">
                <div className="color-item__color" style={{ background: colorGroup }}></div>
                <AvField validate={validateRequired} label={props.t("groupName")} name="groupName" value={groupName} placeHolder={props.t("groupName")}></AvField>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit"> {props.t("save")}</Button>
          <Button variant="secondary" onClick={props.toggle}>{props.t("cancel")}</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}
export default withTranslation('common')(ModalColorGroup);