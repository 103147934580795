import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import { get } from 'lodash';
import Dimmer from "../../../shared/Dimmer";
import ModalDelete from "../../../shared/ModalDelete";
import {API_HOST, SOCKET_EVENT_TYPE, USER_ROLE, WSS_HOST} from "../../../utils/constants";
import { jsonToCSV } from "../../../utils/functionHelper";
import moment from 'moment';
import { iconCrown, iconEdit, plusIcon, searchIcon, trashIcon } from "../../../utils/iconStatics";
import { requestApi, getToken } from "../../../utils/requestHelper";
import ModalUser from "./ModalUser";
import "./User.scss";
import { withTranslation } from 'react-i18next';
import TooltipDenwa from "../../../shared/TooltipDenwa";

const User = ({ forceRefreshContact, onClickExport, t }) => {
  const [loading, setLoading] = useState(false);
  let [users, setUsers] = useState([]);
  const [modalDelete, setModalDelete] = useState(false)
  let [usersChose, setUsersChose] = useState([])
  let [query, setQuery] = useState("");
  let [filterUsers, setFilterUsers] = useState([]);
  const [userChose, setUserChose] = useState(null);
  const [tenantDetail, setTenantDetail] = useState({});
  const firstUpdate = useRef(true);
  const usersRef = useRef([]);
  const filterUsersRef = useRef([]);
  const socketRef = useRef(null);
  const token = getToken();
  
  const fetchUsers = () => {
    const url = `${API_HOST}user/tenant`;
    setLoading(true)
    requestApi(url).then(result => {
      setLoading(false)
      if (!result.error && !result.message) {
        result.sort(function (a, b) { return a.attributes.userRole - b.attributes.userRole });
        setUsers(result)
        usersRef.current = result;
        setFilterUsers(result)
        filterUsersRef.current = result;
      }
    })
  }

  const fetchTenantDetail = () => {
    const url = `${API_HOST}tenant/detail`;
    requestApi(url).then(result => {
      setLoading(false)
      if (!result.error && !result.message) {
        setTenantDetail(get(result, '[0]', {}));
      }
    })
  }

  const deleteUsers = () => {
    const url = `${API_HOST}admin/user`;
    requestApi(url, 'DELETE', JSON.stringify({ ids: usersChose })).then(result => {
      if (!result.message && !result.error) {
        const newUsers = users.filter(c => { return !usersChose.includes(c.id) });
        const newUsersFiltered = filterUsers.filter(c => { return !usersChose.includes(c.id) });
        setFilterUsers([...newUsersFiltered])
        filterUsersRef.current = [...newUsersFiltered];
        setUsers([...newUsers])
        usersRef.current = [...newUsers]
        setUsersChose([]);
        setModalDelete(false);
      } else {
        toast.error(t(`${result.error}`))
      }
    })
  }

  const createUser = (errors, values) => {
    if (!errors.length) {
      const url = `${API_HOST}admin/user`;
      delete values.confirmPassword;
      requestApi(url, 'POST', JSON.stringify({ ...values, userRole: USER_ROLE.user })).then(result => {
        if (!result.error && !result.message) {
          const newUsers = users.concat([{ id: result.id, attributes: values }]);
          const newFilterUsers = filterUsers.concat([{ id: result.id, attributes: values }]);
          setUsers([...newUsers])
          usersRef.current = [...newUsers]
          setFilterUsers([...newFilterUsers])
          filterUsersRef.current = [...newFilterUsers];
          setUserChose(null)
        } else {
          toast.error(t(`${result.error}`));
        }
      })
    }
  }

  const updateUser = (errors, values, id) => {
    if (!errors.length) {
      const url = `${API_HOST}admin/user`;
      delete values.confirmPassword;
      if (!values.password) {
        delete values.password;
      }
      requestApi(url, 'PUT', JSON.stringify({ ...values, id })).then(result => {
        if (!result.error && !result.message) {
          setUserChose(null);
          let index = users.findIndex(u => { return u.id === id });
          let indexFilter = filterUsers.findIndex(u => { return u.id === id });
          if (index > -1) {
            users[index].attributes = { ...users[index].attributes, ...values };
            setUsers([...users])
            usersRef.current = [...users];
          }
          if (indexFilter > -1) {
            filterUsers[index].attributes = { ...filterUsers[index].attributes, ...values };
            setUsers([...filterUsers])
            usersRef.current = [...filterUsers];
          }
        } else {
          toast.error(t(`${result.error}`));
        }
      })
    }
  }

  const filterUser = () => {
    let items = users;
    if (query) {
      items = items.filter(user => {
        return (user.attributes.email && user.attributes.email.includes(query))
          || (user.attributes.displayName && user.attributes.displayName.includes(query))
      })
    }
    setUsersChose([])
    setFilterUsers([...items]);
    filterUsersRef.current = [...items];
  }

  const debounce = (executeFunction, wait) => {
    let timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => executeFunction(), wait);
    };
  };

  const fetchResultsOnTyping = debounce(() => filterUser(), 200)

  useEffect(() => {
    fetchUsers();
    fetchTenantDetail();
  }, [])

  useEffect(() => {
    fetchUsers();
    setUsersChose([]);
    setUserChose(null);
    setQuery("");
  }, [forceRefreshContact])
  
  useEffect(() => {
    if (WSS_HOST && token) {
      const socket = new WebSocket(`${WSS_HOST}?type=user&token=${token}`)
      socketRef.current = socket;
      
      socket.onopen = function(event) {
        // console.log('[USER] socket connect successfully: ', { event })
      };
      
      socket.onmessage = function(message) {
        try {
          handleReceiveSocketEvent(message);
        } catch (e) {
          // console.error('handleReceiveSocketEvent() error: ', e);
        }
      }
    }
  
    return (() => {
      if (socketRef.current) {
        socketRef.current.send('disconnection');
      }
    })
  }, [token]);
  
  const handleReceiveSocketEvent = (message) => {
    const data = JSON.parse(message.data);
    // console.log('socketMessage: ', data);
    switch (data.eventType) {
      case SOCKET_EVENT_TYPE.USER_CREATED:
        return handleSocketCreateUser(data);
      case SOCKET_EVENT_TYPE.USER_UPDATED:
        return handleSocketUpdateUser(data);
      case SOCKET_EVENT_TYPE.USER_DELETED:
        return handleSocketDeleteUsers(data);
      case SOCKET_EVENT_TYPE.USER_IMPORTED:
        return handleSocketImportUser();
      default:
        // console.log('Not handle: ', data);
        return;
    }
  }
  
  const handleSocketCreateUser = (data) => {
    const body = data.body;
    const newUser = {
      id: body.id,
      attributes: body.attributes,
    };
    const addedUserToList = usersRef.current.findIndex(item => item.id === newUser.id);
    if (addedUserToList === -1) {
      const newUsers = usersRef.current.concat([newUser]);
      const newFilterUsers = filterUsersRef.current.concat([newUser]);
      users = Array.from(new Set(newUsers));
      filterUsers = Array.from(new Set(newFilterUsers));
      setUsers(users);
      usersRef.current = users;
      setFilterUsers(filterUsers);
      filterUsersRef.current = filterUsers;
    }
  }
  
  const handleSocketUpdateUser = (data) => {
    const body = data.body;
    if (body && body.id) {
      const id = body?.id || '';
      const attributes = body?.attributes;
      const index = usersRef.current.findIndex(u => u.id === id);
      const indexFilter = filterUsersRef.current.findIndex(u => u.id === id);
      if (index > -1) {
        usersRef.current[index].attributes = {
          ...usersRef.current[index].attributes,
          ...attributes
        };
        users = [...usersRef.current];
        setUsers(users);
        usersRef.current = users;
      }
      if (indexFilter > -1) {
        filterUsersRef.current[index].attributes = {
          ...filterUsersRef.current[index].attributes,
          ...attributes
        };
        filterUsers = [...filterUsersRef.current];
        setUsers(filterUsers);
        usersRef.current = filterUsers;
      }
    }
  }
  
  const handleSocketDeleteUsers = (data) => {
    const body = data?.body;
    if (body && body.ids) {
      const newUsers = usersRef.current.filter(c => !body.ids.includes(c.id));
      const newUsersFiltered = filterUsersRef.current.filter(c => !body.ids.includes(c.id));
      users = [...newUsers];
      filterUsers = [...newUsersFiltered];
      setUsers(users);
      usersRef.current = users;
      setFilterUsers(filterUsers);
      filterUsersRef.current = filterUsers;
    }
  }
  
  const handleSocketImportUser = () => {
    fetchUsers();
  }

  const exportContactCsv = () => {
    const csvData = users.map(user => {
      return {
        id: user.id,
        displayName: user.attributes.displayName || "",
        email: user.attributes.email || "",
        sipUserId: user.attributes.sipUserId || "",
      }
    });
    const result = jsonToCSV(csvData);
    var fileName = `Users_${moment().format("YYYY-MM-DD-HH:mm:ss")}`;
    var uri = "data:text/csv;charset=utf-8," + encodeURI(result);
    var link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    exportContactCsv();
  }, [onClickExport])

  return (
    <div className="user-page">
      {loading && <Dimmer></Dimmer>}
      <ModalDelete show={modalDelete} toggle={() => { setModalDelete(!modalDelete) }} confirm={deleteUsers} title={t('deleteUserTitle')} ></ModalDelete>
      {!!userChose && <ModalUser user={userChose} toggle={() => { setUserChose(null) }}
        createUser={createUser} updateUser={updateUser}></ModalUser>}
      <div className="user-page__header">
        <input
          type="checkbox"
          checked={usersChose.length && usersChose.length === filterUsers.length}
          onChange={() => {
            if (usersChose.length < filterUsers.length) {
              usersChose = filterUsers.map(u => { return u.id });
            } else {
              usersChose = [];
            }
            setUsersChose([...usersChose])
          }}
        />
        <div className="search-input">
          <div className="search-input__icon">{searchIcon()}</div>
          <Input placeholder={t("userPlaceHolder")} onChange={(e) => {
            query = e.target.value;
            setQuery(query);
            fetchResultsOnTyping();
          }}></Input>
        </div>
        <div>{`${users.length}/100`}</div>
        <div className="action">
          <div className="icon icon-white" style={{ opacity: usersChose.length ? 1 : 0.5 }} onClick={() => {
            if (usersChose.length)
              setModalDelete(true)
          }}>
            <TooltipDenwa title={t("tooltipRemoveUser")}>
              {trashIcon()}
            </TooltipDenwa></div>
          <div className="icon icon-white" onClick={() => { setUserChose({}) }}>
            <TooltipDenwa title={t("tooltipAddUser")}>
              {plusIcon()}
            </TooltipDenwa>
          </div>
        </div>
      </div>
      <div className="user-page__content">
        <div className="item">
          <div className="item__checkbox">
          </div>
          <div className="item__name">{t("name")}</div>
          <div className="item__name">{t("tenantName")}</div>
          <div className="item__email">
            {t("email")}
          </div>
          <div className="item__action">
          </div>
        </div>
        {filterUsers.map((user, i) => {
          return (
            <div className="item" key={i}>
              <div className="item__checkbox">
                <input
                  type="checkbox"
                  checked={usersChose.includes(user.id)}
                  onChange={(e) => {
                    const index = usersChose.indexOf(user.id);
                    if (index < 0) usersChose.push(user.id);
                    else usersChose.splice(index, 1);
                    setUsersChose([...usersChose]);
                  }}
                  onClick={(e) => { e.stopPropagation() }}
                />
              </div>
              <div className="item__name">{user.attributes.displayName}</div>
              <div className="item__name">{tenantDetail.attributes && tenantDetail.attributes.tenantName}</div>
              <div className="item__email">
                {user.attributes.userRole === 0 && iconCrown()}
                {user.attributes.email}
              </div>
              <div className="item__action">
                <div onClick={() => { setUserChose(user) }}>
                  <TooltipDenwa title={t("tooltipEditUser")}>
                    {iconEdit()}
                  </TooltipDenwa>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default withTranslation('common')(User);
