import { useEffect, useState } from "react";
import {
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { toast } from "react-toastify";
import { API_HOST } from "../../../utils/constants";
import { iconUploadDelivery, trashIcon, fileIcon, iconExclamation } from "../../../utils/iconStatics";
import { apiRequestFilePost, requestApi } from "../../../utils/requestHelper";
import Dropzone from "react-dropzone";
import Dimmer from "../../../shared/Dimmer";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { withTranslation } from 'react-i18next';
import jp from '../../../assets/languages/jp.json';

const ModalBlackList = (props) => {
  const [preSignedUrl, setPreSignedUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDimmer, setLoadingDimmer] = useState(false);
  const [file, setFile] = useState(null)
  const { t } = props;
  const fetchBlackList = () => {
    const url = `${API_HOST}tenant/blacklist`;
    setLoading(true);
    requestApi(url).then(result => {
      setLoading(false)
      if (!result.error && !result.message) {
        setPreSignedUrl(result.preSignedUrl);
      }
    })
  }
  useEffect(() => {
    if (props.show) {
      fetchBlackList();
    }
  }, [props.show])

  const onDropItem = async (files) => {
    const file = files.length && files[0] || null;
    if (file) {
      if (!file.name.includes(".csv"))
        return toast.error(t("csvIncorrect"));
      setLoadingDimmer(true);
      const url = `${API_HOST}admin/tenant/blacklist`;
      apiRequestFilePost(url, { file: [file] }, {}, "PUT").then(result => {
        setLoadingDimmer(false);
        if (!result.error && !result.message) {
          setPreSignedUrl(result.preSignedUrl);
          toast.success(props.t("rejectionSucceedMessage"));
        } else {
          toast.error(jp[`${result.error}`] ? props.t(`${result.error}`) : result.error);
        }
      })
    }
  }

  const removeBlackList = () => {
    const url = `${API_HOST}admin/tenant/blacklist`;
    setLoadingDimmer(true);
    requestApi(url, 'DELETE', JSON.stringify({})).then(result => {
      setLoadingDimmer(false)
      if (!result.error && !result.message) {
        toast.success(props.t("rejectionDeletedMessage"));
        setPreSignedUrl("");
      }
    })
  }

  const exportContactCsv = () => {
    var link = document.createElement("a");
    link.href = preSignedUrl;
    link.style = "visibility:hidden";
    link.download = "blacklist.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success(props.t("rejectionExportSucceedMessage"));
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("rejectListMessage")}
    </Tooltip>
  );

  return (
    <Modal isOpen={props.show} className="modal-csv">
      <ModalHeader>
        {t("uploadRejectionListMessage")}
        <OverlayTrigger
          placement="right"
          overlay={renderTooltip}
        >
          {iconExclamation()}
        </OverlayTrigger>
      </ModalHeader>
      <ModalBody>
        {loading && <div className="text-center" style={{ marginTop: "20px" }}>
          <Spinner animation="grow" variant="info" />
        </div>}
        {loadingDimmer && <Dimmer></Dimmer>}
        {!loading && <div className="blacklist-csv">
          <Dropzone onDrop={acceptedFiles => { onDropItem(acceptedFiles) }}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>

                <Button variant="outline-primary">{t("importCsv")}</Button>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
          <Button variant="outline-info" onClick={exportContactCsv} disabled={!preSignedUrl}>{t("exportCsv")}</Button>
          <Button variant="danger" onClick={removeBlackList} disabled={!preSignedUrl}>{t("deleteCsv")}</Button>
        </div>}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => { props.toggle() }}>{t("cancel")}</Button>
      </ModalFooter>
    </Modal>
  )
}
export default withTranslation('common')(ModalBlackList);