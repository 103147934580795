export const API_HOST = process.env.REACT_APP_API_HOST
export const WSS_HOST = process.env.REACT_WSS_HOST
export const SOCKET_URL = process.env.REACT_WSS_HOST || "http://127.0.0.1:5062/"
export const GROUP_ACCESS = {
  public: 0,
  private: 1
}
export const USER_ROLE = {
  admin: 0,
  user: 1
}

export const CONTACT_CALLING_STORAGE = "contact-calling"

export const MAX_CHILD_GROUP = 4;
export const SOCKET_EVENT_TYPE = {
  CONTACT_CREATED: 'CONTACT_CREATED',
  CONTACT_UPDATED: 'CONTACT_UPDATED',
  CONTACT_DELETED: 'CONTACT_DELETED',
  GROUP_CREATED: 'GROUP_CREATED',
  GROUP_UPDATED: 'GROUP_UPDATED',
  GROUP_DELETED: 'GROUP_DELETED',
  CONTACT_ADD_GROUPS: 'CONTACT_ADD_GROUPS',
  CONTACT_REMOVE_GROUPS: 'CONTACT_REMOVE_GROUPS',
  COMPANY_GROUP_CREATED: 'COMPANY_GROUP_CREATED',
  USER_CREATED: 'USER_CREATED',
  USER_UPDATED: 'USER_UPDATED',
  USER_DELETED: 'USER_DELETED',
  TENANT_CREATED: 'TENANT_CREATED',
  TENANT_UPDATED: 'TENANT_UPDATED',
  TENANT_DELETED: 'TENANT_DELETED',
  HISTORY_CREATED: 'HISTORY_CREATED',
  HISTORY_UPDATED: 'HISTORY_UPDATED',
  HISTORY_DELETED: 'HISTORY_DELETED',
  CONTACT_IMPORTED: 'CONTACT_IMPORTED',
  TENANT_IMPORTED: 'TENANT_IMPORTED',
  USER_IMPORTED: 'USER_IMPORTED',
  DIAL_LIST_IMPORTED: 'DIAL_LIST_IMPORTED',
  LEAVE_GROUP: 'LEAVE_GROUP',
}
