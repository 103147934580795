import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { closeIcon, iconCamera, iconGallery, iconSmile, iconUser } from "../utils/iconStatics";
import { withTranslation } from 'react-i18next';
import { requestApi } from "../utils/requestHelper";
import { API_HOST } from "../utils/constants";
import Avatar from "./Avatar";
import _ from 'lodash';
const MessagePopup = (props) => {
  let currentHeightTextArea = 40;
  const { contact, group } = props;
  const title = (contact && `${contact.attributes.lastName || ""} ${contact.attributes.firstName || ""}`) ||
    (group && group.attributes.groupName) || ""
  const autoGrow = (element) => {
    element.target.style.height = "40px";
    element.target.style.height = (element.target.scrollHeight) + "px";
    currentHeightTextArea = element.target.scrollHeight;
  }
  const [message, setMessage] = useState("")
  let [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchMessages = () => {
    const query = contact ? `list-direct-message?${new URLSearchParams({ contactId: contact.id })}` : group ?
      `list-channel-message?${new URLSearchParams({ groupId: group.id })}` : ''
    const url = `${API_HOST}handler/messages/chat-event/${query}`;
    setLoading(true);
    requestApi(url, 'POST', JSON.stringify({})).then(result => {
      setLoading(false);
      if (!result.error) {
        const messages = result.messages && result.messages.map(mess => {
          const contact = result.contacts.find(contact => { return contact.attributes.chatId === mess.posted_by }) || { attributes: {} };
          return { ...mess, contact, currentUser: props.user.id === contact.attributes.createdBy }
        }) || [];
        setMessages(messages);
        if (props.clearUnread) {
          props.clearUnread({
            chatId: contact && contact.attributes.chatId || "",
            groupId: group && group.attributes.channelId || "",
          })
        }
      }
    })
  }
  const chatToContact = (body) => {
    const url = `${API_HOST}handler/messages/chat-event/create-direct-message`;
    setLoading(true);
    requestApi(url, "POST", JSON.stringify(body)).then(result => {
      setLoading(false);
      if (!result.error && result.directMessage) {
        const mess = result.directMessage;
        messages.push({ ...mess, contact: props.contactUser, currentUser: true })
        setMessages([...messages])
      }
    })
  }

  const chatToGroup = (body) => {
    const url = `${API_HOST}handler/messages/chat-event/create-channel-message`;
    setLoading(true);
    requestApi(url, "POST", JSON.stringify(body)).then(result => {
      setLoading(false);
      if (!result.error && !_.isEmpty(result)) {
        messages.push({ content: { text: body.message }, contact: props.contactUser, currentUser: true })
        setMessages([...messages])
      }
    })
  }

  useEffect(() => {
    fetchMessages();
  }, [])
  return (
    <div className="message-popup">
      <div className="message-popup__header">
        <div className="message-popup__title">{title}</div>
        <div className="message-popup__close" onClick={props.toggle}>{closeIcon()}</div>
      </div >
      <div className="message-popup__content">
        {messages.map((message, i) => {
          return (
            <div className={`message-popup__content__box ${message.currentUser ? "right" : "left"}`} key={i}>
              <Avatar colorAvatar="#138eff" contact={message.contact || { attributes: {} }} style={{ width: "40px", height: "40px", fontSize: "20px" }}></Avatar>
              <div>
                {!!message.content.text && <div className={`chat ${message.currentUser ? "right" : "left"}`}>
                  {message.content.text}
                </div>}
              </div>
            </div>
          )
        })}
      </div>
      <div className="message-popup__box">
        <div>
          <Input id="chatbox" style={{ height: "40px", maxHeight: "120px" }} placeholder={props.t("chatBoxPlaceholder")}
            type="textarea"
            onInput={(e) => { autoGrow(e) }}
            value={message}
            onChange={(e) => { setMessage(e.target.value) }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (contact)
                  chatToContact({ message, contactId: contact.id })
                if (group)
                  chatToGroup({ message, groupId: group.id })
                setMessage('');
                e.target.style.height = "40px";
              }
            }}
            onKeyPress={e => {
              if (e.key === 'Enter')
                e.preventDefault()
            }}></Input>
        </div>
        <div className="message-popup__box__action">
          <div className="icon">{iconCamera()}</div>
          <div className="icon">{iconGallery()}</div>
          <div className="icon">{iconUser()}</div>
          <div className="icon">{iconSmile()}</div>
          <Button variant="primary" onClick={() => {
            if (contact)
              chatToContact({ message, contactId: contact.id })
            if (group)
              chatToGroup({ message, groupId: group.id })
            setMessage('');
            const e = document.getElementById("chatbox");
            e.style.height = "40px";
          }}>{props.t("send")}</Button>
        </div>
      </div>
    </div >
  )
}
export default withTranslation('common')(MessagePopup);