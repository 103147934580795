import "./Header.scss";
import Dropzone from "react-dropzone";
import { apiRequestFilePost, requestApi } from "../../../utils/requestHelper";
import { API_HOST, USER_ROLE } from "../../../utils/constants";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Dimmer from "../../../shared/Dimmer";
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from "reactstrap";
import ModalDial from "./ModalDial";
import ModalBlackList from "./ModalBlackList";
import ModalDialList from "./ModalDialList";
import { withTranslation } from 'react-i18next';

const routeName = {
  "/contact": "Contacts",
  "/histories": "History",
  "/users": "Users",
  "/tenants": "Tenants",
}

const MAPPING_ROUTE_NAME_TO_CSV_IMPORT = {
  "/contact": "handler/csv-to-contacts",
  "/users": "admin/csv-to-users",
  "/tenants": "admin/tenant/import-csv-to-tenants",
}

const Header = (props) => {
  const routePathName = props.history.location.pathname;
  const name = routeName[routePathName];
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModalDial, setShowModalDial] = useState(false);
  const [showModalBlackList, setShowModalBlackList] = useState(false);
  const [showModalDialList, setShowModalDialList] = useState(false);
  const [tenantDetail, setTenantDetail] = useState({});
  const [modalPhoneSetting, setModalPhoneSetting] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const checkSuperAdmin = props.user.attributes && props.user.attributes.email.endsWith("@ageet.com") && props.user.attributes.userRole === USER_ROLE.admin
  const checkAdmin = props.user.attributes && props.user.attributes.userRole === USER_ROLE.admin;

  // MEMO: Disable phoneSetting now.
  // const phoneSetting = props.tenant.attributes && !!props.tenant.attributes.tenantAlias;
  const phoneSetting = false;

  useEffect(() => {
    fetchTenantDetail();
  }, []);

  const fetchTenantDetail = () => {
    const url = `${API_HOST}tenant/detail`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        if (Array.isArray(result) && result.length > 0) {
          setTenantDetail(result[0]);
        }
      }
    });
  }

  const onDropItem = async (files) => {
    const file = files.length && files[0] || null;
    if (file) {
      if (!file.name.includes(".csv"))
        return toast.error(props.t("csvIncorrect"));

      const url = `${API_HOST}${MAPPING_ROUTE_NAME_TO_CSV_IMPORT[routePathName]}`;
      setLoading(true);
      apiRequestFilePost(url, { file: [file] }, { groups: [] }, "POST").then(result => {
        setLoading(false);
        if (!result.error && !result.message) {
          toast.success(`${result.success} ${props.t("registrationSuccess")}`);
          props.forceRefresh();
        } else {
          toast.error(props.t(`${result.error}`));
        }
      })
    }
  }
  return (
    <div className="header">
      <ModalDial show={showModalDial} toggle={() => { setShowModalDial(!showModalDial) }}
        user={props.user} updateSuccess={(body) => {
          let user = props.user;
          user.attributes = { ...user.attributes, ...body };
          setShowModalDial(false);
          props.setUser({ ...user });
        }} />
      <ModalBlackList show={showModalBlackList} toggle={() => { setShowModalBlackList(!showModalBlackList) }} />
      <ModalDialList
        show={showModalDialList}
        toggle={() => { setShowModalDialList(!showModalDialList) }}
        fetchDialList={props.fetchDialList}
        removeDialList={props.removeDialList}
      />
      {loading && <Dimmer />}
      <div className="header__title">
        {routePathName !== "/tenants" && tenantDetail && tenantDetail.attributes && tenantDetail.attributes.image && (
          <img className="header__title__logo" src={tenantDetail.attributes.image} alt="" />)}
        <div className="header__title__name">{name}</div>
      </div>
      <div className="header__nav">
        {routePathName !== '/histories' && <a className="header__nav__item desktop" onClick={() => {
          props.history.push("/histories");
        }}>History</a>}
        {routePathName !== '/contact' && <a className="header__nav__item desktop" onClick={() => {
          props.history.push("/contact");
        }}>Contact</a>}
        {routePathName !== '/tenants' && checkSuperAdmin && <a className="header__nav__item desktop" onClick={() => {
          props.history.push("/tenants");
        }}>Tenant</a>}
        {(routePathName === '/contact' || routePathName === '/users' || routePathName === '/tenants') && (
          <>
            <Dropzone onDrop={acceptedFiles => { onDropItem(acceptedFiles) }}>
              {({ getRootProps, getInputProps }) => (
                <a className={`header__nav__item desktop ${props.disableExportButton ? 'disabled' : ''}`} {...getRootProps()}>
                  Import
                  <input {...getInputProps()} />
                </a>
              )}
            </Dropzone>
            <a className={`header__nav__item desktop ${props.disableExportButton ? 'disabled' : ''}`} onClick={() => { !props.disableExportButton && props.export() }}>Export</a>
          </>
        )}

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            caret={true}
            tag="span"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
          >
            <a href="#">
              {props.user.attributes && props.user.attributes.email}
            </a>
          </DropdownToggle>
          <DropdownMenu>
            {props.user.attributes && props.user.attributes.userRole === USER_ROLE.admin &&
              <>
                <DropdownItem onClick={() => {
                  props.history.push("/users")
                }}>
                  <a>{props.t("userManagement")}</a>
                </DropdownItem>
                <DropdownItem onClick={() => {
                  setShowModalBlackList(true)
                }}>
                  <a>{props.t("rejectionList")}</a>
                </DropdownItem>
                {props.tenant.attributes && props.tenant.attributes.enableDialList &&
                  <DropdownItem onClick={() => {
                    setShowModalDialList(true)
                  }}>
                    <a>{props.t("dialListCsv")}</a>
                  </DropdownItem>}
              </>}
            {/* MEMO: Disable Dial settings */}
            {/*<DropdownItem onClick={() => {*/}
            {/*  setShowModalDial(true)*/}
            {/*}}>*/}
            {/*  <a>{props.t("dialSetting")}</a>*/}
            {/*</DropdownItem>*/}
            <DropdownItem onClick={() => {
              localStorage.removeItem("AccessToken");
              props.history.push("/login");
            }}>
              <a>{props.t("logout")}</a>
            </DropdownItem>
            <DropdownItem text>
              <a>バージョン情報 v.1.0.0</a>
            </DropdownItem>

          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="mobile__nav mobile">
        {routePathName !== '/histories' && <a className="header__nav__item" onClick={() => {
          props.history.push("/histories");
        }}>History</a>}
        {routePathName !== '/contact' && <a className="header__nav__item" onClick={() => {
          props.history.push("/contact");
        }}>Contact</a>}
        {routePathName !== '/tenants' && checkSuperAdmin && <a className="header__nav__item" onClick={() => {
          props.history.push("/tenants");
        }}>Tenant</a>}
        {(routePathName === '/contact' || routePathName === '/users' || routePathName === '/tenants') && (
          <>
            <Dropzone onDrop={acceptedFiles => { onDropItem(acceptedFiles) }}>
              {({ getRootProps, getInputProps }) => (
                <a className="header__nav__item" {...getRootProps()}>
                  Import
                  <input {...getInputProps()} />
                </a>
              )}
            </Dropzone>
            <a className="header__nav__item" onClick={() => { props.export() }}>Export</a>
          </>
        )}
      </div>
    </div>
  )
}
export default withTranslation('common')(Header);