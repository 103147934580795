import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";

import { save, copy } from "../../../utils/iconStatics";
import { withTranslation } from 'react-i18next';

const TenantDetail = (props) => {
  const { data, toggle, isOpen } = props;
  const attributes = data && data.attributes || {};
  const [copied, setCopy] = useState(false);

  const copyToClipboard = (password) => {
    setCopy(true);
    navigator.clipboard.writeText(password);
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} centered className="modal-tenant-detail">
        <ModalHeader>テナントを作成しました</ModalHeader>
        <ModalBody>
          <div className="modal-detail">
            <Row className="modal-detail__row">
              <Col md={5}>{props.t("tenantName")}</Col>
              <Col>{attributes.tenantName}</Col>
            </Row>
            <Row className="modal-detail__row">
              <Col md={5}>{props.t("maximumUser")}</Col>
              <Col>{attributes.maxUserCount}</Col>
            </Row>
            <Row className="modal-detail__row">
              <Col md={5}>{props.t("adminEmail")}</Col>
              <Col>{attributes.emailAdmin}</Col>
            </Row>
            <Row className="modal-detail__row">
              <Col md={5}>{props.t("adminPassword")}</Col>
              <Col>{attributes.passwordAdmin}</Col>
              <Col className="modal-detail__row__icon" onClick={() => copyToClipboard(attributes.passwordAdmin)} md={1}>{copied ? save() : copy()}</Col>
            </Row>
            <Row className="modal-detail__row">
              <Col md={5}>{props.t("adminName")}</Col>
              <Col>{attributes.displayName}</Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={toggle}>{props.t("cancel")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
export default withTranslation('common')(TenantDetail);
