import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Header from "./header/Header";
import routes from "./routes";
import "./Main.scss";
import { useEffect, useState } from "react";
import { API_HOST, CONTACT_CALLING_STORAGE, SOCKET_URL } from "../../utils/constants";
import { requestApi } from "../../utils/requestHelper";
import PopupCalling from "../../shared/PopupCalling";
import { decrypt, encrypt } from '../../utils/xTea';
import Style from 'style-it';

const Main = ({ history }) => {
  const [forceRefreshContact, setForceRefreshContact] = useState(false);
  const [onClickExport, setOnClickExport] = useState(false);
  const [user, setUser] = useState({});
  const [contactUser, setContactUser] = useState({});
  const [tenant, setTenant] = useState({});
  const [contactDial, setContactDial] = useState({ contact: {}, color: "", numberCalling: "" })
  const [showDial, setShowDial] = useState(false)
  const [holding, setHolding] = useState(false)
  const [mute, setMute] = useState(false)
  const [timeCall, setTimeCall] = useState(null)
  const [callEventStatus, setCallEventStatus] = useState('')
  const [remoteUser, setRemoteUser] = useState('')
  const [incoming, setIncoming] = useState(false)
  const [tenantStyle, setTenantStyle] = useState("");
  const [onFetchDialList, setOnFetchDialList] = useState(false);
  const [onRemoveDialList, setOnRemoveDialList] = useState(false);
  const [disableExportButton, setDisableExportButton] = useState(false);

  const convertTenantStyle = (tenant) => {
    let style = "";
    const attributes = tenant.attributes || {};
    if (attributes.linkedColor) {
      style = `a {
        color: ${attributes.linkedColor} !important;
      }
      .alphabet .item{
        color: ${attributes.linkedColor} !important;
      }
      .alphabet .item.-active{
        color: ${attributes.linkedColor} !important;
      }
      `
    }

    if (attributes.linkHoverColor) {
      style += `a:hover {
        color: ${attributes.linkHoverColor} !important;
      }
      .alphabet .item:hover{
        color: ${attributes.linkHoverColor} !important;
      }
      .alphabet .item.-active{
        color: ${attributes.linkHoverColor} !important;
      }
      `
    }

    if (attributes.buttonColor) {
      style += `.button-primary {
        background: ${attributes.buttonColor} !important;
        background-color: ${attributes.buttonColor} !important;
        border: 1px solid ${attributes.buttonColor} !important;
      }
      .button-light {
        background: ${attributes.buttonColor}66 !important;
        background-color: ${attributes.buttonColor}66 !important;
        border: 1px solid ${attributes.buttonColor} !important;
        color: ${attributes.buttonColor} !important;
      }`
    }
    if (attributes.selectedColor) {
      style += `.item.active {
        background: ${attributes.selectedColor} !important;
      }
      `
    }

    if (attributes.contactTitleColor) {
      style += `.header__title__name {
        color: ${attributes.contactTitleColor} !important;
      }
      `
    }

    if (attributes.linkedColorDark || attributes.buttonColorDark || attributes.selectedColorDark || attributes.contactTitleColorDark || attributes.linkHoverColorDark) {
      style += "@media (prefers-color-scheme: dark) {"
      if (attributes.linkedColorDark) {
        style += `a {
                color: ${attributes.linkedColorDark} !important;
              }
              .alphabet .item{
                color: ${attributes.linkedColorDark} !important;
              }
              `
      }
      if (attributes.buttonColorDark) {
        style += `.button-primary {
          background: ${attributes.buttonColorDark} !important;
          background-color: ${attributes.buttonColorDark} !important;
          border: 1px solid ${attributes.buttonColorDark} !important;
        }
        .button-light {
          background: ${attributes.buttonColorDark}66 !important;
          background-color: ${attributes.buttonColorDark}66 !important;
          border: 1px solid ${attributes.buttonColorDark} !important;
          color: ${attributes.buttonColorDark} !important;
        }`
      }

      if (attributes.selectedColorDark) {
        style += `.item.active {
          background: ${attributes.selectedColorDark} !important;
        }
        `
      }

      if (attributes.contactTitleColorDark) {
        style += `.header__title__name {
        color: ${attributes.contactTitleColorDark} !important;
      }
      `
      }

      if (attributes.linkHoverColorDark) {
        style += `a:hover {
          color: ${attributes.linkHoverColorDark} !important;
        }
        .alphabet .item:hover {
          color: ${attributes.linkHoverColorDark} !important;
        }
        .alphabet .item.-active{
          color: ${attributes.linkHoverColorDark} !important;
        }
        `
      }

      style += "}";
    }
    setTenantStyle(style)
  }
  const fetchUser = () => {
    const url = `${API_HOST}user/token/detail`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setUser(result)
      }
    })
  }
  const fetchContactUser = () => {
    const url = `${API_HOST}handler/messages/contact/user`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setContactUser(result)
      }
    })
  }
  const fetchTenant = () => {
    const url = `${API_HOST}tenant/detail`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        const tenantResult = result.length ? result[0] : {}
        setTenant(tenantResult)
        if (tenantResult.attributes && tenantResult.attributes.enableConnect) {
          wsstart();
        }
      }
    })
  }

  const handleStatusText = (state) => {
    if (state == 1) {
      setIncoming(true)
      setCallEventStatus("着信中")
    } else if (state == 2) {
      setIncoming(false)
      setCallEventStatus("発信中")
    } else if (state == 3) {
      setIncoming(false)
      setCallEventStatus("通話接続中")
    } else if (state == 5) {
      setIncoming(false)
      setCallEventStatus("切断")
    } else if (state == 6) {
      setIncoming(false)
      setCallEventStatus("保留")
    } else if (state != 0) {
      setIncoming(false)
      setCallEventStatus('')
    }
  }

  const wsstart = () => {
    const ws = new WebSocket('ws://' + '127.0.0.1' + ':5062', 'agephone');
    window.ws = ws;
    ws.onclose = function () {
      setTimeout(function () {
        wsstart()
      }, 3000);
    };
    ws.onmessage = function (n) {
      var message = decrypt(String(n.data), 'key');
      var msg = message.split('\t');
      if (msg[0] == 'stat') {
        if (!contactDial.numberCalling)
          setContactDial({ ...contactDial, numberCalling: msg[3] })
        let callstate = Math.floor(msg[2]);
        handleStatusText(callstate);
        if (!remoteUser) {
          setRemoteUser(msg[4]);
        }
        if (callstate == 5) {
          localStorage.removeItem(CONTACT_CALLING_STORAGE)
          if (msg[12] === "404") {
            setTimeout(function () {
              setHolding(false)
              setShowDial(false)
              setContactDial({ ...contactDial, numberCalling: '' })
            }, 3000);
          } else {
            setHolding(false)
            setTimeCall(null)
            setShowDial(false)
            setContactDial({ ...contactDial, numberCalling: '' })
          }
        }
        else if (callstate != 5 && !contactDial.showDial) {
          setShowDial(true)
        }
        if (callstate == 4) {
          setTimeCall(new Date().getTime())
        }
        if (callstate == 6 || callstate == 4) {
          if (msg[11] === '1') {
            setCallEventStatus("保留")
            setHolding(true);
          } else if (msg[11] === '0') {
            setCallEventStatus("")
            setHolding(false)
          }
        }
      }
    };
  }
  useEffect(() => {
    convertTenantStyle(tenant);
  }, [tenant])
  useEffect(() => {
    fetchUser();
    fetchTenant();
    fetchContactUser();
  }, [])

  return (
    <div>
      {!!tenantStyle && <Style>
        {tenantStyle}
      </Style>}
      <Header
        user={user}
        tenant={tenant}
        history={history}
        disableExportButton={disableExportButton}
        forceRefresh={() => setForceRefreshContact(!forceRefreshContact)}
        export={() => setOnClickExport(!onClickExport)}
        setUser={(user) => setUser(user)}
        fetchDialList={() => setOnFetchDialList(!onFetchDialList)}
        removeDialList={() => setOnRemoveDialList(!onRemoveDialList)}
        setTenant={setTenant}
      />
      <div className="main-container">
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                  <route.component
                    {...props}
                    forceRefreshContact={forceRefreshContact}
                    onClickExport={onClickExport}
                    user={user}
                    setContactDial={setContactDial}
                    tenant={tenant}
                    setTenant={setTenant}
                    onFetchDialList={onFetchDialList}
                    onRemoveDialList={onRemoveDialList}
                    contactUser={contactUser}
                    setDisableExportButton={setDisableExportButton}
                  />
                )} />
            ) : (null);
          })}
          <Redirect from="/" to="/contact" />
        </Switch>
      </div>
      <PopupCalling {...contactDial} showDial={showDial} holding={holding} mute={mute} remoteUser={remoteUser}
        timeCall={timeCall} setMute={setMute} callEventStatus={callEventStatus} incoming={incoming}
        toggle={() => {
          window.ws.send(encrypt(`hangup`, 'key'));
          setShowDial(false)
        }}></PopupCalling>
    </div >
  )
}
export default Main;