const setValidatePassword = (value, ctx, input, cb) => {
  let error = null;
  if (!value) cb(true);
  if (!/\d/.test(value) || !/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
    error = "パスワードは大文字、小文字、数字が含まれます"
  } else {
    cb(true);
  }
  return error;
}
export const validateRequired = {
  required: { value: true, errorMessage: "入力がありません。" },
}
export const validateEmail = {
  email: { value: true, errorMessage: "メール形式が正しくありません" },
}
export const validatePassword = {
  custom: setValidatePassword
}

export const validateNumber = {
  number: { value: true, errorMessage: "This field must be a number" }
}