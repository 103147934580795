import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { validateEmail, validatePassword, validateRequired } from "../../../utils/validateForm";
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { API_HOST } from "../../../utils/constants";
import { requestApi } from "../../../utils/requestHelper";
import moment from "moment";

const ModalAddHistory = (props) => {
  const url = `${API_HOST}history`;
  const talkingStartDate = new Date();
  const submitForm = (event, errors, values) => {
    if (!errors.length) {
      requestApi(url, 'POST', JSON.stringify([{ ...values, talkingDuration: Number(values.talkingDuration), talkingStartDate }])).then(result => {
        if (!result.error && !result.message) {
          props.onSuccess({ id: result[0], attributes: { ...values, talkingDuration: Number(values.talkingDuration), talkingStartDate } });
        } else {
          toast.error(props.t(`${result.error}`));
        }
      })
    }
  }
  return (
    <Modal show={true}>
      <AvForm onSubmit={submitForm}>
        <ModalBody>

          <AvField label="Local Display Name" name="localDisplayName" placeHolder="Local Display Name"></AvField>
          <AvField label="Local Number" name="localNumber" placeHolder="Local Number"></AvField>
          <AvField label="Remote Display Name" name="remoteDisplayName" placeHolder="Remote Display Name"></AvField>
          <AvField label="Remote Number" name="remoteNumber" placeHolder="Remote Number"></AvField>
          <AvField label="Talking Duration" type="number" name="talkingDuration" placeHolder="Talking Duration"></AvField>
          <AvField label="Call Type" type="select" name="callType" defaultValue="in">
            <option value="in">in</option>
            <option value="out">out</option>
            <option value="missed">missed</option>
          </AvField>
          <AvField label="Record File Name" name="recordFileName" placeHolder="Record File Name"></AvField>
          <AvField label="Transfer Display Name" name="transferDisplayName" placeHolder="Transfer Display Name"></AvField>
          <AvField label="Transfer Display Number" name="transferDisplayNumber" placeHolder="Transfer Display Number"></AvField>
          <AvField label="Memo" name="memo" placeHolder="Memo"></AvField>
          <AvField label="Sip CallId" name="sipCallId" placeHolder="Sip CallId"></AvField>
        </ModalBody>
        <ModalFooter>
          <Button type="submit">{props.t("save")}</Button>
          <Button variant="secondary" onClick={props.toggle}>{props.t("cancel")}</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}
export default withTranslation('common')(ModalAddHistory);
