import { createStore, applyMiddleware, compose } from "redux";
import { BrowserRouter } from "react-router-dom";
import sagas from "./sagas/index";
import createSagaMiddleware from 'redux-saga';
import {
  routerMiddleware
} from "react-router-redux";
import reducer from "./reducers/index";

const sagaMiddleware = createSagaMiddleware();
const initialState = {};

const createStoreWithMiddleware = compose(
  applyMiddleware(sagaMiddleware, routerMiddleware(BrowserRouter))
)(createStore);

const store = createStoreWithMiddleware(reducer, initialState);

export default store;
sagaMiddleware.run(sagas);
