import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import backgroundImage from "../../../assets/images/background-login.png";
import { API_HOST } from "../../../utils/constants";
import { requestApi } from "../../../utils/requestHelper";
import { validateEmail, validatePassword, validateRequired } from "../../../utils/validateForm";
import "./Login.scss";
import { withTranslation } from 'react-i18next';

const Login = ({ history, t }) => {
  const submitForm = (event, errors, values) => {
    if (!errors.length) {
      const url = `${API_HOST}user/login`;
      requestApi(url, "POST", JSON.stringify(values)).then(result => {
        if (!result.message && !result.error) {
          localStorage.setItem("AccessToken", result.token);
          history.push("/contact");
        } else {
          toast.error(t(`${result.error}`));
        }
      })
    }
  }
  useEffect(() => {
    if (localStorage.getItem("AccessToken")) {
      history.push("/contact")
    }
  }, [])
  return (
    <div className="login-page">
      <AvForm className="login-page__left" onSubmit={submitForm}>
        <div className="form">
          <div className="form-title">{t("login")}</div>
          <AvField name="email" validate={{ ...validateEmail, ...validateRequired }} label={t("email")}></AvField>
          <AvField type="password" name="password" validate={{ ...validatePassword, ...validateRequired }} label={t("password")}></AvField>
          <div className="form__button">
            <Button className="button-primary" type="submit">{t("login")}</Button>
          </div>
        </div>
      </AvForm>
      <div className="login-page__right">
        <img src={backgroundImage}></img>
      </div>
    </div>
  )
}
export default withTranslation('common')(Login);