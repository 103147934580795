import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from "react-bootstrap";
import { toast } from "react-toastify";
import { API_HOST } from "../../../utils/constants";
import { requestApi } from "../../../utils/requestHelper";
import { withTranslation } from 'react-i18next';

const ModalDial = (props) => {
  const { user } = props;
  const updateUser = (event, errors, values) => {
    if (!errors.length) {
      const url = `${API_HOST}user`;
      requestApi(url, 'PUT', JSON.stringify(values)).then(result => {
        if (!result.error && !result.message) {
          props.updateSuccess(values)
        } else {
          toast.error(props.t(`${result.error}`));
        }
      })
    }
  }
  return (
    <Modal show={props.show} >
      <AvForm onSubmit={updateUser}>
        <ModalBody>
          <AvField label={props.t("schemaPhoneNumber")} name="schemaName" placeHolder={props.t("schemaPhoneNumber")}
            value={user.attributes && user.attributes.schemaName || "agephone"}></AvField>
          <AvField label={props.t("prefixPhoneNumber")} name="dialPrefix" placeHolder={props.t("prefixPhoneNumber")}
            value={user.attributes && user.attributes.dialPrefix || ""}></AvField>
          <AvField label={props.t("countryCode")} name="countryCode" placeHolder={props.t("countryCode")}
            value={user.attributes && user.attributes.countryCode || ""}></AvField>
        </ModalBody>
        <ModalFooter>
          <Button type="submit">{props.t("save")}</Button>
          <Button variant="secondary" onClick={() => { props.toggle() }}>{props.t("cancel")}</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}
export default withTranslation('common')(ModalDial);