export const TAB_CHAR_OTHER = "＃"

export const TAB_CHARS = ["あ", "か", "さ", "た", "な", "は", "ま", "や", "ら", "わ",
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q",
  "R", "S", "T", "U", "V", "W", "X", "Y", "Z", TAB_CHAR_OTHER];

export const TAB_JAPANESE_CHARS = [
  { parent: "あ", child: "あいうえおアイウエオ" },
  { parent: "か", child: "かきくけこがぎぐげごカキクケコガギグゲゴ" },
  { parent: "さ", child: "さしすせそざじずぜぞサシスセソザジズゼゾ" },
  { parent: "た", child: "たちつてとだぢづでどタチツテトダヂヅデド" },
  { parent: "な", child: "なにぬねのナニヌネノ" },
  { parent: "は", child: "はひふへほばびぶべぼぱぴぷぺぽゔハヒフヘホバビブベボパピプペポヴ" },
  { parent: "ま", child: "まみむめもマミムメモ" },
  { parent: "や", child: "やゆよヤユヨ" },
  { parent: "ら", child: "らりるれろラリルレロ" },
  { parent: "わ", child: "わをんワヲン" }
];
