import './style.scss';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import routes from './routes'
import { Provider } from 'react-redux';
import store from './store'
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import smoothscrollPolyfill from 'smoothscroll-polyfill';
import 'smoothscroll-anchor-polyfill';
import { useEffect } from 'react';
import jp from './assets/languages/jp.json';
import en from './assets/languages/en.json';
smoothscrollPolyfill.polyfill();

const languages = {
  en: { name: 'en', common: en },
  jp: { name: 'jp', common: jp }
}

const i18options = {
  interpolation: {escapeValue: false},
  lng: languages.jp.name,
  // MEMO: compatibilityJSON option. Maybe don't need this option.
  // https://www.i18next.com/misc/migration-guide
  // compatibilityJSON: 'v3',
  resources: {
    [languages.en.name]: { common: languages.en.common },
    [languages.jp.name]: { common: languages.jp.common }
  }
}

i18next.init(i18options);

function App() {
  const changeLanguageHandler = (lang) => {
    i18next.changeLanguage(lang)
  }
  useEffect(() => {
    const initLanguage = localStorage.getItem("Language");
    if (initLanguage === "en") {
      changeLanguageHandler("en");
    }
  }, [])
  return (
    <I18nextProvider i18n={i18next}>
      <div className="App" id="denwa-app">
        <ToastContainer></ToastContainer>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} />
                    )} />
                ) : (null);
              })}
              <Redirect from="/" to="/contact" />
            </Switch>
          </BrowserRouter>
        </Provider>
      </div>
    </I18nextProvider>
  );
}

export default App;
