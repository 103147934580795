import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { omitBy, isEmpty } from 'lodash';
import ToggleSwitch from "../../../shared/ToggleSwitch";
import Avatar from "./Avatar";
import { validateEmail, validatePassword, validateNumber, validateRequired } from "../../../utils/validateForm";
import { SketchPicker } from "react-color";
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { requestApi } from "../../../utils/requestHelper";
import { API_HOST } from "../../../utils/constants";
import { trashIcon, folderIcon } from "../../../utils/iconStatics";

const TenantModal = (props) => {
  const { data, toggle, isOpen, user, handleAcceptModal } = props;
  const attributes = data.attributes || {};
  const [color, setColor] = useState('');
  const [focusField, setFocusField] = useState('');
  const [colors, setColors] = useState({
    linkedColor: '',
    selectedColor: '',
    buttonColor: '',
    linkedColorDark: '',
    selectedColorDark: '',
    buttonColorDark: '',
    linkHoverColor: '',
    linkHoverColorDark: '',
    contactTitleColor: '',
    contactTitleColorDark: '',
  });
  let [imageInfo, setImageInfo] = useState({
    file: null,
    filePath: ""
  });
  const [enableConnect, setEnableConnect] = useState(!!attributes.enableConnect)
  const [enableChatMessage, setEnableChatMessage] = useState(!!attributes.enableChatMessage)
  const [enableDialList, setEnableDialList] = useState(!!attributes.enableDialList)
  const [enableSso, setEnableSso] = useState(!!attributes.enableSso)

  const [showEditTemplateJson, setShowEditTemplateJson] = useState(false);
  const [showEditSchemaJson, setShowEditSchemaJson] = useState(false);
  const [showEditUiSchemaJson, setShowEditUiSchemaJson] = useState(false);
  const [templateJson, setTemplateJson] = useState('');
  const [schemaJson, setSchemaJson] = useState('');
  const [uiSchemaJson, setUiSchemaJson] = useState('');
  const [isTemplateValidateJsonFormat, setTemplateValidateJsonFormat] = useState(false);
  const [isSchemaValidateJsonFormat, setSchemaValidateJsonFormat] = useState(false);
  const [isUiSchemaValidateJsonFormat, setUiSchemaValidateJsonFormat] = useState(false);
  let [jsonObject, setJsonObject] = useState({});
  let [file1, setFile1] = useState({ file: undefined, path: '' });
  let [file2, setFile2] = useState({ file: undefined, path: '' });
  let [file3, setFile3] = useState({ file: undefined, path: '' });
  let [file4, setFile4] = useState({ file: undefined, path: '' });
  let [file5, setFile5] = useState({ file: undefined, path: '' });

  useEffect(() => {
    setEnableConnect(data.attributes ? !!data.attributes.enableConnect : false)
    setEnableChatMessage(data.attributes ? !!data.attributes.enableChatMessage : false)
    setEnableDialList(data.attributes ? !!data.attributes.enableDialList : false)
    setEnableSso(data.attributes ? !!data.attributes.enableSso : false)
    getTenantTemplatePreSigned(data.id);
  }, [data])

  useEffect(() => {
    setFile1({ file: undefined, path: attributes.file1 });
    setFile2({ file: undefined, path: attributes.file2 });
    setFile3({ file: undefined, path: attributes.file3 });
    setFile4({ file: undefined, path: attributes.file4 });
    setFile5({ file: undefined, path: attributes.file5 });
  }, [data])


  const onDropItem = async (files) => {
    const file = files.length && files[0] || null;
    if (file) {
      if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg")
        return toast.error(props.t("imageIncorrect"));
      await Promise.all(files.map(async (file) => {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = await (e => {
          let filePath = e.target.result;
          let image = { filePath, file };
          setImageInfo(image);
        });
      }))
    }
  }

  const onDropFile = async (files, setFunc) => {
    const file = files.length && files[0] || null;
    if (!file) return;

    await Promise.all(files.map(async (file) => {
      let reader = new FileReader();
      await reader.readAsDataURL(file);
      reader.onload = await (e => {
        setFunc({ file, path: file.path });
      });
    }))
  }

  const submitForm = (event, errors, values) => {
    let body = omitBy(values, isEmpty);
    if (!errors.length) {
      toggle();
      if (body.maxUserCount) {
        body.maxUserCount = parseInt(body.maxUserCount, 10);
      }
      if (attributes.emailAdmin === body.emailAdmin) {
        delete body.emailAdmin;
      }

      body = { ...body, ...jsonObject };
      const files = {};
      const filesDelete = {};

      if (file1.file === null) {
        filesDelete.file1 = 'null';
      } else if (file1.file !== undefined) {
        files.file1 = file1;
      }
      if (file2.file === null) {
        filesDelete.file2 = 'null';
      } else if (file2.file !== undefined) {
        files.file2 = file2;
      }
      if (file3.file === null) {
        filesDelete.file3 = 'null';
      } else if (file3.file !== undefined) {
        files.file3 = file3;
      }
      if (file4.file === null) {
        filesDelete.file4 = 'null';
      } else if (file4.file !== undefined) {
        files.file4 = file4
      }
      if (file5.file === null) {
        filesDelete.file5 = 'null';
      } else if (file5.file !== undefined) {
        files.file5 = file5
      }

      handleAcceptModal({ ...body, enableConnect, enableChatMessage, enableDialList, enableSso, ...filesDelete }, data.id, imageInfo, files);
    }
  }

  const getTenantTemplatePreSigned = (tenantId) => {
    if (!tenantId) {
      setTemplateJson('');
      setSchemaJson('');
      setUiSchemaJson('');
      return;
    }
    const url = `${API_HOST}tenant/${tenantId}/pre-signed-template`;
    requestApi(url)
      .then(result => {
        if (result.error || result.message) {
          return;
        }
        result.templateJson ? getText(result.templateJson, setTemplateJson) : setTemplateJson('');
        result.schemaJson ? getText(result.schemaJson, setSchemaJson) : setSchemaJson('');
        result.uiSchemaJson ? getText(result.uiSchemaJson, setUiSchemaJson) : setUiSchemaJson('');
      })
      .catch(e => console.log(e));
  }

  const getText = (url, setFunc) => {
    fetch(url)
      .then(response => response.json())
      .then(data => setFunc(JSON.stringify(data)))
      .catch(error => console.log({ error }));
  }

  const validateJsonString = (str) => {
    if (!str) {
      return;
    }
    try {
      JSON.parse(str);
    } catch (e) {
      toast.error('JSONの書式が正しくありません');
      return;
    }
    toast.success('JSONの書式が正しい');
    return true;
  }

  const getFileName = (filePath) => {
    if (!filePath || filePath === 'null') return '';
    const arr = filePath.split('/');
    return arr.length > 0 ? arr[arr.length - 1] : '';
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-xl tenant-page__tenant-modal">
      <ModalHeader>{data.id ? props.t("tenantEdit") : props.t("createTenant")}</ModalHeader>
      <AvForm onSubmit={submitForm}>
        <ModalBody>
          <div className="tenant-page__tenant-modal__content">
            <div className="tenant-page__tenant-modal__content__left">
              <div className="tenant-page__tenant-modal__image">
                <Dropzone onDrop={acceptedFiles => onDropItem(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {/*<Avatar contact={contact} colorAvatar={props.colorAvatar} style={{ width: "150px", height: "150px", fontSize: "55px" }}></Avatar>*/}
                      <Avatar
                        edit={true}
                        filePath={imageInfo.filePath || attributes.image}
                        data={attributes}
                        style={{ width: "100px", height: "100px", fontSize: "45px" }}
                      />
                    </div>
                  )}
                </Dropzone>
              </div>

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("tenantName")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="tenantName" validate={validateRequired} placeholder={props.t("tenantName")}
                    defaultValue={attributes.tenantName} />
                </div>
              </div>

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">テナントエイリアス</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="tenantAlias" validate={validateRequired} placeholder="テナントエイリアス"
                    defaultValue={attributes.tenantAlias} />
                </div>
              </div>

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("maximumUser")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="maxUserCount" placeholder={props.t("maximumUser")}
                    defaultValue={attributes.maxUserCount} validate={{ ...validateNumber, ...validateRequired }} />
                </div>
              </div>

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("adminEmail")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="emailAdmin" placeholder={props.t("adminEmail")} defaultValue={attributes.emailAdmin}
                    validate={{ ...validateEmail, ...validateRequired }} />
                </div>
              </div>

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("adminPassword")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="passwordAdmin" placeholder={props.t("adminPassword")}
                    validate={!data.id ? { ...validatePassword, ...validateRequired } :
                      validatePassword} />
                </div>
              </div>

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("adminName")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="displayName" validate={validateRequired} placeholder={props.t("adminName")}
                    defaultValue={attributes.displayName} />
                </div>
              </div>

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__group">
                  <div className="modal-detail__attributes__item__title"><span className="modal-detail__attributes__item__title__label">{props.t("callScreen")}</span> <ToggleSwitch
                    checked={!!enableConnect} value={!!enableConnect}
                    onChange={() => {
                      setEnableConnect(!enableConnect);
                    }} />
                  </div>
                  {/*<Button disabled={showEditTemplateJson || showEditSchemaJson || showEditUiSchemaJson} onClick={() => {*/}
                  {/*  setShowEditTemplateJson(!showEditTemplateJson)*/}
                  {/*}}>電話設定JSON</Button>*/}
                </div>
              </div>

              {/*<div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>*/}
              {/*  <div className="modal-detail__attributes__item__group">*/}
              {/*    <div className="modal-detail__attributes__item__title"><span className="modal-detail__attributes__item__title__label">{props.t("chatMessage")}</span> <ToggleSwitch*/}
              {/*      checked={!!enableChatMessage} value={!!enableChatMessage}*/}
              {/*      onChange={() => {*/}
              {/*        setEnableChatMessage(!enableChatMessage);*/}
              {/*      }} />*/}
              {/*    </div>*/}
              {/*    <Button disabled={showEditTemplateJson || showEditSchemaJson || showEditUiSchemaJson} onClick={() => {*/}
              {/*      setShowEditSchemaJson(!showEditSchemaJson)*/}
              {/*    }}>JSONスキーマ</Button>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__group">
                  <div className="modal-detail__attributes__item__title"><span className="modal-detail__attributes__item__title__label">{props.t("enableDialList")}</span> <ToggleSwitch
                    checked={!!enableDialList} value={!!enableDialList}
                    onChange={() => {
                      setEnableDialList(!enableDialList);
                    }} />
                  </div>
                  {/*<Button disabled={showEditTemplateJson || showEditSchemaJson || showEditUiSchemaJson} onClick={() => {*/}
                  {/*  setShowEditUiSchemaJson(!showEditUiSchemaJson)*/}
                  {/*}}>UIスキーマ</Button>*/}
                </div>
              </div>

              {/*<div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>*/}
              {/*  <div className="modal-detail__attributes__item__group">*/}
              {/*    <div className="modal-detail__attributes__item__title"><span className="modal-detail__attributes__item__title__label">シングルサインオン</span>*/}
              {/*      <ToggleSwitch*/}
              {/*        disabled={!!data.id}*/}
              {/*        checked={!!enableSso} value={!!enableSso}*/}
              {/*        onChange={() => {*/}
              {/*          setEnableSso(!enableSso);*/}
              {/*        }} />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

            {/*  <div className="modal-detail__attributes__file-item">*/}
            {/*    <div className="modal-detail__attributes__file-item__title">ファイル1</div>*/}
            {/*    <div className="modal-detail__attributes__file-item__input">*/}
            {/*      <input value={getFileName(file1.path)} disabled={true} />*/}
            {/*      <Dropzone onDrop={acceptedFiles => onDropFile(acceptedFiles, setFile1)}>*/}
            {/*        {({ getRootProps, getInputProps }) => (*/}
            {/*          <div {...getRootProps()}>*/}
            {/*            <input {...getInputProps()} />*/}
            {/*            <div className="modal-detail__attributes__file-item__input__upload-button">{folderIcon()}</div>*/}
            {/*          </div>*/}
            {/*        )}*/}
            {/*      </Dropzone>*/}
            {/*      /!*<Button onClick={() => setFile1({ file: null, path: '' })} disabled={!file1.path || file1.path === 'null'}>{trashIcon()}</Button>*!/*/}
            {/*      <div className={`modal-detail__attributes__file-item__input__delete-button ${(!file1.path || file1.path === 'null') ? 'disabled' : ''}`} onClick={() => {*/}
            {/*        if (file1.path && file1.path !== 'null') {*/}
            {/*          setFile1({ file: null, path: '' })*/}
            {/*        }*/}
            {/*      }}>{trashIcon()}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="modal-detail__attributes__file-item">*/}
            {/*    <div className="modal-detail__attributes__file-item__title">ファイル2</div>*/}
            {/*    <div className="modal-detail__attributes__file-item__input">*/}
            {/*      <input value={getFileName(file2.path)} disabled={true} />*/}
            {/*      <Dropzone onDrop={acceptedFiles => onDropFile(acceptedFiles, setFile2)}>*/}
            {/*        {({ getRootProps, getInputProps }) => (*/}
            {/*          <div {...getRootProps()}>*/}
            {/*            <input {...getInputProps()} />*/}
            {/*            <div className="modal-detail__attributes__file-item__input__upload-button">{folderIcon()}</div>*/}
            {/*          </div>*/}
            {/*        )}*/}
            {/*      </Dropzone>*/}
            {/*      /!*<Button onClick={() => setFile2({ file: null, path: '' })} disabled={!file2.path || file2.path === 'null'}>{trashIcon()}</Button>*!/*/}
            {/*      <div className={`modal-detail__attributes__file-item__input__delete-button ${(!file2.path || file2.path === 'null') ? 'disabled' : ''}`} onClick={() => {*/}
            {/*        if (file2.path && file2.path !== 'null') {*/}
            {/*          setFile2({ file: null, path: '' })*/}
            {/*        }*/}
            {/*      }}>{trashIcon()}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="modal-detail__attributes__file-item">*/}
            {/*    <div className="modal-detail__attributes__file-item__title">ファイル3</div>*/}
            {/*    <div className="modal-detail__attributes__file-item__input">*/}
            {/*      <input value={getFileName(file3.path)} disabled={true} />*/}
            {/*      <Dropzone onDrop={acceptedFiles => onDropFile(acceptedFiles, setFile3)}>*/}
            {/*        {({ getRootProps, getInputProps }) => (*/}
            {/*          <div {...getRootProps()}>*/}
            {/*            <input {...getInputProps()} />*/}
            {/*            <div className="modal-detail__attributes__file-item__input__upload-button">{folderIcon()}</div>*/}
            {/*          </div>*/}
            {/*        )}*/}
            {/*      </Dropzone>*/}
            {/*      <div className={`modal-detail__attributes__file-item__input__delete-button ${(!file3.path || file3.path === 'null') ? 'disabled' : ''}`} onClick={() => {*/}
            {/*        if (file3.path && file3.path !== 'null') {*/}
            {/*          setFile3({ file: null, path: '' })*/}
            {/*        }*/}
            {/*      }}>{trashIcon()}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="modal-detail__attributes__file-item">*/}
            {/*    <div className="modal-detail__attributes__file-item__title">ファイル4</div>*/}
            {/*    <div className="modal-detail__attributes__file-item__input">*/}
            {/*      <input value={getFileName(file4.path)} disabled={true} />*/}
            {/*      <Dropzone onDrop={acceptedFiles => onDropFile(acceptedFiles, setFile4)}>*/}
            {/*        {({ getRootProps, getInputProps }) => (*/}
            {/*          <div {...getRootProps()}>*/}
            {/*            <input {...getInputProps()} />*/}
            {/*            <div className="modal-detail__attributes__file-item__input__upload-button">{folderIcon()}</div>*/}
            {/*          </div>*/}
            {/*        )}*/}
            {/*      </Dropzone>*/}
            {/*      /!*<Button onClick={() => setFile4({ file: null, path: '' })} disabled={!file4.path || file4.path === 'null'}>{trashIcon()}</Button>*!/*/}
            {/*      <div className={`modal-detail__attributes__file-item__input__delete-button ${(!file4.path || file4.path === 'null') ? 'disabled' : ''}`} onClick={() => {*/}
            {/*        if (file4.path && file4.path !== 'null') {*/}
            {/*          setFile4({ file: null, path: '' })*/}
            {/*        }*/}
            {/*      }}>{trashIcon()}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="modal-detail__attributes__file-item">*/}
            {/*    <div className="modal-detail__attributes__file-item__title">ファイル5</div>*/}
            {/*    <div className="modal-detail__attributes__file-item__input">*/}
            {/*      <input value={getFileName(file5.path)} disabled={true} />*/}
            {/*      <Dropzone onDrop={acceptedFiles => onDropFile(acceptedFiles, setFile5)}>*/}
            {/*        {({ getRootProps, getInputProps }) => (*/}
            {/*          <div {...getRootProps()}>*/}
            {/*            <input {...getInputProps()} />*/}
            {/*            <div className="modal-detail__attributes__file-item__input__upload-button">{folderIcon()}</div>*/}
            {/*          </div>*/}
            {/*        )}*/}
            {/*      </Dropzone>*/}
            {/*      <div className={`modal-detail__attributes__file-item__input__delete-button ${(!file5.path || file5.path === 'null') ? 'disabled' : ''}`} onClick={() => {*/}
            {/*        if (file5.path && file5.path !== 'null') {*/}
            {/*          setFile5({ file: null, path: '' })*/}
            {/*        }*/}
            {/*      }}>{trashIcon()}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            </div>

            <div className="tenant-page__tenant-modal__content__right">
              {(!showEditTemplateJson && !showEditSchemaJson && !showEditUiSchemaJson) ? (
                <div className="modal-detail__attributes__item" style={{ marginBottom: "10px", display: "flex", justifyContent: "space-between", marginTop: '30px' }}>
                  <div className="modal-detail__attributes__item__left">
                    <div style={{ marginBottom: "10px" }}>White mode</div>
                    <div className="modal-detail__attributes__item__title">Linked Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.linkedColor || attributes.linkedColor}` }}
                        name="linkedColor"
                        value={colors.linkedColor || attributes.linkedColor}
                        placeHolder="Linked color"
                        onFocus={() => {
                          setFocusField('linkedColor')
                          setColor(colors.linkedColor || attributes.linkedColor)
                        }}
                      />
                    </div>
                    <div className="modal-detail__attributes__item__title">Selected Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.selectedColor || attributes.selectedColor}` }}
                        name="selectedColor"
                        value={colors.selectedColor || attributes.selectedColor}
                        placeHolder="Selected color"
                        onFocus={() => {
                          setFocusField('selectedColor')
                          setColor(colors.selectedColor || attributes.selectedColor)
                        }}
                      />
                    </div>
                    <div className="modal-detail__attributes__item__title">Button Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.buttonColor || attributes.buttonColor}` }}
                        name="buttonColor"
                        value={colors.buttonColor || attributes.buttonColor}
                        placeHolder="Button color"
                        onFocus={() => {
                          setFocusField('buttonColor')
                          setColor(colors.buttonColor || attributes.buttonColor)
                        }}
                      />
                    </div>

                    <div className="modal-detail__attributes__item__title">Link Hover Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.linkHoverColor || attributes.linkHoverColor}` }}
                        name="linkHoverColor"
                        value={colors.linkHoverColor || attributes.linkHoverColor}
                        placeHolder="Link Hover Color"
                        onFocus={() => {
                          setFocusField('linkHoverColor')
                          setColor(colors.linkHoverColor || attributes.linkHoverColor)
                        }}
                      />
                    </div>

                    <div className="modal-detail__attributes__item__title">Title Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.contactTitleColor || attributes.contactTitleColor}` }}
                        name="contactTitleColor"
                        value={colors.contactTitleColor || attributes.contactTitleColor}
                        placeHolder="Link Hover Color"
                        onFocus={() => {
                          setFocusField('contactTitleColor')
                          setColor(colors.contactTitleColor || attributes.contactTitleColor)
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-detail__attributes__item__center" style={{ marginLeft: '10px' }}>
                    <div style={{ marginBottom: "10px" }}>Dark mode</div>
                    <div className="modal-detail__attributes__item__title">Linked Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.linkedColorDark || attributes.linkedColorDark}` }}
                        name="linkedColorDark"
                        value={colors.linkedColorDark || attributes.linkedColorDark}
                        placeHolder="Linked color"
                        onFocus={() => {
                          setFocusField('linkedColorDark')
                          setColor(colors.linkedColorDark || attributes.linkedColorDark)
                        }}
                      />
                    </div>
                    <div className="modal-detail__attributes__item__title">Selected Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.selectedColorDark || attributes.selectedColorDark}` }}
                        name="selectedColorDark"
                        value={colors.selectedColorDark || attributes.selectedColorDark}
                        placeHolder="Selected color"
                        onFocus={() => {
                          setFocusField('selectedColorDark')
                          setColor(colors.selectedColorDark || attributes.selectedColorDark)
                        }}
                      />
                    </div>
                    <div className="modal-detail__attributes__item__title">Button Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.buttonColorDark || attributes.buttonColorDark}` }}
                        name="buttonColorDark"
                        value={colors.buttonColorDark || attributes.buttonColorDark}
                        placeHolder="Button color"
                        onFocus={() => {
                          setFocusField('buttonColorDark')
                          setColor(colors.buttonColorDark || attributes.buttonColorDark)
                        }}
                      />
                    </div>

                    <div className="modal-detail__attributes__item__title">Link Hover Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.linkHoverColorDark || attributes.linkHoverColorDark}` }}
                        name="linkHoverColorDark"
                        value={colors.linkHoverColorDark || attributes.linkHoverColorDark}
                        placeHolder="Link Hover Color"
                        onFocus={() => {
                          setFocusField('linkHoverColorDark')
                          setColor(colors.linkHoverColorDark || attributes.linkHoverColorDark)
                        }}
                      />
                    </div>

                    <div className="modal-detail__attributes__item__title">Title Color</div>
                    <div className="modal-detail__attributes__item__content">
                      <AvField
                        style={{ border: `4px solid ${colors.contactTitleColorDark || attributes.contactTitleColorDark}` }}
                        name="contactTitleColorDark"
                        value={colors.contactTitleColorDark || attributes.contactTitleColorDark}
                        placeHolder="Link Hover Color"
                        onFocus={() => {
                          setFocusField('contactTitleColorDark')
                          setColor(colors.contactTitleColorDark || attributes.contactTitleColorDark)
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-detail__attributes__item__right" style={{ marginLeft: '20px' }}>
                    <SketchPicker color={color} onChange={(color) => {
                      setColor(color.hex)
                      const newColors = { ...colors, [focusField]: color.hex }
                      setColors(newColors)
                    }} />
                  </div>
                </div>
              ) : (
                <div className="modal-detail__attributes__item">
                  {showEditTemplateJson && (
                    <div>
                      <div className="modal-detail__attributes__item__title">電話設定JSON</div>
                      <div className="modal-detail__attributes__item__json-form">
                        <AvInput type="textarea" rows={20} name="templateJson" value={templateJson} onChange={(e) => {
                          jsonObject = { ...jsonObject, templateJson: e.target.value };
                          setJsonObject(jsonObject);
                        }} />
                        <div className="modal-detail__attributes__item__json-form__btn-group">
                          <Button variant="info" onClick={() => {
                            validateJsonString(jsonObject.templateJson || templateJson)
                          }}>バリデーション</Button>
                          <Button variant="primary" onClick={() => {
                            setShowEditTemplateJson(!showEditTemplateJson)
                            setTemplateJson(jsonObject.templateJson);
                          }}>保存</Button>
                          <Button variant="secondary" onClick={() => {
                            setShowEditTemplateJson(!showEditTemplateJson)
                            jsonObject = delete jsonObject.templateJson;
                            setJsonObject(jsonObject)
                          }}>キャンセル</Button>
                        </div>
                      </div>
                    </div>
                  )}

                  {showEditSchemaJson && (
                    <div>
                      <div className="modal-detail__attributes__item__title">JSONスキーマ</div>
                      <div className="modal-detail__attributes__item__json-form">
                        <AvInput type="textarea" rows={20} name="schemaJson" value={schemaJson} onChange={(e) => {
                          jsonObject = { ...jsonObject, schemaJson: e.target.value };
                          setJsonObject(jsonObject);
                        }} />
                        <div className="modal-detail__attributes__item__json-form__btn-group">
                          <Button variant="info" onClick={() => validateJsonString(jsonObject.schemaJson || schemaJson)}>バリデーション</Button>
                          <Button variant="primary" onClick={() => {
                            setShowEditSchemaJson(!showEditSchemaJson)
                            setSchemaJson(jsonObject.schemaJson);
                          }}>保存</Button>
                          <Button variant="secondary" onClick={() => {
                            setShowEditSchemaJson(!showEditSchemaJson)
                            jsonObject = delete jsonObject.schemaJson;
                            setJsonObject(jsonObject)
                          }}>キャンセル</Button>
                        </div>
                      </div>
                    </div>
                  )}

                  {showEditUiSchemaJson && (
                    <div>
                      <div className="modal-detail__attributes__item__title">UIスキーマ</div>
                      <div className="modal-detail__attributes__item__json-form">
                        <AvInput type="textarea" rows={20} name="uiSchemaJson" value={uiSchemaJson} onChange={(e) => {
                          jsonObject = { ...jsonObject, uiSchemaJson: e.target.value };
                          setJsonObject(jsonObject);
                        }} />
                        <div className="modal-detail__attributes__item__json-form__btn-group">
                          <Button variant="info" onClick={() => validateJsonString(jsonObject.uiSchemaJson || uiSchemaJson)}>バリデーション</Button>
                          <Button variant="primary" onClick={() => {
                            setShowEditUiSchemaJson(!showEditUiSchemaJson)
                            setUiSchemaJson(jsonObject.uiSchemaJson);
                          }}>保存</Button>
                          <Button variant="secondary" onClick={() => {
                            setShowEditUiSchemaJson(!showEditUiSchemaJson)
                            jsonObject = delete jsonObject.uiSchemaJson;
                            setJsonObject(jsonObject)
                          }}>キャンセル</Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button disabled={!(!showEditTemplateJson && !showEditSchemaJson && !showEditUiSchemaJson)} type="submit" style={{ marginRight: "5px" }}>{data && data.id ? '保存' : '作成'}</Button>
          <Button variant="secondary" onClick={toggle}>{data && data.id ? props.t("cancel") : props.t("noCreateTenant")}</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}
export default withTranslation('common')(TenantModal);
