import { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { Input } from "reactstrap";
import { closeIcon, iconPhoneCall, iconRemoveNumber } from "../utils/iconStatics";
import { withTranslation } from 'react-i18next';
const dialButtons = [{ number: 1 }, { number: 2, alphabet: "ABC" }, { number: 3, alphabet: "DEF" }, { number: 4, alphabet: "GHI" },
{ number: 5, alphabet: "JKL" }, { number: 6, alphabet: "MNO" },
{ number: 7, alphabet: "PQRS" }, { number: 8, alphabet: "TUV" }, { number: 9, alphabet: "WXYZ" }, { number: "*" },
{ number: 0, alphabet: "+" }, { number: "#" }]
const ModalDial = (props) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    if (props.show)
      setValue("")
  }, [props.show])
  return (
    <Modal show={props.show} className="dial-modal" centered onHide={() => { props.toggle() }}>
      <ModalBody >
        <div className="dial-modal__close" onClick={props.toggle}> {closeIcon()}</div>
        <div className="dial-modal__container">
          <Input value={value} onChange={(e) => { setValue(e.target.value) }}></Input>
          <div className="dial-modal__numbers">
            {dialButtons.map(dial => {
              return (
                <div className="item" onClick={() => {
                  const newValue = `${value}${dial.number}`;
                  setValue(newValue);
                }}>
                  <div className="item__number">{dial.number}</div>
                  {!!dial.alphabet && <div className="item__alphabet">{dial.alphabet}</div>}
                </div>
              )
            })}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant="secondary" onClick={() => { setValue("") }}>{props.t("clear")}</Button>
            </div>
            <div className="item -call" onClick={() => {
              props.toggle()
              if (value) {
                props.handleDialPhone(value, {}, "")
              }
            }}>
              {iconPhoneCall()}
            </div>
            <div className="item" onClick={() => {
              const newValue = value.slice(0, -1)
              setValue(newValue);
            }}>
              {iconRemoveNumber()}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
export default withTranslation('common')(ModalDial);