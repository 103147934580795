import { MAX_CHILD_GROUP } from "./constants";
import _ from 'lodash';
import countries from "../assets/countries.json";

function csvJSON(csv) {

  var lines = csv.split("\n");

  var result = [];

  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {

    var obj = {};
    var currentline = lines[i].split(",");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);

  }
  return JSON.stringify(result);
}

export const jsonToCSV = (arrData) => {
  var CSV = "";
  var row = "";
  for (var index in arrData[0]) {
    row += index + ",";
  }
  row = row.slice(0, -1);
  CSV += row + "\r\n";
  for (var i = 0; i < arrData.length; i++) {
    var row = "";
    for (var index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);
    row = row.slice(0, -1);
    CSV += row + "\r\n";
  }
  return CSV;
}

export const convertPhoneHref = (number, { schemaName, dialPrefix, countryCode }, isConnect) => {
  number = number.replace(/\D/g, '');
  let url = !isConnect ? `${schemaName ? schemaName : 'agephone'}://` : '';
  if (countryCode) {
    if (number.length && number[0] === '0') {
      number = number.replace('0', '');
    }
    number = `${countryCode}${number}`
  }
  if (dialPrefix) {
    if (number.length && number[0] === '0') {
      number = number.replace('0', '');
    }
    number = `${dialPrefix}${number}`
  }
  return `${url}${number}`;
}

export const msToTime = (duration) => {
  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;
  if (hours > 0) {
    hours = (hours < 10) ? "0" + hours : hours;
    return hours + ":" + minutes + ":" + seconds;
  }
  return minutes + ":" + seconds;
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const convertGroupOptions = (groups, user, isContact) => {
  function arrayToObject(arr) {
    var rv = {};
    for (var i = 0; i < arr.length; ++i)
      rv[arr[i].id] = arr[i];
    return rv;
  }
  const groupsObj = arrayToObject(groups)
  let result = [];
  for (const key in groupsObj) {
    const element = groupsObj[key];
    const isOwner = !user || (element.attributes.userIds && element.attributes.userIds.includes(user))
    if (!element.attributes.groupIndex && isOwner) {
      result.push({ key: element.id, value: element.attributes.groupName })
    } else if (element.attributes.groupIndex === 1 && isOwner) {
      const parent = groupsObj[element.attributes.parent];
      if (parent)
        result.push({ key: element.id, value: `${parent && parent.attributes.groupName || ""}/${element.attributes.groupName}` })
    } else if (element.attributes.groupIndex === 2 && isOwner) {
      const parent2 = groupsObj[element.attributes.parent];
      const parent1 = groupsObj[parent2 && parent2.attributes.parent || ""];
      if (parent1 && parent2)
        result.push({ key: element.id, value: `${parent1.attributes.groupName || ""}/${parent2.attributes.groupName || ""}/${element.attributes.groupName}` })
    } else if (element.attributes.groupIndex === 3 && isContact) {
      const parent2 = groupsObj[element.attributes.parent];
      const parent1 = groupsObj[parent2 && parent2.attributes.parent || ""];
      const parent = groupsObj[parent1 && parent1.attributes.parent || ""];
      if (parent && parent1 && parent2)
        result.push({ key: element.id, value: `${parent.attributes.groupName || ""}/${parent1.attributes.groupName || ""}/${parent2.attributes.groupName || ""}/${element.attributes.groupName}` })
    }
  }
  result.sort((a, b) => {
    return a.value.localeCompare(b.value, 'en');
  })
  return result;
}

export const convertCountry = (code) => {
  const findCountry = countries.find(c => { return c.code === code });
  return findCountry ? findCountry.japanese : "";
}