import React, { Component } from "react"
import "./ToggleSwitch.scss"

export default class ToggleSwitch extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { checked, value, onChange, disabled = false } = this.props

    return (
      <label className="toggle-switch">
        <input className="toggle-switch__input" disabled={disabled} checked={checked} value={value} type="checkbox" onChange={onChange} onClick={(e) => {
          e.stopPropagation();
        }} />
        <span className="toggle-switch__slider" />
      </label>
    )
  }
}