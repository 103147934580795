import React, { Component } from "react";
import { Spinner } from "reactstrap";

export default class Dimmer extends Component {
  render() {
    return (
      <div className="dimmer">
        <Spinner animation="border" role="status">
        </Spinner>
      </div>
    )
  }
}