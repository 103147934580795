import React, { useState } from "react";
import { iconCamera } from "../../../utils/iconStatics";

const Avatar = ({ data, style, filePath, edit, colorAvatar }) => {
  const [showEdit, setShowEdit] = useState(false);
  const { tenantName } = data;
  const abb = tenantName && tenantName.slice(0, 2) || '';

  return (
    <div className="avatar" onMouseOver={() => {
      setShowEdit(true)
    }} onMouseLeave={() => {
      setShowEdit(false)
    }}>
      {filePath ? (
        <img src={filePath} style={{ ...style, border: `3px solid ${colorAvatar}` }} alt="" />
      ) : (
        <div className="avatar__name" style={colorAvatar ? { ...style, background: colorAvatar } : { ...style }}>{abb}</div>
      )}

      {edit && showEdit && <div className="avatar__action">
        {iconCamera()}
        <div className="avatar__action__remove">
        </div>
      </div>}
    </div>
  )
}
export default Avatar;