import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import {TAB_CHARS} from "../../../utils/tab";

const Alphabet = ({ t, charActive, onFilter }) => {
  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {t("inActiveAlphabet")}
    </Tooltip>
  );
  return (
    <div className="alphabet" id="style-1">
      {TAB_CHARS.map((a, i) => {
        return (
          <div key={i}>
            {a === charActive ?
              <OverlayTrigger
                placement="left"
                overlay={renderTooltip}
              >
                <div className={`item -active`} style={{ cursor: "pointer" }} onClick={() => {
                  onFilter(a);
                }}>{a}</div>
              </OverlayTrigger>
              : <div className={`item`} style={{ cursor: "pointer" }} onClick={() => {
                onFilter(a);
              }}>{a}</div>}
          </div>
        )
      })}
    </div>
  )
}
export default withTranslation('common')(Alphabet);