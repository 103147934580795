import Contact from "./contact/Contact";
import History from "./history/History";
import User from "./user/User";
import Tenant from "./tenant/Tenant";

const routes = [
  { path: '/contact', exact: true, name: 'Contact', component: Contact },
  { path: '/histories', exact: true, name: 'History', component: History },
  { path: '/users', exact: true, name: 'User', component: User },
  { path: '/tenants', exact: true, name: 'History', component: Tenant },
];

export default routes;