import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { validateEmail, validatePassword, validateRequired } from "../../../utils/validateForm";
import { withTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { API_HOST } from "../../../utils/constants";
import { requestApi } from "../../../utils/requestHelper";
const ModalUser = (props) => {
  const [attributes, setAttributes] = useState({});

  const validateUserPassword = props.user.id ? validatePassword : { ...validateRequired, ...validatePassword }
  const submitForm = (event, errors, values) => {
    if (!props.user.id) {
      props.createUser(errors, values);
    } else {
      props.updateUser(errors, values, props.user.id);
    }
  }
  const fetchUserDetail = () => {
    const url = `${API_HOST}admin/user-detail/${props.user.id}`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setAttributes(result.attributes || {});
      }
    })
  }
  useEffect(() => {
    setAttributes(props.user.attributes || {});
    if (props.user.id) {
      fetchUserDetail();
    }
  },
    [props.user])
  return (
    <Modal show={!!props.user}>
      <AvForm onSubmit={submitForm}>
        <ModalBody className="modal-user-group">
          <div className="title">{props.user.id ? props.t("editUser") : props.t("createUser")}</div>
          <AvField label={props.t("name")} validate={validateRequired} name="displayName" placeHolder={props.t("nameUserPlaceHolder")} value={attributes.displayName || ""}></AvField>
          <AvField label={props.t("email")} validate={{ ...validateRequired, ...validateEmail }} name="email" placeHolder={props.t("email")}
            value={attributes.email || ""}></AvField>
          <AvField label={props.t("newPassword")} validate={validateUserPassword} name="password" placeHolder={props.t("newPassword")}></AvField>
          <AvField label={props.t("reEnterPassword")} validate={{ ...validateUserPassword, match: { value: 'password', errorMessage: "Confirm password doesn't match password!" } }}
            name="confirmPassword" placeHolder={props.t("reEnterPassword")}></AvField>

          <AvField label={props.t("sipUserId")} name="sipUserId" placeHolder={props.t("sipUserId")}
            value={attributes.sipUserId || ""}></AvField>
        </ModalBody>
        <ModalFooter>
          <Button type="submit">{props.user.id ? props.t("save") : props.t('yesCreateUser')}</Button>
          <Button variant="secondary" onClick={props.toggle}>{props.user.id ? props.t("cancel") : props.t('noCreateUser')}</Button>
        </ModalFooter>
      </AvForm>
    </Modal >
  )
}
export default withTranslation('common')(ModalUser);