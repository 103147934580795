import {
  iconAddUser,
  iconAddUserGroups,
  iconArrowDown,
  iconArrowRight,
  iconDialCall,
  iconDialCallSmall,
  iconEdit,
  iconMessage,
  iconRemoveContactGroup,
  iconSideBar,
  iconStar,
  iconStarInactive,
  inCall,
  missCall,
  outCall,
  plusIcon,
  searchIcon,
  trashIcon
} from "../../../utils/iconStatics";
import "./Contact.scss";
import {Input,} from "reactstrap";
import 'bootstrap/scss/bootstrap.scss';
import React, {useEffect, useRef, useState} from "react";
import {
  API_HOST,
  CONTACT_CALLING_STORAGE,
  GROUP_ACCESS,
  WSS_HOST,
  SOCKET_EVENT_TYPE,
} from "../../../utils/constants";
import {requestApi, getToken} from "../../../utils/requestHelper";
import ModalDetail from "./ModalDetail";
import Alphabet from "./Alphabet";
import moment from "moment";
import ModalDelete from "../../../shared/ModalDelete";
import {TAB_CHAR_OTHER, TAB_CHARS, TAB_JAPANESE_CHARS} from "../../../utils/tab"
import _ from "lodash";
import {convertGroupOptions, convertPhoneHref, jsonToCSV} from "../../../utils/functionHelper";
import Dimmer from "../../../shared/Dimmer";
import ModalColorGroup from "../../../shared/ModalColorGroup";
import ModalUserGroup from "../../../shared/ModalUserGroup";
import Avatar from "../../../shared/Avatar";
import ModalDial from "../../../shared/ModalDial";
import {encrypt} from '../../../utils/xTea';
import ModalDialDetail from './ModalDialDetail'
import {toast} from "react-toastify";
import {withTranslation} from 'react-i18next';
import MessagePopup from "../../../shared/MessagePopup";
import TooltipDenwa from "../../../shared/TooltipDenwa";
import {createConnect} from "react-redux/lib/connect/connect";

const callTypeIconMapping = {
  'in': inCall(),
  'out': outCall(),
  'missed': missCall(),
  'before': iconDialCallSmall(),
};

const Contact = ({ forceRefreshContact, onClickExport, user, tenant, setContactDial, onFetchDialList, onRemoveDialList, t, contactUser, setDisableExportButton }) => {
  const firstUpdate = useRef(true);
  const firstUpdateFetchDialList = useRef(true);
  const firstUpdateRemoveDialList = useRef(true);
  let [contacts, setContactsState] = useState([]);
  let [groups, setGroupsState] = useState([]);
  let [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactChose, setContactChose] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [showDial, setShowDial] = useState(false);
  const [modalCreateGroup, setModalCreateGroup] = useState(false)
  const [modalDeleteGroup, setModalDeleteGroup] = useState(false)
  const [modalDeleteContact, setModalDeleteContact] = useState(false)
  let [alphabets, setAlphabets] = useState([])
  let [contactsFiltered, setContactsFiltered] = useState({
    groupId: "",
    query: "",
    iNav: 0,
    firstCharacter: ""
  });
  let contactsFilteredRef = useRef({
    groupId: "",
    query: "",
    iNav: 0,
    firstCharacter: ""
  });
  
  let [contactsChose, setContactsChose] = useState([])
  let [filterContacts, setFilterContacts] = useState([]);
  const [hideLeftBar, setHideLeftBar] = useState(false);
  let [groupsSelected, setGroupsSelected] = useState([]);
  const [groupColorChose, setGroupColorChose] = useState({});
  const [userGroupChose, setUserGroupChose] = useState("");
  const [colorGroup, setColorGroup] = useState("#138eff");
  const [listDials, setListDials] = useState([]);
  const [showModalDialDetail, setShowModalDialDetail] = useState(false);
  const [dialDetail, setDialDetail] = useState({});
  let [currentDialIndex, setCurrentDialIndex] = useState(null);
  const [groupsParent, setGroupsParent] = useState([]);
  let [groupsExpanded, setGroupsExpanded] = useState([]);

  const [showMessage, setShowMessage] = useState(false);
  const [groupChat, setGroupChat] = useState(null);
  const [contactChat, setContactChat] = useState(null);
  let [dmChannels, setDmChannels] = useState([]);
  let [teamChannels, setTeamChannels] = useState([]);

  let [countContacts, setCountContacts] = useState({
    public: 0,
    private: 0
  });
  let [countContactFilter, setCountContactFilter] = useState({});
  const socketRef = useRef(null);
  const contactsRef = useRef(null);
  const groupsRef = useRef(null);
  const userRef = useRef();
  const token = getToken();
  
  const setContacts = (contactsList) => {
    contacts = contactsList
    setContactsState(contacts);
    contactsRef.current = contacts;
  }

  const setGroups = (groupsList) => {
    groups = groupsList
    setGroupsState(groups);
    groupsRef.current = groups;
    handleCountContacts(contactsRef.current);
    filterContact(contactsRef.current)
  }
  
  useEffect(() => {
    userRef.current = user;
  }, [user]);
  
  useEffect(() => {
    if (WSS_HOST && token) {
      const socket = new WebSocket(`${WSS_HOST}?type=contact&token=${token}`)
      socketRef.current = socket;

      socket.onopen = function(event) {
        console.log('[CONTACT] socket connect successfully: ', { event })
      };
  
      socket.onmessage = function(message) {
        try {
          handleReceiveSocketEvent(message);
        } catch (e) {
          console.error('handleReceiveSocketEvent() error: ', e);
        }
      }
    }
  
    return (() => {
      if (socketRef.current) {
        socketRef.current.send('disconnection');
      }
    })
  }, [token]);
  
  const checkOwnerGroupIdExistingInContactUserIds = (groups, contactUsersIds) => {
    for (const group of groups) {
      const ownerGroup = group?.attributes?.ownerGroup;
      if (contactUsersIds.includes(ownerGroup)) {
        return true;
      }
    }
    return false;
  }
  
  const handleReceiveSocketEvent = (message) => {
    const data = JSON.parse(message.data);
    console.log('socketMessage: ', data);
    const loggedUserId = userRef.current?.id;
    switch (data.eventType) {
      case SOCKET_EVENT_TYPE.CONTACT_CREATED:
        return handleSocketCreateContact(data, loggedUserId);
      case SOCKET_EVENT_TYPE.CONTACT_UPDATED:
        return handleSocketUpdateContact(data, loggedUserId);
      case SOCKET_EVENT_TYPE.CONTACT_DELETED:
        return handleSocketDeleteContact(data, loggedUserId);
      case SOCKET_EVENT_TYPE.GROUP_CREATED:
        if (_.get(data,'body.attributes.userIds', []).includes(loggedUserId)) {
          return handleSocketCreateGroup(data, loggedUserId);
        }
        break;
      case SOCKET_EVENT_TYPE.GROUP_UPDATED:
        const ids = _.get(data, 'body.attributes.userIds', []);
        if (!ids.includes(loggedUserId)) {
          return handleSocketRemoveUserFromGroup(data, loggedUserId);
        }
        return handleSocketUpdateGroup(data, loggedUserId);
      case SOCKET_EVENT_TYPE.GROUP_DELETED:
        return handleSocketDeleteGroup(data, loggedUserId);
      case SOCKET_EVENT_TYPE.CONTACT_ADD_GROUPS:
        return handleSocketAddContactsToGroups(data, loggedUserId);
      case SOCKET_EVENT_TYPE.CONTACT_REMOVE_GROUPS:
        return handleSocketRemoveContactsFromGroups(data, loggedUserId);
      case SOCKET_EVENT_TYPE.COMPANY_GROUP_CREATED:
        const userIds = _.get(data, 'body.userIds', []);
        if (userIds.includes(loggedUserId)) {
          return handleSocketCreateCompanyGroup(data, loggedUserId);
        }
        break;
      case SOCKET_EVENT_TYPE.LEAVE_GROUP:
        return handleSocketLeaveGroup(data, loggedUserId);
      case SOCKET_EVENT_TYPE.CONTACT_IMPORTED:
        return handleSocketImportContact();
      default:
        console.log('Not handle: ', data);
        return;
    }
  }
  
  const handleSocketCreateGroup = (data) => {
    const body = data?.body || {};
    const findGroup = groupsRef.current.find(g => g.id === body.attributes.parent);
    const groupParentIndex = findGroup && findGroup.attributes.groupIndex || 0;
  
    let newGroups = groupsRef.current;
    let createdGroup = groupsRef.current.find(g => g.id === body.id);
    
    if (!createdGroup) {
      createdGroup = {
        id: body.id,
        attributes: {
          ...body.attributes,
          groupIndex: !body.attributes?.parent ? 0 : groupParentIndex + 1,
          owner: user.id, userIds: [user.id]
        },
      };
      newGroups = [createdGroup].concat(groupsRef.current);
    }
    newGroups.sort((a, b) => {
      return compareString(a.attributes.groupName, b.attributes.groupName)
    });
    groups = [...newGroups];
    setGroups(groups);
  }
  
  const handleSocketUpdateGroup = async (data, loggedUserId) => {
    const body = data?.body;
    let currentGroups = groupsRef.current;
    let updatedGroupIndex = currentGroups.findIndex(g => g.id === body.id);
    const parentGroup = currentGroups.find(g => g.id === body.attributes.parent);
    const groupParentIndex = parentGroup?.attributes?.groupIndex || 0;
    let newGroups;
  
    if (updatedGroupIndex > -1) {
      currentGroups[updatedGroupIndex].attributes = {
        ...currentGroups[updatedGroupIndex].attributes,
        ...body.attributes,
      };
      if (!body?.attributes?.userIds) {
        currentGroups[updatedGroupIndex].attributes.groupIndex = !body?.attributes?.parent ? 0 : groupParentIndex + 1
      }
      newGroups = [...currentGroups];
    } else {
      let addedGroup = {
        id: body.id,
        attributes: {
          ...body.attributes,
        },
      }
      newGroups = [addedGroup].concat(groupsRef.current);
    }
    newGroups.sort((a, b) => {
      return compareString(a.attributes.groupName, b.attributes.groupName)
    })
    groups = [...newGroups];
    setGroups(groups);
    let contactsResult = [];
    if (loggedUserId !== body.attributes.ownerGroup && updatedGroupIndex === -1) {
      const result = await fetchContactsByGroupAndUser(body.id);
      if (!result.error && !result.message) {
        let items = result.map(item => {
          return generateContact({id: item.id, attributes: item.attributes, subId: item.subId});
        })
        contactsResult = contactsResult.concat(items);
      }
    }
    for (const item of contactsResult) {
      if (!contactsRef.current.find(c => c.id === item.id)) {
        contactsRef.current.push(item);
      }
    }
    
    contactsRef.current.sort(function (a, b) {
      if (a.compareFirstCharacter === b.compareFirstCharacter)
        return compareString(a.compareName, b.compareName)
      else
        return compareString(a.compareFirstCharacter, b.compareFirstCharacter)
    })
    contacts = [...contactsRef.current];
    setContacts(contacts)
    handleCountContacts(contacts);
    filterContact(contacts)
  }
  
  const handleSocketRemoveUserFromGroup = (data) => {
    const newGroups = groupsRef.current.filter(g => g.id !== data?.body?.id);
    newGroups.sort((a, b) => compareString(a.attributes.groupName, b.attributes.groupName));
    groups = [...newGroups]
    setGroups(groups);
    const newContacts = contactsRef.current.filter(c => !c.attributes?.groups?.includes(data?.body?.id));
    contactsRef.current = [...newContacts];
    contacts = contactsRef.current;
    setContacts(contacts)
    handleCountContacts(contacts);
    filterContact(contacts)
  }
  
  const handleSocketLeaveGroup = (data, loggedUserId) => {
    if (loggedUserId === data.body?.user) {
      const groupsIdsDeleted = data.body?.groups;
      const deletedGroups = groupsRef.current.filter(item => groupsIdsDeleted.includes(item.id))
      const newGroups = groupsRef.current.filter(g => !groupsIdsDeleted.includes(g.id));
      newGroups.sort((a, b) => compareString(a.attributes.groupName, b.attributes.groupName));
      groups = [...newGroups]
      setGroups(groups);
      let newContacts = contactsRef.current.filter(item => {
        if (!item.attributes.groups || item.attributes.groups.length === 0) {
          return true;
        }
        for (const deletedGroup of deletedGroups) {
          const index = item.attributes.groups.findIndex(id => id === deletedGroup.id);
          if (index > -1 && loggedUserId !== deletedGroup.attributes.ownerGroup) {
            return false;
          }
        }
        return true
      });
      setContacts([...newContacts]);
      handleCountContacts([...newContacts]);
      filterContact([...newContacts]);
    }
  }
  
  const handleSocketDeleteGroup = (data, loggedUserId) => {
    const groupsIdsDeleted = data.body?.ids;
    const deletedGroups = groupsRef.current.filter(item => groupsIdsDeleted.includes(item.id))
    const newGroups = groupsRef.current.filter(g => !groupsIdsDeleted.includes(g.id));
    newGroups.sort((a, b) => compareString(a.attributes.groupName, b.attributes.groupName));
    groups = [...newGroups]
    setGroups(groups);
    let newContacts = contactsRef.current.filter(item => {
      if (!item.attributes.groups || item.attributes.groups.length === 0) {
        return true;
      }
      for (const deletedGroup of deletedGroups) {
        const index = item.attributes.groups.findIndex(id => id === deletedGroup.id);
        if (index > -1 && loggedUserId !== deletedGroup.attributes.ownerGroup) {
          return false;
        }
      }
      return true
    });
    setContacts([...newContacts]);
    handleCountContacts([...newContacts]);
    filterContact([...newContacts])
  }
  
  const handleSocketCreateContact = (data, loggedUserId) => {
    const userIds = _.get(data, 'body.attributes.userIds', []);
    const contactGroupIds = _.get(data, 'body.attributes.groups', []);
    const currentGroupIds = groupsRef.current.map(item => item.id);
    let isExistingGroup = false;
    for (const id of contactGroupIds) {
      if (currentGroupIds.includes(id)) {
        isExistingGroup = true;
        break;
      }
    }
    if (
      (isExistingGroup && (
        userIds.includes(loggedUserId) ||
        checkOwnerGroupIdExistingInContactUserIds(groupsRef.current, userIds)
      ))
      ||
      (contactGroupIds.length === 0 && userIds.includes(loggedUserId))
    ) {
      const body = data.body;
      const alreadyAddedContactToList = contactsRef.current.find(item => item.id === body.id);
      if (!alreadyAddedContactToList) {
        contacts = [generateContact({id: body.id, attributes: body.attributes})].concat(contactsRef.current);
        contacts.sort(function (a, b) {
          if (a.compareFirstCharacter === b.compareFirstCharacter)
            return compareString(a.compareName, b.compareName)
          else
            return compareString(a.compareFirstCharacter, b.compareFirstCharacter)
        })
        contacts = [...contacts];
        setContacts(contacts)
        handleCountContacts(contacts);
        filterContact(contacts);
      }
    }
  }
  
  const handleSocketUpdateContact = (data) => {
    const body = data.body;
    const contactIndex = contacts.findIndex(item => item.id === body.id);
    if (contactIndex > -1) {
      contacts[contactIndex] = generateContact({
        id: body.id,
        attributes: {...contacts[contactIndex].attributes, ...body.attributes},
        subId: contacts[contactIndex].subId
      });
      contacts.sort(function (a, b) {
        if (a.compareFirstCharacter === b.compareFirstCharacter)
          return compareString(a.compareName, b.compareName)
        else
          return compareString(a.compareFirstCharacter, b.compareFirstCharacter)
      })
      contacts = [...contacts];
      setContacts(contacts);
      handleCountContacts(contacts);
      filterContact(contacts);
    }
  }
  
  const handleSocketDeleteContact = (data) => {
    const ids = data?.body?.ids;
    const newContacts = contactsRef.current.filter(c => (!ids?.find(id => id === c.id)));
    contacts = [...newContacts];
    setContacts(contacts);
    handleCountContacts(contacts);
    filterContact(contacts)
  }
  
  const handleSocketAddContactsToGroups = async (data, loggedUserId) => {
    const body = data.body;
    const contactIds = body?.contacts;
    const groupIds = body?.groups;
    if (Array.isArray(contactIds) && Array.isArray(groupIds)) {
      contactIds.forEach(contactId => {
        const findIndex = contactsRef.current.findIndex(c => c.id === contactId);
        if (findIndex > -1) {
          const contactGroups = contactsRef.current[findIndex].attributes.groups || [];
          contactsRef.current[findIndex].attributes.groups = Array.from(new Set(contactGroups.concat(groupIds)))
          contacts = [...contactsRef.current];
          setContacts(contacts);
          handleCountContacts(contacts);
          filterContact(contacts);
        }
      });
  
      for (const groupId of groupIds) {
        const group = groupsRef.current.find(item => item.id === groupId);
        if (group && loggedUserId !== group.ownerGroup) {
          let contactsResult = [];
          const result = await fetchContactsByGroupAndUser(groupId);
          if (!result.error && !result.message) {
            let items = result.map(item => {
              return generateContact({id: item.id, attributes: item.attributes, subId: item.subId});
            })
            contactsResult = contactsResult.concat(items);
            contactsResult.sort(function (a, b) {
              if (a.compareFirstCharacter === b.compareFirstCharacter)
                return compareString(a.compareName, b.compareName)
              else
                return compareString(a.compareFirstCharacter, b.compareFirstCharacter)
            });
          }
          for (const item of contactsResult) {
            if (!contactsRef.current.find(c => c.id === item.id)) {
              contactsRef.current.push(item);
            }
          }
          contacts = [...contactsRef.current];
          setContacts(contacts);
          handleCountContacts(contacts);
          filterContact(contacts);
        }
      }
    }
  }
  
  const handleSocketRemoveContactsFromGroups = (data, loggedUserId) => {
    const body = data.body;
    const contactIds = body?.contacts;
    const groupIds = body?.groups;
    const currentGroupIds = groupsRef.current.map(item => item.id);
    if (Array.isArray(contactIds) && Array.isArray(groupIds)) {
      let newContacts = contactsRef.current.map(c => {
        if (contactIds.includes(c.id)) {
          c.attributes.groups = c.attributes.groups ? c.attributes.groups.filter(g => !groupIds.includes(g)) : [];
        }
        return c;
      });
      newContacts = newContacts.filter((c, index) => {
        if (c.subId.includes("Public")) {
          return true;
        }
        const isExistingInGroup = c.attributes.groups?.some(g => currentGroupIds.includes(g));
        return c.attributes.userIds?.includes(loggedUserId) || isExistingInGroup
      });
      setContacts([...newContacts]);
      handleCountContacts([...newContacts])
      filterContact([...newContacts]);
    }
  }
  
  const handleSocketCreateCompanyGroup = (data) => {
    const body = data.body;
    const nestedGroups = body?.nestedGroups;
    if (Array.isArray(nestedGroups)) {
      nestedGroups.map(group => {
        if (groups.findIndex(g => (g.id === group.id)) < 0) {
          groups.push({ id: group.id, attributes: { ...group, userIds: [user.id] } });
        }
      })
      contacts = contacts.map(contact => {
        let newGroups = contact.attributes.groups || [];
        const organization = nestedGroups.length > 0 && nestedGroups[0] || {};
        const department = nestedGroups.length > 1 && nestedGroups[1] || {};
        const division = nestedGroups.length > 2 && nestedGroups[2] || {};
        const sections = nestedGroups.length > 3 && nestedGroups[3] || {};
        if (organization.groupName === contact.attributes.organization &&
          (!department.groupName || (department.groupName && department.groupName === contact.attributes.department)) &&
          (!division.groupName || (division.groupName && division.groupName === contact.attributes.division)) &&
          (!sections.groupName || (sections.groupName && sections.groupName === contact.attributes.sections))) {
          newGroups.push(nestedGroups[nestedGroups.length - 1].id);
        }
        contact.attributes.groups = Array.from(new Set(newGroups));
        return contact;
      })
      contacts = [...contacts];
      setContacts(contacts);
      handleCountContacts(contacts);
      filterContact(contacts );
      
      groups.sort((a, b) => {
        return compareString(a.attributes.groupName, b.attributes.groupName)
      })
      groups = [...groups];
      setGroups(groups);
    }
  }
  
  const handleSocketImportContact = () => {
    fetchContacts();
  }
  
  const handleCountContacts = (contacts) => {
    countContacts = { public: 0, private: 0 }
    let publicContact = 0;
    let privateContact = 0;
    contacts.map(contact => {
      if (contact.subId.includes("Public")) {
        publicContact++;
      } else if (contact.subId.includes("Private")) {
        privateContact++;
      }
      contact.attributes.groups && contact.attributes.groups.map(group => {
        let number = !countContacts[group] ? 0 : countContacts[group];
        countContacts[group] = number + 1;
      })
    })
    setCountContacts({ ...countContacts, public: publicContact, private: privateContact })
  }

  const fetchContacts = (exportCsv, lastEvaluatedKey) => {
    setLoading(true)
    let contactsResult = [];
    if (!lastEvaluatedKey && !exportCsv) {
      setContacts(contactsResult);
    }
    let url = `${API_HOST}contact/tenant${lastEvaluatedKey ? '?' + new URLSearchParams(lastEvaluatedKey) : ''}`;
    if (exportCsv) url += `?type=all`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        let items = result.items.map(item => {
          return generateContact({id: item.id, attributes: item.attributes, subId: item.subId});
        })
        contactsResult = contactsResult.concat(items);
        if (result.LastEvaluatedKey) {
          fetchContacts(exportCsv, result.LastEvaluatedKey);
        } else {
          setLoading(false);
          contactsResult.sort(function (a, b) {
            if (a.compareFirstCharacter === b.compareFirstCharacter)
              return compareString(a.compareName, b.compareName)
            else
              return compareString(a.compareFirstCharacter, b.compareFirstCharacter)
          })
          if (!exportCsv) {
            setContacts([...contactsResult]);
            handleCountContacts(contactsResult);
          }
          filterContact([...contactsResult], exportCsv)
        }
      } else {
        setLoading(false)
      }
    })
  }
  
  const fetchContactsByGroupAndUser = (groupId) => {
    let url = `${API_HOST}contact/group-user/${groupId}`;
    return requestApi(url)
  }

  const addFavorite = (favorite) => {
    favorites.push(favorite);
    setFavorites([...favorites]);
    if (contactsFiltered.iNav === 2)
      fetchResultsOnTyping()
  }

  const removeFavorite = (favs) => {
    favorites = favorites.filter(f => { return !favs.find(fav => { return `${f.id}${f.subId}` === `${fav.id}${fav.subId}` }) })
    setFavorites([...favorites]);
    if (contactsFiltered.iNav === 2)
      fetchResultsOnTyping()
  }

  const compareString = (s1, s2) => {
    s1 = s1 || "";
    s2 = s2 || "";
    const firstCharS1 = s1.charAt(0);
    const firstCharS2 = s2.charAt(0);
    const validate = "!~@#$%^&*()_+|`"
    if ((s1.localeCompare("ZZZ", 'en') <= 0 && s2.localeCompare("ZZZ", 'en') > 0) || (validate.includes(firstCharS1) && !validate.includes(firstCharS2))) {
      return 1;
    } else if ((s1.localeCompare("ZZZ", 'en') > 0 && s2.localeCompare("ZZZ", 'en') <= 0) || (!validate.includes(firstCharS1) && validate.includes(firstCharS2))) {
      return -1;
    } else {
      return s1.localeCompare(s2, 'en');
    }
  }

  const fetchUserChatDetail = () => {
    const url = `${API_HOST}handler/messages/chat-event/user-chat-detail`;
    requestApi(url, 'POST', JSON.stringify({})).then(result => {
      if (!result.error && !result.message) {
        setDmChannels(result.dm_channels || [])
        setTeamChannels(result.team_channels || [])
      }
    })
  }

  const fetchGroups = () => {
    const url = `${API_HOST}group/user`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        result.sort((a, b) => {
          return compareString(a.attributes.groupName, b.attributes.groupName)
        })
        setGroups(result);
      }
    })
  }

  const fetchFavorites = () => {
    const url = `${API_HOST}contact/favorite`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setFavorites(result)
      }
    })
  }

  const handleGroupsDeleted = () => {
    let ids = [...groupsSelected];
    const selected = groups.filter(g => { return groupsSelected.includes(g.id) })
    selected.map(group => {
      let filterChildren = groups.filter(g => { return g.attributes.parent === group.id });

      while (filterChildren.length) {
        let filterChildrenIds = filterChildren.map(c => { return c.id });
        ids = _.union(ids, filterChildrenIds);
        filterChildren = groups.filter(g => { return filterChildren.find(fc => { return fc.id === g.attributes.parent }) });
      }
    })
    return ids;
  }

  const checkExistNestedGroup = () => {
    return !!groups.find(g => { return groupsSelected.includes(g.attributes.parent) });
  }

  const deleteGroups = () => {
    const url = `${API_HOST}group`;
    const groupsDeleted = handleGroupsDeleted();
    requestApi(url, 'DELETE', JSON.stringify({ ids: groupsDeleted })).then(result => {
      if (!result.message && !result.error) {
        let newGroups = groups.filter(g => !groupsDeleted.includes(g.id));
        const deletedGroups = groups.filter(g => !!groupsDeleted.includes(g.id));
        
        newGroups.sort((a, b) => {
          return compareString(a.attributes.groupName, b.attributes.groupName)
        })
        const findGroup = groupsDeleted.find(g => { return g === contactsFiltered.groupId });
        if (findGroup) {
          contactsFiltered.groupId = ""
          contactsFiltered.iNav = 0;
        }
        setGroupsSelected([])
        setGroups([...newGroups])
        contactsFilteredRef.current = { ...contactsFiltered };
        setContactsFiltered({ ...contactsFiltered })
        setModalDeleteGroup(false);
        setFilterContacts([])
        fetchResultsOnTyping()
        let newContacts = contacts.filter((item, index) => {
          if (!item.attributes.groups || item.attributes.groups.length === 0) {
            return true;
          }
          for (const deletedGroup of deletedGroups) {
            const index = item.attributes.groups.findIndex(id => id === deletedGroup.id);
            if (index > -1 && user.id !== deletedGroup.attributes.ownerGroup) {
              return false;
            }
          }
          return true;
        });
        contacts = [...newContacts]
        setContacts(contacts);
        handleCountContacts(contacts);
        filterContact(contacts)
      }
    })
  }

  const deleteUserGroups = () => {
    const url = `${API_HOST}group/user`;
    requestApi(url, 'DELETE', JSON.stringify({ groups: groupsSelected })).then(result => {
      if (!result.message && !result.error) {
        let newGroups = groups.filter(g => { return !groupsSelected.includes(g.id) });
        newGroups.sort((a, b) => {
          return compareString(a.attributes.groupName, b.attributes.groupName)
        })
        const findGroup = groupsSelected.find(g => { return g === contactsFiltered.groupId });
        if (findGroup) {
          contactsFiltered.groupId = ""
          contactsFiltered.iNav = 0;
        }
        setGroupsSelected([])
        setGroups([...newGroups])
        contactsFilteredRef.current = { ...contactsFiltered };
        setContactsFiltered({ ...contactsFiltered })
        setModalDeleteGroup(false);
        setFilterContacts([])
        fetchResultsOnTyping()
      }
    })
  }

  const removeFavorites = () => {
    const url = `${API_HOST}contact/favorite`;
    const body = contactsChose.map(contact => {
      return {
        id: `${contact.id}@favorite`,
        subId: `${user.id}@${contact.phoneType}`
      }
    })
    requestApi(url, 'DELETE', JSON.stringify({
      favorites: body
    })).then(result => {
      if (!result.message && !result.error) {
        removeFavorite(body);
        setModalDeleteContact(false);
        setContactsChose([]);
      }
    })
  }

  const deleteContacts = () => {
    if (contactsFiltered.iNav !== 2) {
      const ids = contactsChose.map(c => { return c.id });
      const url = `${API_HOST}contact`;
      requestApi(url, 'DELETE', JSON.stringify({ ids })).then(result => {
        if (!result.error && !result.message) {
          const newContacts = contacts.filter(c => { return !contactsChose.find(chose => { return chose.id === c.id }) });
          const newContactsFiltered = filterContacts.filter(c => { return !contactsChose.find(chose => { return chose.id === c.id }) });
          setContacts([...newContacts])
          handleCountContacts(newContacts);
          setFilterContacts([...newContactsFiltered])
          setContactsChose([]);
          setModalDeleteContact(false);
        }
      })
    } else {
      removeFavorites();
    }
  }

  const createCompanyGroup = (body) => {
    const url = `${API_HOST}group/company`;
    setLoading(true);
    requestApi(url, 'POST', JSON.stringify(body)).then(result => {
      setLoading(false);
      setShowDetail(false)
      if (!result.error && !result.message) {
        result.map(group => {
          if (groups.findIndex(g => (g.id === group.id)) < 0) {
            groups.push({ id: group.id, attributes: { ...group, userIds: [user.id] } });
          }
        })
        contacts = contacts.map(contact => {
          let groups = contact.attributes.groups || [];
          const organization = result.length > 0 && result[0] || {};
          const department = result.length > 1 && result[1] || {};
          const division = result.length > 2 && result[2] || {};
          const sections = result.length > 3 && result[3] || {};
          if (organization.groupName === contact.attributes.organization &&
            (!department.groupName || (department.groupName && department.groupName === contact.attributes.department)) &&
            (!division.groupName || (division.groupName && division.groupName === contact.attributes.division)) &&
            (!sections.groupName || (sections.groupName && sections.groupName === contact.attributes.sections))) {
            groups.push(result[result.length - 1].id);
          }
          contact.attributes.groups = groups;
          return contact;
        })
        setContacts([...contacts]);
        handleCountContacts(contacts);
        fetchResultsOnTyping();
        groups.sort((a, b) => {
          return compareString(a.attributes.groupName, b.attributes.groupName)
        })
        setGroups([...groups]);
      } else {
        toast.error(t(`${result.error}`));
      }
    })
  }

  const createGroup = (values) => {
    const url = `${API_HOST}group`;
    const findGroup = groups.find(g => { return g.id === values.parent });
    const groupParentIndex = findGroup && findGroup.attributes.groupIndex || 0;
    requestApi(url, 'POST', JSON.stringify({ ...values, groupType: GROUP_ACCESS.private })).then(result => {
      if (!result.error && !result.message) {
        setModalCreateGroup(false);
        let newGroups = [{
          id: result.id, attributes: {
            ...values, groupIndex: !values.parent ? 0 : groupParentIndex + 1,
            owner: user.id, userIds: [user.id]
          }
        }].concat(groups);
        newGroups.sort((a, b) => {
          return compareString(a.attributes.groupName, b.attributes.groupName)
        })
        setGroups([...newGroups])
      } else {
        toast.error(t(`${result.error}`))
      }
    })
  }

  const updateGroup = (id, body, updateObject) => {
    const url = `${API_HOST}group`;
    requestApi(url, 'PUT', JSON.stringify({ id, ...body })).then(result => {
      if (!result.error && !result.message) {
        setGroupColorChose({});
        setUserGroupChose("");
        setModalCreateGroup(false);
        let index = groups.findIndex(g => { return g.id === id });
        const findGroup = groups.find(g => { return g.id === body.parent });
        const groupParentIndex = findGroup && findGroup.attributes.groupIndex || 0;
        if (index > -1) {
          groups[index].attributes = { ...groups[index].attributes, ...body };
          if (updateObject) {
            groups[index].attributes.groupIndex = !body.parent ? 0 : groupParentIndex + 1
          }
          groups.sort((a, b) => {
            return compareString(a.attributes.groupName, b.attributes.groupName)
          })
          setGroups([...groups])
        }
      } else {
        toast.error(t(`${result.error}`))
      }
    })
  }

  const addContactsToGroups = () => {
    const url = `${API_HOST}contact/group`;
    setLoading(true);
    requestApi(url, 'PUT', JSON.stringify({ contacts: contactsChose.map(c => { return c.id }), groups: groupsSelected })).then(result => {
      setLoading(false);
      if (!result.error && !result.message) {
        contactsChose.map(contact => {
          const findIndex = contacts.findIndex(c => { return c.id === contact.id });
          if (findIndex > -1) {
            contacts[findIndex].attributes.groups = contacts[findIndex].attributes.groups || [];
            contacts[findIndex].attributes.groups = Array.from(new Set(contacts[findIndex].attributes.groups.concat(groupsSelected)));
            setContacts([...contacts]);
            handleCountContacts(contacts);
            filterContact([...contacts]);
          }
        })
      }
    })
  }

  const removeContactsFromGroups = () => {
    const url = `${API_HOST}contact/group`;
    setLoading(true);
    const contactIds = contactsChose.map(c => { return c.id });
    requestApi(url, 'DELETE', JSON.stringify({ contacts: contactIds, groups: groupsSelected })).then(result => {
      if (!result.error && !result.message) {
        contacts = contacts.map(c => {
          if (contactIds.includes(c.id)) {
            c.attributes.groups = c.attributes.groups ? c.attributes.groups.filter(g => { return !groupsSelected.includes(g) }) : [];
          }
          return c;
        })
        setContacts([...contacts]);
        handleCountContacts(contacts)
        fetchResultsOnTyping();
      }
      setLoading(false);
    })
  }

  const renderGroupOptions = (group) => {
    const parents = groups.filter(g => { return g.attributes.parent === group.id });
    const groupsArchitecture = () => {
      if (group.attributes.groupIndex === 1) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else if (group.attributes.groupIndex === 2) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else if (group.attributes.groupIndex === 3) {
        return null;
      } else {
        return <option value={group.id}>{group.attributes.groupName}</option>
      }
    }
    return (
      <>
        {groupsArchitecture()}
        {parents.map(g => {
          return renderGroupOptions(g);
        })}
      </>
    )
  }

  useEffect(() => {
    if (contactsFiltered.iNav === 0) {
      setColorGroup("#138eff")
    } else if (contactsFiltered.iNav === 1) {
      setColorGroup("#8d8d8d")
    } else if (contactsFiltered.iNav === 3) {
      const group = groups.find(g => { return g.id === contactsFiltered.groupId });
      setColorGroup(group && group.attributes.color || "#2f7590")
    }

  }, [contactsFiltered.iNav, contactsFiltered.groupId, groups])

  useEffect(() => {
    setDisableExportButton(contactsFiltered.iNav === 4);
  }, [contactsFiltered.iNav])

  useEffect(() => {
    setContacts([]);
    setFilterContacts([]);
    fetchContacts();
    fetchGroups();
    fetchFavorites();
    fetchUserChatDetail();
  }, [forceRefreshContact])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    fetchContacts(true);
  }, [onClickExport])

  useEffect(() => {
    if (firstUpdateFetchDialList.current) {
      firstUpdateFetchDialList.current = false;
      return;
    }
    fetchDialList()
  }, [onFetchDialList]);

  useEffect(() => {
    if (firstUpdateRemoveDialList.current) {
      firstUpdateRemoveDialList.current = false;
      return;
    }
    setListDials([])
  }, [onRemoveDialList]);

  useEffect(() => {
    const parent = groups.filter(g => { return !g.attributes.groupIndex });
    setGroupsParent(parent);
  }, [groups])

  const exportContactCsv = (contacts) => {
    contacts = contacts.map(c => {
      const contact = c.attributes || {};
      const phoneNumbers = contact.phoneNumbers || [];
      const address = contact.addresses && contact.addresses.length ? contact.addresses[0] : {};
      const phone = phoneNumbers.find(p => { return p.type === "phoneNumber" });
      const mobile = phoneNumbers.find(p => { return p.type === "mobileNumber" });
      const extension = phoneNumbers.find(p => { return p.type === "extensionNumber" });
      const options = convertGroupOptions(groups, null, true)
      let groupNames = [];
      contact.groups && contact.groups.map(group => {
        const findGroup = options.find((g) => { return g.key === group });
        if (findGroup) {
          groupNames.push(findGroup.value);
        }
      })
      return {
        id: c.id,
        image: contact.image || "",
        first_name: contact.firstName || "", last_name: contact.lastName || "",
        phonetic_first_name: contact.phoneticFirstName || "", phonetic_last_name: contact.phoneticLastName || "",
        access: c.subId.includes("Private") ? "Private" : "Public",
        title: contact.title || "", section: contact.sections || "", division: contact.division || "",
        department: contact.department || "", organization: contact.organization || "",
        email: contact.email || "",
        phone: phone && phone.number || "", extension: extension && extension.number || "", mobile: mobile && mobile.number || "",
        group: groupNames.join(","),
        street1: address.street1 || "", street2: address.street2 || "", city: address.city || "", state: address.state || "",
        zipcode: address.zip || "", country: address.country || "", memo: contact.memo || ""
      }
    });
    const result = jsonToCSV(contacts);
    var fileName = `Contacts_${moment().format("YYYY-MM-DD-HH:mm:ss")}`;
    var uri = "data:text/csv;charset=utf-8," + encodeURI(result);
    var link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const isMatchTabGroup = (contact, tabChar) => {
    if (contact.firstCharacter === tabChar ) {
      return true;
    } else if (tabChar === TAB_CHAR_OTHER) {
      // # Tab
      if (!contact.firstCharacter) {
        // firstCharacter is blank https://app.clickup.com/t/gvj056
        // #タブは空欄の場合表示する
        return true;
      } else {
        // firstCharacter is Number style char
        return (/^[0-9０-９]/.test(contact.firstCharacter))
      }
    }
    return false;
  }

  const generateContact = ({id, attributes, subId = "@Private"}) => {
    const fullPhoneticName = `${attributes.phoneticLastName ?? ""}${attributes.phoneticFirstName ?? ""}`
    const fullName = `${attributes.lastName ?? ""}${attributes.firstName ?? ""}`
    const compareName = fullPhoneticName || fullName || "";
    const compareFirstCharacter = compareName.charAt(0);
    let firstCharacter = compareFirstCharacter.toUpperCase();
    const findParent = TAB_JAPANESE_CHARS.find(a => {
      return a.child.includes(firstCharacter)
    });
    if (findParent) {
      firstCharacter = findParent.parent;
    }
    if ( !TAB_CHARS.includes(firstCharacter) ) {
      firstCharacter = null;
    }
    return {
      id,
      attributes,
      subId,
      compareFirstCharacter,
      compareName,
      firstCharacter,
      fullPhoneticName,
      fullName
    };
  }

  const filterContact = (contacts, exportCsv) => {
    let items = contacts;
    const { groupId, query, iNav, firstCharacter } = contactsFilteredRef.current;
    let keyCount = "";
    if (groupId) {
      keyCount = groupId;
      items = items.filter(contact => {
        const groups = contact.attributes.groups || [];
        const result = groups.find(g => { return g === groupId });
        return !!result;
      })
    }
    if (!groupId) {
      if (iNav !== 2) {
        if (iNav !== 0) {
          items = items.filter(contact => {
            return !contact.subId.includes("Public")
          })
          keyCount = "private";
        }
        if (iNav !== 1) {
          items = items.filter(contact => {
            return !contact.subId.includes("Private")
          })
          keyCount = "public";
        }
      } else {
        let result = [];
        for (let i = 0; i < favorites.length; i++) {
          const f = favorites[i];
          let findContact = items.find(contact => { return `${contact.id}@favorite` === f.id });
          let newFindContact = findContact && { ...findContact } || null;
          if (newFindContact) {
            if (f.subId.includes("phoneNumber")) {
              newFindContact.phoneType = "phoneNumber"
              newFindContact.isPhoneNumber = true;
            } else if (f.subId.includes("mobileNumber")) {
              newFindContact.phoneType = "mobileNumber"
              newFindContact.isMobileNumber = true;
            } else if (f.subId.includes("extensionNumber")) {
              newFindContact.phoneType = "extensionNumber"
              newFindContact.isExtensionNumber = true;
            }
            result.push({ ...newFindContact });
          }
        }
        items = result;
      }
    }
    if (query) {
      const queryLower = query.toLowerCase()
      items = items.filter(contact => {
        let numberMatches;
        if (contactsFiltered.iNav !== 2) {
          numberMatches = !!(contact.attributes.phoneNumbers && !!contact.attributes.phoneNumbers.find(p => { return p.number && p.number.includes(query) }))
        } else {
          numberMatches = !!(contact.isPhoneNumber && contact.attributes.phoneNumbers.find(p => { return p.type === "phoneNumber" && p.number && p.number.includes(query) }))
            || !!(contact.isMobileNumber && contact.attributes.phoneNumbers.find(p => { return p.type === "mobileNumber" && p.number && p.number.includes(query) })) ||
            !!(contact.isExtensionNumber && contact.attributes.phoneNumbers.find(p => { return p.type === "extensionNumber" && p.number && p.number.includes(query) }))
        }
        return (`${contact.attributes.lastName || ""} ${contact.attributes.firstName || ""}`).toLowerCase().includes(queryLower) ||
          (`${contact.attributes.phoneticLastName || ""} ${contact.attributes.phoneticFirstName || ""}`).toLowerCase().includes(queryLower) ||
          numberMatches ||
          (contact.attributes.organization && contact.attributes.organization.toLowerCase().includes(queryLower))
          || (contact.attributes.title && contact.attributes.title.toLowerCase().includes(queryLower)) ||
          (contact.attributes.department && contact.attributes.department.toLowerCase().includes(queryLower)) ||
          (contact.attributes.sections && contact.attributes.sections.toLowerCase().includes(queryLower)) ||
          (contact.attributes.division && contact.attributes.division.toLowerCase().includes(queryLower)) ||
          (contact.attributes.email && contact.attributes.email.toLowerCase().includes(queryLower));
      })
    }

    if (firstCharacter) {
      items = items.filter(contact => isMatchTabGroup(contact, firstCharacter))
    }

    if (!exportCsv) {
      if (keyCount) {
        countContactFilter = { keyCount, count: items.length };
        setCountContactFilter({ ...countContactFilter });
      }
      items = items.slice(0, 1000);
      setFilterContacts(items)
    }
    else
      exportContactCsv(items);
  }


  const debounce = (executeFunction, wait) => {
    let timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => executeFunction(), wait);
    };
  };

  const fetchResultsOnTyping = debounce(() => filterContact(contacts), 200)

  const handleDialPhone = (number, contact, colorAvatar) => {
    if (tenant.attributes && tenant.attributes.enableConnect && window.ws && window.ws.readyState === 1) {
      setContactDial({ contact, color: colorAvatar })
      localStorage.setItem(CONTACT_CALLING_STORAGE, JSON.stringify({ contact, color: colorAvatar }))
      window.ws.send(encrypt(`dial ${convertPhoneHref(number, user.attributes || {}, true)}`, 'key'));
    } else {
      window.location.href = convertPhoneHref(number, user.attributes || {})
    }
  }

  const fetchDialList = () => {
    const url = `${API_HOST}dial-list`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setListDials(result)
      }
    })
  }
  const handleViewDialDetail = (dial, i) => {
    setShowModalDialDetail(!showModalDialDetail)
    setDialDetail(dial)
    setCurrentDialIndex(i)
  }

  const handleViewPrevDialDetail = () => {
    if (currentDialIndex > 0) {
      currentDialIndex -= 1;
      setDialDetail(listDials[currentDialIndex])
      setCurrentDialIndex(currentDialIndex)
    }
  }

  const handleViewNextDialDetail = () => {
    if (currentDialIndex < listDials.length - 1) {
      currentDialIndex += 1;
      setDialDetail(listDials[currentDialIndex])
      setCurrentDialIndex(currentDialIndex)
    }
  }

  const onUpdateDialSuccess = (id, updatedData) => {
    const index = listDials.findIndex(item => item.id === id);
    if (index === -1) {
      return;
    }

    listDials[index].attributes = { ...listDials[index].attributes, ...updatedData };
    setListDials([...listDials]);
  }

  const windowLocationHref = (remoteNumber) => {
    handleDialPhone(remoteNumber, {}, "")
  }

  const findChildGroups = (id) => {
    let ids = [id];
    let children = groups.filter(g => {
      return g.attributes.parent === id;
    })
    ids = ids.concat(children.map(c => { return c.id }))
    while (children.length) {
      ids.map(newId => {
        children = groups.filter(g => {
          return g.attributes.parent === newId;
        })
        ids = ids.concat(children.map(c => { return c.id }))
      })
    }
    return ids;
  }

  const renderGroups = (group) => {
    const groupSelectIndex = groupsSelected.findIndex(id => { return id === group.id });
    const attributes = group.attributes || {};
    const groupIndex = attributes.groupIndex || 0;
    const isExpanded = groupsExpanded.indexOf(group.id) > -1;
    const isOwner = attributes.userIds.includes(user.id)
    const children = groups.filter(g => { return g.attributes.parent === group.id });
    const channel = teamChannels.find(t => { return t.channel_id === group.attributes.channelId });
    const totalUnread = channel && channel.unread || 0;
    return (
      <>
        <div key={group.id} className={`item ${contactsFiltered.groupId === group.id ? 'active' : ''} index_${groupIndex} noselect`}
          onClick={() => {
            if (group.id !== contactsFiltered.groupId) {
              contactsFiltered.groupId = group.id;
              contactsFiltered.iNav = 3;
              contactsFiltered.firstCharacter = "";
              contactsFilteredRef.current = { ...contactsFiltered };
              setContactsFiltered({ ...contactsFiltered });
              setContactsChose([])
              setFilterContacts([])
              fetchResultsOnTyping();
            }
          }}>
          {!!children.length && <div className="item__toggle" onClick={(e) => {
            e.stopPropagation();
            const index = groupsExpanded.indexOf(group.id);
            if (index > -1) {
              groupsExpanded.splice(index, 1);
            } else {
              groupsExpanded.push(group.id);
            }
            setGroupsExpanded([...groupsExpanded]);
          }}>
            {isExpanded ? iconArrowDown() : iconArrowRight()}
          </div>}
          <div className="private-color" style={{ background: attributes.color || "#2f7590" }}></div>
          <input
            type="checkbox"
            disabled={!isOwner}
            style={{ marginRight: "10px" }}
            checked={groupSelectIndex > -1}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={() => {
              if (groupSelectIndex < 0) groupsSelected.push(group.id);
              else groupsSelected.splice(groupSelectIndex, 1);
              setGroupsSelected([...groupsSelected]);
              // filterContact(contacts, contactsFiltered.groupIds, contactsFiltered.query)
            }}
          />
          <span>{`${attributes.groupName} (${countContactFilter.keyCount === group.id ? countContactFilter.count : countContacts[group.id] || 0})`}</span>
          <div className="item__action">
            {attributes.groupIndex === 3 && (tenant.attributes && tenant.attributes.enableChatMessage) && <div className="message" onClick={() => {
              setContactChat(null)
              setGroupChat(group)
              setShowMessage(true)
            }}>
              <TooltipDenwa title={t("tooltipChatGroup")} placement="top">
                {iconMessage()}
              </TooltipDenwa>
              {!!totalUnread && <div className="message__unread">{totalUnread}</div>}
            </div>}
            {isOwner && <div onClick={() => {
              setModalCreateGroup(true);
              setGroupColorChose(group)
            }}>
              <TooltipDenwa title={t("tooltipEditGroup")} placement="top">
                {iconEdit()}
              </TooltipDenwa>
            </div>}
            { <div onClick={() => { setUserGroupChose(group) }}>
              <TooltipDenwa title={t("tooltipInviteUser")} placement="top">
                {iconAddUser()}
              </TooltipDenwa>
            </div>}
          </div>
        </div>
        {isExpanded && children.map(child => {
          return renderGroups(child)
        })}
      </>
    )
  }
  return (
    <div className="contact-page">
      {loading && <Dimmer></Dimmer>}
      {modalCreateGroup && <ModalColorGroup groupsParent={groupsParent} group={groupColorChose} groups={groups}
        t={t} toggle={() => {
          setModalCreateGroup(false);
          setGroupColorChose({})
        }} confirm={(id, body) => {
          if (id)
            updateGroup(id, body, true);
          else
            createGroup(body)
        }}></ModalColorGroup>}
      {!!userGroupChose && <ModalUserGroup user={user} group={userGroupChose} toggle={() => { setUserGroupChose("") }} confirm={(id, userIds) => {
        updateGroup(id, { userIds }, false);
      }}></ModalUserGroup>}
      {showMessage && <MessagePopup toggle={() => { setShowMessage(!showMessage) }} clearUnread={({ chatId, groupId }) => {
        if (chatId) {
          let findIndex = dmChannels.findIndex(dm => { return dm.user_id === chatId });
          if (findIndex > -1) {
            dmChannels[findIndex].unread = 0;
          }
          setDmChannels([...dmChannels])
        }
        if (groupId) {
          let findIndex = teamChannels.findIndex(dm => { return dm.channel_id === groupId });
          if (findIndex > -1) {
            teamChannels[findIndex].unread = 0;
          }
          setTeamChannels([...teamChannels])
        }
      }}
        group={groupChat} contact={contactChat} user={user} contactUser={contactUser}></MessagePopup>}
      {showDetail && <ModalDetail contact={contactChose} groups={groups} favorites={favorites} user={user} groupsParent={groupsParent}
        colorAvatar={contactChose && contactChose.subId.includes("Public") ? "#138eff" : "#8d8d8d"} t={t}
        createCompanyGroup={createCompanyGroup}
        removeFavorite={removeFavorite}
        addFavorite={addFavorite}
        toggle={() => { setShowDetail(!showDetail) }}
        updateSuccess={(id, body) => {
          const contactIndex = contacts.findIndex(c => { return c.id === id });
          if (contactIndex > -1) {
            contacts[contactIndex] = generateContact({
              id: body.id,
              attributes: {...contacts[contactIndex].attributes, ...body},
              subId: contacts[contactIndex].subId
            })
            contacts.sort(function (a, b) {
              if (a.compareFirstCharacter === b.compareFirstCharacter)
                return compareString(a.compareName, b.compareName)
              else
                return compareString(a.compareFirstCharacter, b.compareFirstCharacter)
            })
            setContacts([...contacts]);
            handleCountContacts(contacts);
            fetchResultsOnTyping();
          }
          setShowDetail(false);
        }}
        createSuccess={(result, body) => {
          setShowDetail(false);
          contacts = [generateContact({id: result.id, attributes: body})].concat(contacts);
          contacts.sort(function (a, b) {
            if (a.compareFirstCharacter === b.compareFirstCharacter)
              return compareString(a.compareName, b.compareName)
            else
              return compareString(a.compareFirstCharacter, b.compareFirstCharacter)
          })
          setContacts([...contacts])
          handleCountContacts(contacts);
          fetchResultsOnTyping();
        }} handleDialPhone={handleDialPhone}></ModalDetail>}
      <ModalDelete className="modal-delete-group" show={modalDeleteGroup} toggle={() => { setModalDeleteGroup(!modalDeleteGroup) }} confirm={deleteGroups} confirmGroup={deleteUserGroups}
        title={t("deleteGroupTitle")} isGroup={true} nestedTitle={t("removeNestedGroup")} isExistNestedGroup={checkExistNestedGroup()}></ModalDelete>
      <ModalDelete show={modalDeleteContact} toggle={() => { setModalDeleteContact(!modalDeleteContact) }} confirm={deleteContacts}
        title={contactsFiltered.iNav === 2 ? t("deleteFavoriteTitle")
          : t("deleteContactTitle")}></ModalDelete>
      <ModalDial show={showDial} toggle={() => { setShowDial(false) }} user={user} handleDialPhone={handleDialPhone}></ModalDial>
      {showModalDialDetail && (
        <ModalDialDetail
          callTypeIconMapping={callTypeIconMapping}
          toggle={() => setShowModalDialDetail(!showModalDialDetail)}
          data={dialDetail}
          handleDialPhone={handleDialPhone}
          user={user}
          onUpdateDialSuccess={onUpdateDialSuccess}
          hasPrev={currentDialIndex > 0}
          hasNext={currentDialIndex < listDials.length - 1}
          handleViewPrevDialDetail={handleViewPrevDialDetail}
          handleViewNextDialDetail={handleViewNextDialDetail}
        />
      )}
      {contactsFiltered.iNav !== 4 && (<Alphabet alphabets={alphabets} charActive={contactsFiltered.firstCharacter} onFilter={(char) => {
        if (char === contactsFiltered.firstCharacter) {
          contactsFiltered.firstCharacter = "";
        } else {
          contactsFiltered.firstCharacter = char;
        }
        contactsFilteredRef.current = { ...contactsFiltered };
        setContactsFiltered({ ...contactsFiltered });
        fetchResultsOnTyping();
      }} />)}
      <div className={`contact-page__left ${hideLeftBar ? "-hide" : ""}`}>
        <div className="contact-page__left__header">
          <input
            type="checkbox"
            checked={groups.length && groupsSelected.length === groups.length}
            onChange={() => {
              if (groupsSelected.length < groups.length) {
                groupsSelected = groups.map(g => { return g.id });
                setGroupsSelected([...groupsSelected])
              } else {
                groupsSelected = [];
                setGroupsSelected([...groupsSelected])
              }
            }}
          />
          <div className="contact-page__left__header__action">
            <div className="icon icon-white" style={{ opacity: groupsSelected.length ? 1 : 0.5 }} onClick={(e) => {
              if (groupsSelected.length)
                setModalDeleteGroup(true);
            }}>
              <TooltipDenwa title={t("tooltipRemoveGroup")}>
                <div className="icon-trash">
                </div>
              </TooltipDenwa>
            </div>
            <div className="icon icon-white" onClick={() => {
              setModalCreateGroup(true)
            }}>
              <TooltipDenwa title={t("tooltipAddGroup")}>
                {plusIcon()}
              </TooltipDenwa>
            </div>
          </div>
        </div>
        <div className="contact-page__left__content">
          <div className={`item ${contactsFiltered.iNav === 2 ? 'active' : ''} noselect`} onClick={() => {
            if (contactsFiltered.iNav !== 2) {
              contactsFiltered.iNav = 2;
              contactsFiltered.groupId = "";
              contactsFiltered.firstCharacter = "";
              setShowDetail(false);
              contactsFilteredRef.current = { ...contactsFiltered };
              setContactsFiltered({ ...contactsFiltered });
              setFilterContacts([])
              setContactsChose([])
              setFilterContacts([])
              fetchResultsOnTyping();
            }
          }}>
            {contactsFiltered.iNav === 2 ? iconStar() : iconStarInactive()}
            <span>{t("favorite")}</span>
          </div>
          {tenant.attributes && tenant.attributes.enableDialList && <div className={`item ${contactsFiltered.iNav === 4 ? 'active' : ''} noselect`} onClick={() => {
            if (contactsFiltered.iNav !== 4) {
              contactsFiltered.iNav = 4;
              contactsFiltered.groupId = "";
              contactsFiltered.firstCharacter = "";
              contactsFilteredRef.current = { ...contactsFiltered };
              setContactsFiltered({ ...contactsFiltered });
              setContactsChose([])
              setFilterContacts([])
              fetchDialList()
            }
          }} >
            {iconDialCallSmall()}
            <span>{t("dialList")}</span>
          </div>}
          <div className={`item ${contactsFiltered.iNav === 0 ? 'active' : ''} noselect`} onClick={() => {
            if (contactsFiltered.iNav !== 0) {
              contactsFiltered.iNav = 0;
              contactsFiltered.groupId = "";
              contactsFiltered.firstCharacter = "";
              contactsFilteredRef.current = { ...contactsFiltered };
              setContactsFiltered({ ...contactsFiltered });
              setContactsChose([])
              setFilterContacts([])
              fetchResultsOnTyping();
            }
          }}>
            <div className="public-color"></div>
            <span>{`${t("publicContact")} (${countContactFilter.keyCount === "public" ? countContactFilter.count : countContacts.public})`}</span></div>
          <div className={`item ${contactsFiltered.iNav === 1 ? 'active' : ''} noselect`} onClick={() => {
            if (contactsFiltered.iNav !== 1) {
              contactsFiltered.iNav = 1;
              contactsFiltered.groupId = "";
              contactsFiltered.firstCharacter = "";
              contactsFilteredRef.current = { ...contactsFiltered };
              setContactsFiltered({ ...contactsFiltered });
              setContactsChose([])
              setFilterContacts([])
              fetchResultsOnTyping();
            }
          }}>
            <div className="private-color"></div>
            <span>{`${t("privateContact")} (${countContactFilter.keyCount === "private" ? countContactFilter.count : countContacts.private})`}</span>
          </div>
          {groupsParent.map(group => {
            return (
              renderGroups(group)
            )
          })}
        </div>
      </div>
      <div className={`contact-page__right  ${hideLeftBar ? "-hide" : ""}`}>
        <div className="contact-page__right__header">
          <input
            type="checkbox"
            checked={filterContacts.length && contactsChose.length === filterContacts.length}
            onChange={() => {
              if (contactsChose.length < filterContacts.length) {
                contactsChose = filterContacts;
              } else {
                contactsChose = [];
              }
              setContactsChose([...contactsChose])
            }}
          />
          <div className="contact-page__right__header__search">
            <div className="search-input">
              <div className="search-input__icon">{searchIcon()}</div>
              <Input placeholder={t("nameAndPhonePlaceHolder")} onChange={(e) => {
                contactsFiltered.query = e.target.value;
                contacts.firstCharacter = "";
                contactsFilteredRef.current = { ...contactsFiltered };
                setContactsFiltered({ ...contactsFiltered });
                setContactsChose([])
                fetchResultsOnTyping();
              }}></Input>
            </div>
          </div>
          <div className="contact-page__right__header__action">
            <div className="icon icon-white" onClick={() => { setHideLeftBar(!hideLeftBar) }}>
              <TooltipDenwa title={t("tooltipCollapseMenu")}>
                {iconSideBar()}
              </TooltipDenwa>
            </div>
            <div className="icon icon-white" style={{ opacity: contactsChose.length && groupsSelected.length ? 1 : 0.5 }}
              onClick={() => {
                if (contactsChose.length && groupsSelected.length)
                  addContactsToGroups()
              }}>
              <TooltipDenwa title={t("tooltipAddContactToGroup")}>
                {iconAddUserGroups()}
              </TooltipDenwa>
            </div>
            <div className="icon icon-white" style={{ opacity: contactsChose.length && groupsSelected.length ? 1 : 0.5 }}
              onClick={() => {
                if (contactsChose.length && groupsSelected.length)
                  removeContactsFromGroups()
              }}>
              <TooltipDenwa title={t("tooltipRemoveContactFromGroup")}>
                {iconRemoveContactGroup()}
              </TooltipDenwa>
            </div>
            <div className="icon icon-white" style={{ opacity: contactsChose.length ? 1 : 0.5 }} onClick={() => {
              if (contactsChose.length)
                setModalDeleteContact(true);
            }}>
              <TooltipDenwa title={t("tooltipRemoveContact")}>
                {trashIcon()}
              </TooltipDenwa>
            </div>
            <div className="icon icon-white" style={{ opacity: contactsFiltered.iNav !== 2 ? 1 : 0.5 }} onClick={() => {
              if (contactsFiltered.iNav !== 2) {
                setContactChose(null);
                setShowDetail(true)
              }
            }}>
              <TooltipDenwa title={t("tooltipAddContact")}>
                {plusIcon()}
              </TooltipDenwa>
            </div>
            <div className="icon icon-white" onClick={() => { setShowDial(true) }}>
              <TooltipDenwa title={t("tooltipCallDial")}>
                {iconDialCall()}
              </TooltipDenwa>
            </div>
          </div>
        </div>
        {contactsFiltered.iNav !== 4 ? (
          <div className="contact-page__right__content" id="contact-content">
            {filterContacts.map((contact, i) => {
              const mobileNumber = contact.attributes.phoneNumbers && contact.attributes.phoneNumbers.find(p => { return p.type == "mobileNumber" }) || {}
              const extensionNumber = contact.attributes.phoneNumbers && contact.attributes.phoneNumbers.find(p => { return p.type == "extensionNumber" }) || {}
              const phoneNumber = contact.attributes.phoneNumbers && contact.attributes.phoneNumbers.find(p => { return p.type == "phoneNumber" }) || {}
              const colorAvatar = contact.subId && contact.subId.includes("Public") ? "#138eff" : "#8d8d8d";
              const dm = dmChannels.find(c => { return contact.attributes.chatId === c.user_id });
              const totalUnread = dm && dm.unread || 0;
              return (
                <div className={`item ${contactChose && contactChose.id === contact.id && (!contactChose.phoneType || contactChose.phoneType === contact.phoneType)
                  && showDetail ? "active" : ""} ${contactsFiltered.iNav === 2 ? "favorite" : ""} noselect`} name={contact.firstCharacter} key={i} onClick={() => {
                    setContactChose(contact);
                    setShowDetail(true);
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {contactsFiltered.iNav !== 2 && <div className="private-color" style={{ background: colorGroup }}></div>}
                    <input
                      type="checkbox"
                      checked={!!contactsChose.find(c => {
                        return c.id === contact.id && (!c.phoneType ||
                          (c.phoneType && c.phoneType === contact.phoneType))
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={() => {
                        const index = contactsChose.findIndex(c => {
                          return c.id === contact.id && (!c.phoneType ||
                            (c.phoneType && c.phoneType === contact.phoneType))
                        });
                        if (index < 0) contactsChose.push(contact)
                        else contactsChose.splice(index, 1);
                        setContactsChose([...contactsChose])
                      }}
                    />
                  </div>
                  <div className="info" style={{
                    width: contactsFiltered.iNav === 2 ? "calc(60% - 40px)" : "30%"
                  }}>
                    <Avatar key={contact.id} contact={contact} colorAvatar={colorAvatar} isMessage={contactUser.id !== contact.id
                      && !!contact.attributes.chatId && (tenant.attributes && tenant.attributes.enableChatMessage)} style={{
                        width: contactsFiltered.iNav === 2 ? "50px" : "60px",
                        height: contactsFiltered.iNav === 2 ? "50px" : "60px"
                      }}></Avatar>
                    {!!totalUnread && <div className="info__unread" style={{
                      top: contactsFiltered.iNav === 2 ? "30px" : "40px",
                      left: contactsFiltered.iNav === 2 ? "30px" : "40px"
                    }}>{totalUnread}</div>}
                    <div className="info__name">
                      <div className="name">{`${contact.attributes.lastName || ""} ${contact.attributes.firstName || ""}`}</div>
                      <div className="phonetic-name">{`${contact.attributes.phoneticLastName || ""} ${contact.attributes.phoneticFirstName || ""}`}</div>
                    </div>
                  </div>
                  {
                    contactsFiltered.iNav !== 2 && <div className="groups desktop">
                      <div className="groups__item">
                        {contact.attributes.organization}
                      </div>
                      <div className="groups__item">
                        {contact.attributes.department}
                      </div>
                      <div className="groups__item">
                        {contact.attributes.title}
                      </div>
                    </div>
                  }
                  <div className="phones">
                    {(contactsFiltered.iNav !== 2 || (contactsFiltered.iNav === 2 && contact.isMobileNumber)) &&
                      <div className="phones__item">
                        <div className="type desktop">{t("mobile")}</div>
                        <a href="#"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDialPhone(mobileNumber.number, contact, colorAvatar)
                          }}>{mobileNumber.number || ""}</a>
                      </div>
                    }
                    {(contactsFiltered.iNav !== 2 || (contactsFiltered.iNav === 2 && contact.isExtensionNumber)) &&
                      <div className="phones__item">
                        <div className="type desktop">{t("extension")}</div>
                        <a href="#" onClick={(e) => {
                          e.stopPropagation()
                          handleDialPhone(extensionNumber.number, contact, colorAvatar)
                        }}>{extensionNumber.number || ""}</a>
                      </div>
                    }
                    {(contactsFiltered.iNav !== 2 || (contactsFiltered.iNav === 2 && contact.isPhoneNumber)) &&
                      <div className="phones__item">
                        <div className="type desktop">{t("phone")}</div>
                        <a href="#" onClick={(e) => {
                          e.stopPropagation()
                          handleDialPhone(phoneNumber.number, contact, colorAvatar)
                        }}>{phoneNumber.number || ""}</a>
                      </div>
                    }
                    {contactUser.id !== contact.id && !!contact.attributes.chatId && (tenant.attributes && tenant.attributes.enableChatMessage) && <div className="phones__item" onClick={(e) => {
                      e.stopPropagation();
                      setContactChat(contact)
                      setGroupChat(null)
                      setShowMessage(true);
                    }}>
                      <div className="message desktop">
                        {iconMessage()}
                      </div>
                      <a href="#">チャット</a>
                    </div>}
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="contact-page__right__dial-content">
            {listDials.map((item, i) => {
              const attributes = item.attributes || {};
              const remoteNumber = attributes.remoteNumber;

              return (
                <div className="item noselect" key={i}>
                  <div className="item__content" onClick={() => handleViewDialDetail(item, i)}>
                    <div className="item__content__info">
                      <div className="item__content__info__icon">{callTypeIconMapping[attributes.callType]}</div>
                      <div className="item__content__info__name">
                        <span className={`name ${attributes.callType === 'missed' ? 'missed' : ''}`} onClick={(e) => {
                          e.stopPropagation()
                          windowLocationHref(remoteNumber)
                        }}>
                          {attributes.remoteDisplayName}
                        </span>
                        <a href="#" onClick={(e) => {
                          e.stopPropagation()
                          windowLocationHref(remoteNumber)
                        }}>{` (${attributes.remoteNumber})`}</a>
                      </div>
                    </div>
                    <div className="item__content__time">{attributes.talkingStartDate && moment(attributes.talkingStartDate).format('YYYY/MM/DD HH:mm:ss')}</div>
                    <div className="item__content__duration desktop">{attributes.talkingDuration ? new Date(attributes.talkingDuration * 1000).toISOString().substr(11, 8) : ''}</div>
                    <div className="item__content__memo desktop">{attributes.memo}</div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div >
  )
}
export default withTranslation('common')(Contact);
