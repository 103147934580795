import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { useEffect, useState } from "react";
import { Button, Input, Spinner } from "reactstrap";
import { API_HOST } from "../../../utils/constants";
import { closeIcon, iconStar, iconStarInactive, plusIcon, trashIcon } from "../../../utils/iconStatics";
import { apiRequestFilePost, requestApi } from "../../../utils/requestHelper";
import { validateRequired } from "../../../utils/validateForm";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import Avatar from "../../../shared/Avatar";
import moment from "moment";
import countries from "../../../assets/countries.json";
import { convertCountry } from "../../../utils/functionHelper";
import TooltipDenwa from "../../../shared/TooltipDenwa";

const ModalDetail = (props) => {
  const { contact, groups } = props;
  let [contactDetail, setContactDetail] = useState(contact || null);
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  let [newGroups, setNewGroups] = useState([]);
  const [groupSelected, setGroupSelected] = useState("");
  let [addresses, setAddresses] = useState([])
  const [showHistory, setShowHistory] = useState(false);
  const [histories, setHistories] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  let [imageInfo, setImageInfo] = useState({
    file: null,
    filePath: ""
  })

  const fetchHistory = () => {
    const numbers = contactDetail.attributes.phoneNumbers.map(number => { return number.number }).join(',');
    const url = `${API_HOST}history?${new URLSearchParams({
      numbers, limit: 10
    })}`;
    setHistories([])
    setLoadingHistory(true)
    requestApi(url).then(result => {
      setLoadingHistory(false)
      if (!result.error && !result.message) {
        setHistories(result);
      }
    })
  }
  useEffect(() => {
    if (contact) {
      const url = `${API_HOST}contact/detail/${contact.id}`;
      setContactDetail(contact);
      requestApi(url).then(result => {
        if (!result.message && !result.error) {
          let detail = result.length ? result[0] : null;
          if (detail) {
            let contactFavorites = props.favorites.filter(f => { return f.id === `${detail.id}@favorite` });
            const isMobileFavorite = !!contactFavorites.find(cf => { return cf.subId.includes("mobileNumber") })
            const isPhoneFavorite = !!contactFavorites.find(cf => { return cf.subId.includes("phoneNumber") })
            const isExtendFavorite = !!contactFavorites.find(cf => { return cf.subId.includes("extensionNumber") })
            detail = { ...detail, isPhoneFavorite, isMobileFavorite, isExtendFavorite };
          }
          setContactDetail(detail);
          setNewGroups(detail.groups && detail.attributes.groups ? [...detail.attributes.groups] : []);
          setAddresses(detail.attributes && detail.attributes.addresses ? [...detail.attributes.addresses] : []);
        }
      })
    }
  }, [contact])

  const onChangeAddressAttr = (value, name, i) => {
    addresses[i][name] = value;
    setAddresses([...addresses])
  }
  const onDropItem = async (files) => {
    const file = files.length && files[0] || null;
    if (file) {
      if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg")
        return toast.error(props.t("imageIncorrect"));
      await Promise.all(files.map(async (file) => {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = await (e => {
          let filePath = e.target.result;
          let image = { filePath, file };
          setImageInfo(image);
        });
      }))
    }
  }

  const addFavorite = (phoneType) => {
    const url = `${API_HOST}contact/favorite`;
    requestApi(url, 'POST', JSON.stringify({ phoneType, contactId: contact.id })).then(result => {
      if (!result.message && !result.error) {
        if (phoneType === "phoneNumber") {
          contactDetail.isPhoneFavorite = true;
        } else if (phoneType === "mobileNumber") {
          contactDetail.isMobileFavorite = true;
        } else if (phoneType === "extensionNumber") {
          contactDetail.isExtendFavorite = true;
        }
        setContactDetail({ ...contactDetail })
        props.addFavorite({
          id: `${contactDetail.id}@favorite`,
          subId: `${props.user.id}@${phoneType}`
        })
      }
    })
  }

  const removeFavorite = (phoneType) => {
    const url = `${API_HOST}contact/favorite`;
    requestApi(url, 'DELETE', JSON.stringify({
      favorites: [{
        id: `${contactDetail.id}@favorite`,
        subId: `${props.user.id}@${phoneType}`
      }]
    })).then(result => {
      if (!result.message && !result.error) {
        props.removeFavorite([{
          id: `${contactDetail.id}@favorite`,
          subId: `${props.user.id}@${phoneType}`
        }]);
        if (phoneType === "phoneNumber") {
          contactDetail.isPhoneFavorite = false;
        } else if (phoneType === "mobileNumber") {
          contactDetail.isMobileFavorite = false;
        } else if (phoneType === "extensionNumber") {
          contactDetail.isExtendFavorite = false;
        }
        setContactDetail({ ...contactDetail })
      }
    })
  }

  const updateContact = (values) => {
    const url = `${API_HOST}contact`;
    const files = imageInfo.file ? { image: [imageInfo.file] } : null
    const phoneNumbers = [{ type: "phoneNumber", number: values.phoneNumber }, { type: "extensionNumber", number: values.extensionNumber }, { type: "mobileNumber", number: values.mobileNumber }];
    const addressesUpdate = addresses.map(add => {
      delete add.index;
      return add;
    })
    let body = {
      id: contact.id,
      firstName: values.firstName,
      lastName: values.lastName,
      department: values.department,
      email: values.email,
      memo: values.memo,
      organization: values.organization,
      title: values.title,
      phoneticLastName: values.phoneticLastName,
      phoneticFirstName: values.phoneticFirstName,
      groups: JSON.stringify(newGroups),
      addresses: JSON.stringify(addressesUpdate),
      phoneNumbers: JSON.stringify(phoneNumbers),
      division: values.division,
      sections: values.sections,
    }
    if (!imageInfo.filePath) {
      body.filePath = null;
    }
    apiRequestFilePost(url, files, body, "PUT").then(result => {
      if (!result.error && !result.message) {
        body.groups = newGroups;
        body.phoneNumbers = phoneNumbers;
        body.image = imageInfo.filePath || contactDetail.image;
        props.updateSuccess(contact.id, body);
      } else {
        toast.error(props.t(`${result.error}`));
      }
    })
  }
  const createContact = (values) => {
    const url = `${API_HOST}contact`;
    const files = imageInfo.file ? { image: [imageInfo.file] } : null
    const phoneNumbers = [{ type: "phoneNumber", number: values.phoneNumber }, { type: "extensionNumber", number: values.extensionNumber }, { type: "mobileNumber", number: values.mobileNumber }];
    let body = {
      firstName: values.firstName,
      lastName: values.lastName,
      department: values.department,
      email: values.email,
      memo: values.memo,
      title: values.title,
      organization: values.organization,
      phoneticLastName: values.phoneticLastName,
      phoneticFirstName: values.phoneticFirstName,
      groups: JSON.stringify(newGroups),
      addresses: JSON.stringify(addresses),
      phoneNumbers: JSON.stringify(phoneNumbers),
      division: values.division,
      sections: values.sections,
    }
    apiRequestFilePost(url, files, body, "POST").then(result => {
      if (!result.error && !result.message) {
        body.groups = newGroups;
        body.phoneNumbers = phoneNumbers;
        body.image = imageInfo.filePath;
        body.addresses = addresses;
        props.createSuccess(result, body)
      } else {
        toast.error(props.t(`${result.error}`));
      }
    })
  }
  const submitForm = (event, errors, values) => {
    if (!errors.length) {
      if (contact) {
        updateContact(values)
      } else {
        createContact(values)
      }
    }
  }

  const convertGroups = (ids) => {
    let names = "";
    ids.map(id => {
      const findGroup = groups.find(g => { return g.id === id });
      if (findGroup) names += `${findGroup.attributes.groupName}, `;
    })
    names = names.slice(0, -2);
    return names;
  }

  const renderGroupOptions = (group) => {
    const parents = groups.filter(g => { return g.attributes.parent === group.id });
    const groupsArchitecture = () => {
      if (group.attributes.groupIndex === 1) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else if (group.attributes.groupIndex === 2) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else if (group.attributes.groupIndex === 3) {
        return <option value={group.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{group.attributes.groupName}</option>
      } else {
        return <option value={group.id}>{group.attributes.groupName}</option>
      }
    }
    return (
      <>
        {groupsArchitecture()}
        {parents.map(g => {
          return renderGroupOptions(g);
        })}
      </>
    )
  }

  const mobileNumber = contactDetail && contactDetail.attributes.phoneNumbers && contactDetail.attributes.phoneNumbers.find(p => { return p.type == "mobileNumber" }) || {}
  const extensionNumber = contactDetail && contactDetail.attributes.phoneNumbers && contactDetail.attributes.phoneNumbers.find(p => { return p.type == "extensionNumber" }) || {}
  const phoneNumber = contactDetail && contactDetail.attributes.phoneNumbers && contactDetail.attributes.phoneNumbers.find(p => { return p.type == "phoneNumber" }) || {}
  return (
    <>
      <div className="modal-detail">
        <div className="modal-detail__close" onClick={props.toggle}>{closeIcon()}</div>
        {loading && <div className="text-center" style={{ marginTop: "20px" }}>
          <Spinner animation="grow" variant="info" />
        </div>}
        {contact && contactDetail && !isEdit &&
          <>
            <div className="modal-detail__info">
              <div className="modal-detail__info__group">
                {convertGroups(contactDetail.attributes.groups || [])}
              </div>
              <div className="button-light" style={{ width: "100px" }} onClick={() => {
                setIsEdit(true);
                setNewGroups(contactDetail && contactDetail.attributes && contactDetail.attributes.groups ? [...contactDetail.attributes.groups] : []);
                setAddresses(contactDetail && contactDetail.attributes && contactDetail.attributes.addresses ? [...contactDetail.attributes.addresses] : []);
                setImageInfo({ filePath: contact.attributes.image, file: null })
              }}>
                {props.t("edit")}
              </div>
            </div>
            <div className="modal-detail__avatar">
              <Avatar contact={contact} colorAvatar={props.colorAvatar} style={{ width: "150px", height: "150px", fontSize: "55px" }}></Avatar>
              <div className="text-name">{`${contactDetail.attributes.lastName || ""} ${contactDetail.attributes.firstName || ""}`}</div>
              <div className="text-group">{`${contactDetail.attributes.phoneticLastName || ""} ${contactDetail.attributes.phoneticFirstName || ""}`}</div>
            </div>
            {(contactDetail.attributes.organization
              || contactDetail.attributes.department
              || contactDetail.attributes.title
              || contactDetail.attributes.division
              || contactDetail.attributes.sections
            ) &&
              <div className="modal-detail__organization">
                <div className="modal-detail__organization__group">{contactDetail.attributes.organization}
                  {!!contactDetail.attributes.organization &&
                    <div style={{ padding: "3px 10px", marginLeft: "30px" }} className="button-primary"
                      onClick={() => {
                        const body = { organization: contactDetail.attributes.organization }
                        props.createCompanyGroup(body)
                      }}>{props.t("groupRegistration")}</div>}</div>
                <div className="modal-detail__organization__group">{contactDetail.attributes.department}
                  {!!contactDetail.attributes.organization && !!contactDetail.attributes.department &&
                    <div style={{ padding: "3px 10px", marginLeft: "30px" }} className="button-primary"
                      onClick={() => {
                        const body = {
                          organization: contactDetail.attributes.organization,
                          department: contactDetail.attributes.department
                        }
                        props.createCompanyGroup(body)
                      }}>{props.t("groupRegistration")}</div>}</div>
                <div className="modal-detail__organization__group">{contactDetail.attributes.division}
                  {!!contactDetail.attributes.organization && !!contactDetail.attributes.department && !!contactDetail.attributes.division &&
                    <div style={{ padding: "3px 10px", marginLeft: "30px" }} className="button-primary"
                      onClick={() => {
                        const body = {
                          organization: contactDetail.attributes.organization,
                          department: contactDetail.attributes.department,
                          division: contactDetail.attributes.division
                        }
                        props.createCompanyGroup(body)
                      }}>{props.t("groupRegistration")}</div>}</div>
                <div className="modal-detail__organization__group">{contactDetail.attributes.sections}
                  {!!contactDetail.attributes.organization && !!contactDetail.attributes.department && !!contactDetail.attributes.division && !!contactDetail.attributes.sections &&
                    <div style={{ padding: "3px 10px", marginLeft: "30px" }} className="button-primary"
                      onClick={() => {
                        const body = {
                          organization: contactDetail.attributes.organization,
                          department: contactDetail.attributes.department,
                          division: contactDetail.attributes.division,
                          sections: contactDetail.attributes.sections
                        }
                        props.createCompanyGroup(body)
                      }}>{props.t("groupRegistration")}</div>}</div>
                <div>{contactDetail.attributes.title}</div>
              </div>}
            <div className="modal-detail__attributes">
              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("mobile")}</div>
                {mobileNumber.number ? <div onClick={() => {
                  contactDetail.isMobileFavorite ?
                    removeFavorite("mobileNumber") :
                    addFavorite("mobileNumber")
                }} className="modal-detail__attributes__item__star">
                  {contactDetail.isMobileFavorite ? iconStar() : iconStarInactive()}
                </div> : null}
                <div className="modal-detail__attributes__item__content">
                  <a href="#" onClick={() => { props.handleDialPhone(mobileNumber.number, contact, true) }}>{mobileNumber.number || ""}</a>
                </div>
              </div>
              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("extension")}</div>
                {extensionNumber.number ? <div onClick={() => {
                  contactDetail.isExtendFavorite ?
                    removeFavorite("extensionNumber") :
                    addFavorite("extensionNumber")
                }} className="modal-detail__attributes__item__star">
                  {contactDetail.isExtendFavorite ? iconStar() : iconStarInactive()}
                </div> : null}
                <div className="modal-detail__attributes__item__content">
                  <a href="#" onClick={() => { props.handleDialPhone(extensionNumber.number, contact, true) }}>{extensionNumber.number || ""}</a>
                </div>
              </div>
              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("phone")}</div>
                {phoneNumber.number ? <div onClick={() => {
                  contactDetail.isPhoneFavorite ?
                    removeFavorite("phoneNumber") :
                    addFavorite("phoneNumber")
                }} className="modal-detail__attributes__item__star">
                  {contactDetail.isPhoneFavorite ? iconStar() : iconStarInactive()}
                </div> : null}
                <div className="modal-detail__attributes__item__content">
                  <a href="#" onClick={() => { props.handleDialPhone(phoneNumber.number, contact, true) }}>{phoneNumber.number || ""}</a>
                </div>
              </div>
              <div className="modal-detail__attributes__item">
                <div className="modal-detail__attributes__item__title">{props.t("email")}</div>
                <div className="modal-detail__attributes__item__content">{contactDetail.attributes.email}</div>
              </div>
            </div>
            <div className="modal-detail__memo">
              { Array.isArray(contactDetail.attributes.addresses) && contactDetail.attributes.addresses.map((address, i) => {
                return (
                  <div className="modal-detail__address">
                    {!!address.zip && <div className="modal-detail__attributes__item">
                      <div className="modal-detail__attributes__item__title">〒</div>
                      <div className="modal-detail__attributes__item__content">{address.zip}</div>
                    </div>}
                    <div>{`${convertCountry(address.country) || ""} ${address.state || ""} ${address.city || ""} ${address.street1 || ""} ${address.street2 || ""}`}</div>
                    <div className="memo" style={{ marginBottom: "10px" }}>
                      {contactDetail.attributes.memo || ""}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="modal-detail__history-call">
              <div className="modal-detail__history-call__title" onClick={() => {
                if (!showHistory) {
                  fetchHistory();
                }
                setShowHistory(!showHistory)
              }}>
                <span>{props.t("history")}</span>
                <span>{showHistory ? "▲" : "▼"}</span>
              </div>
              {showHistory && <div className="modal-detail__history-call__items">
                {loadingHistory && <Spinner animation="grow" variant="info" />}
                {histories.map((history, i) => {
                  return (
                    <div className="item" key={i}>
                      <span className="item__type" style={{
                        color: history.attributes.callType === "missed" ? "#ca1f29" :
                          history.attributes.callType === "in" ? "#56ccf2" : "#1fca82"
                      }}>{history.attributes.callType === "out" ? "発" : "着"}</span>
                      <span className="item__date">{moment(history.attributes.talkingStartDate).format("MM/DD")}</span>
                      <span className="item__number">{history.attributes.remoteNumber}</span>
                      <span className="item__memo">{history.attributes.memo}</span>
                    </div>
                  )
                })}
              </div>}
            </div>
          </>
        }
        {(isEdit || !contact) &&
          <AvForm onSubmit={submitForm}>
            <div className="modal-detail__info">
              <div className="modal-detail__info__avt">
                <Dropzone onDrop={acceptedFiles => { onDropItem(acceptedFiles) }}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="buyer-request-form__attach-inner" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {(contactDetail || (!contactDetail && imageInfo.filePath)) ? <Avatar colorAvatar={props.colorAvatar} contact={contact || { attributes: {} }} filePath={imageInfo.filePath} edit={true}
                        onRemove={() => {
                          setImageInfo({
                            file: null,
                            filePath: ""
                          })
                        }}></Avatar> :
                        <div className="button-light">
                          {props.t("uploadImage")}
                        </div>}
                    </div>
                  )}
                </Dropzone>
                {/* <div className="modal-detail__info__avt__detail">
                  <div className="text-group">
                    <AvField name="phoneticName" placeholder="PhoneticName" value={contactDetail && contactDetail.attributes.phoneticName || ""}></AvField>
                  </div>
                </div> */}
              </div>
              {contact && <div className="button-light" onClick={() => { setIsEdit(false) }} style={{ width: "130px" }}>
                {props.t("cancel")}
              </div>}
            </div>
            <div className="modal-detail__attributes">
              <div className="modal-detail__attributes__item" style={{ alignItems: "unset" }}>
                <div className="modal-detail__attributes__item__title" style={{ paddingBotton: "5px" }}>{props.t("group")}</div>
                <div className="modal-detail__attributes__item__content">
                  <div className="modal-detail__attributes__item__content__group">
                    <Input type="select" onChange={(e) => { setGroupSelected(e.target.value) }} value={groupSelected}>
                      <option value="" disabled={true}>{props.t("groupSelection")}</option>
                      {props.groupsParent.map(group => {
                        return renderGroupOptions(group);
                      })}
                    </Input>
                    {groupSelected && <div className="modal-detail__attributes__item__content__group__icon"
                      onClick={() => {
                        if (!newGroups.includes(groupSelected)) {
                          newGroups.push(groupSelected);
                          setNewGroups([...newGroups])
                        }
                      }}>{plusIcon()}</div>}
                  </div>
                  {newGroups.map((group, index) => {
                    const findGroup = groups.find(g => { return g.id === group })
                    if (!findGroup) return null
                    return (
                      <div className="denwa-badge">
                        {findGroup.attributes.groupName}
                        <div className="icon-remove icon-white" onClick={() => {
                          newGroups.splice(index, 1);
                          setNewGroups([...newGroups])
                        }}>{closeIcon()}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("lastName")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField validate={validateRequired} name="lastName" placeholder={props.t("lastName")} value={contactDetail && contactDetail.attributes.lastName || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("firstName")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField validate={validateRequired} name="firstName" placeholder={props.t("firstName")} value={contactDetail && contactDetail.attributes.firstName || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("phoneticLastName")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="phoneticLastName" placeholder={props.t("phoneticLastName")} value={contactDetail && contactDetail.attributes.phoneticLastName || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("phoneticFirstName")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="phoneticFirstName" placeholder={props.t("phoneticFirstName")} value={contactDetail && contactDetail.attributes.phoneticFirstName || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("mobile")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="mobileNumber" placeholder={props.t("mobile")} value={
                    contactDetail && contactDetail.attributes && contactDetail.attributes.phoneNumbers &&
                    contactDetail.attributes.phoneNumbers.find(p => { return p.type === "mobileNumber" }) &&
                    contactDetail.attributes.phoneNumbers.find(p => { return p.type === "mobileNumber" }).number || ""
                  }></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("extension")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="extensionNumber" placeholder={props.t("extension")} value={
                    contactDetail && contactDetail.attributes && contactDetail.attributes.phoneNumbers &&
                    contactDetail.attributes.phoneNumbers.find(p => { return p.type === "extensionNumber" }) &&
                    contactDetail.attributes.phoneNumbers.find(p => { return p.type === "extensionNumber" }).number || ""
                  }></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item" style={{ marginBottom: "10px" }}>
                <div className="modal-detail__attributes__item__title">{props.t("phone")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="phoneNumber" placeholder={props.t("phone")} value={
                    contactDetail && contactDetail.attributes && contactDetail.attributes.phoneNumbers &&
                    contactDetail.attributes.phoneNumbers.find(p => { return p.type === "phoneNumber" }) &&
                    contactDetail.attributes.phoneNumbers.find(p => { return p.type === "phoneNumber" }).number || ""
                  }></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("email")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="email" placeholder={props.t("email")} value={contactDetail && contactDetail.attributes.email || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("organization")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="organization" placeholder={props.t("organization")} value={contactDetail && contactDetail.attributes.organization || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("department")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="department" placeholder={props.t("department")} value={contactDetail && contactDetail.attributes.department || ""}></AvField>
                </div>
              </div>

              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("division")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="division" placeholder={props.t("division")} value={contactDetail && contactDetail.attributes.division || ""} />
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("section")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="sections" placeholder={props.t("section")} value={contactDetail && contactDetail.attributes.sections || ""} />
                </div>
              </div>

              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("title")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="title" placeholder={props.t("title")} value={contactDetail && contactDetail.attributes.title || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__attributes__item border-top">
                <div className="modal-detail__attributes__item__title">{props.t("memo")}</div>
                <div className="modal-detail__attributes__item__content">
                  <AvField name="memo" placeholder={props.t("memo")} value={contactDetail && contactDetail.attributes.memo || ""}></AvField>
                </div>
              </div>
              <div className="modal-detail__add-address" onClick={() => {
                addresses.push({ type: "mobile" });
                setAddresses([...addresses])
              }}>
                <span>{props.t("addAddress")}</span>
                <div>{plusIcon()}</div>
              </div>
              {addresses.map((address, i) => {
                return (
                  <div className="modal-detail__address">
                    <div className="modal-detail__address__title">
                      <div className="modal-detail__address__title__icon" onClick={() => {
                        addresses.splice(i, 1);
                        setAddresses([...addresses])
                      }}>
                        <TooltipDenwa title={props.t("tooltipRemoveAddress")}>
                          {trashIcon()}
                        </TooltipDenwa>
                      </div>
                    </div>
                    <div className="modal-detail__attributes__item border-top">
                      <div className="modal-detail__attributes__item__title">{props.t("country")}</div>
                      <div className="modal-detail__attributes__item__content" style={{ width: "205px" }}>
                        <Input type="select" onChange={(e) => {
                          onChangeAddressAttr(e.target.value, "country", i)
                        }} value={convertCountry(address.country) ? address.country : ""}>
                          <option value="" disabled={true}>{props.t("country")}</option>
                          {countries.map(c => {
                            return <option value={c.code}>{`${c.code} ${c.japanese}`}</option>
                          })}
                        </Input>
                      </div>
                    </div>
                    <div className="modal-detail__attributes__item border-top">
                      <div className="modal-detail__attributes__item__title">{props.t("zip")}</div>
                      <div className="modal-detail__attributes__item__content">
                        <AvField value={address.zip || ""} name={"zip" + `.${i}`} placeHolder={props.t("zip")}
                          onChange={(e) => { onChangeAddressAttr(e.target.value, "zip", i) }}></AvField>
                      </div>
                    </div>
                    <div className="modal-detail__attributes__item border-top">
                      <div className="modal-detail__attributes__item__title">{props.t("state")}</div>
                      <div className="modal-detail__attributes__item__content">
                        <AvField value={address.state || ""} name={"state" + `.${i}`} placeHolder={props.t("state")}
                          onChange={(e) => { onChangeAddressAttr(e.target.value, "state", i) }}></AvField>
                      </div>
                    </div>
                    <div className="modal-detail__attributes__item border-top">
                      <div className="modal-detail__attributes__item__title">{props.t("city")}</div>
                      <div className="modal-detail__attributes__item__content">
                        <AvField value={address.city || ""} name={"city" + `.${i}`} placeHolder={props.t("city")}
                          onChange={(e) => { onChangeAddressAttr(e.target.value, "city", i) }}></AvField>
                      </div>
                    </div>
                    <div className="modal-detail__attributes__item border-top">
                      <div className="modal-detail__attributes__item__title">{props.t("street1")}</div>
                      <div className="modal-detail__attributes__item__content">
                        <AvField value={address.street1 || ""} name={"street1" + `.${i}`} placeHolder={props.t("street1")}
                          onChange={(e) => { onChangeAddressAttr(e.target.value, "street1", i) }}></AvField>
                      </div>
                    </div>
                    <div className="modal-detail__attributes__item border-top">
                      <div className="modal-detail__attributes__item__title">{props.t("street2")}</div>
                      <div className="modal-detail__attributes__item__content">
                        <AvField value={address.street2 || ""} name={"street2" + `.${i}`} placeHolder={props.t("street2")}
                          onChange={(e) => { onChangeAddressAttr(e.target.value, "street2", i) }}></AvField>
                      </div>
                    </div>
                    <div className="modal-detail__attributes__item border-top">
                      <div className="modal-detail__attributes__item__title">{props.t("type")}</div>
                      <div className="modal-detail__attributes__item__content">
                        <AvField type="select" name={"type" + `.${i}`}
                          value="mobile" onChange={(e) => { onChangeAddressAttr(e.target.value, "type", i) }}>
                          <option key={i} value="mobile">{props.t("mobileType")}</option>
                          <option key={i} value="business">{props.t("businessType")}</option>
                        </AvField>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            {(contactDetail && isEdit || !contact) && <div className="modal-detail__submit">
              <Button className="button-primary" type="submit">{props.t("updateAndSave")}</Button>
            </div>}
          </AvForm>}
      </div>
    </>
  )
}
export default ModalDetail;