import React, { useEffect, useState } from "react";
import { get } from 'lodash';
import moment from "moment";
import { Spinner, Button, Input } from "reactstrap";

import { closeIcon, recorded, edit, save, inCall, outCall, missCall } from "../../../utils/iconStatics";
import { API_HOST } from "../../../utils/constants";
import { requestApi } from "../../../utils/requestHelper";
import AvtDefault from "../../../assets/images/defaultImage.png";
import ToggleSwitch from "../../../shared/ToggleSwitch";
import { withTranslation } from 'react-i18next';

const CALL_TYPE_ICON_MAPPING = {
  'in': inCall(),
  'out': outCall(),
  'missed': missCall(),
};

const ModalDetail = (props) => {
  const DEFAULT_MEMO = [props.t('immediateCutting'), props.t('noReply'), props.t('numberError'), props.t('answerDenied'), props.t('hopeToReturn')];
  const { data, toggle, hasPrev, hasNext, user } = props;
  const attributes = data.attributes || {};

  const [contactDetail, setContactDetail] = useState(null);
  const [loading, setLoading] = useState(false)
  const [isEditMemo, setEditMemo] = useState(true);
  const [memo, setMemo] = useState(undefined);
  const [listCallOut, setListCallOut] = useState([]);
  const [showListOutCall, setShowListOutCall] = useState(attributes.isShowOutCallList);

  useEffect(() => {
    fetchContactDetail()
    fetchListCallOut()
    fetchDialListById()
  }, []);

  useEffect(() => {
    fetchDialListById()
  }, [data]);

  const fetchDialListById = () => {
    const url = `${API_HOST}dial-list/${data.id}`;
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setShowListOutCall(!!result.attributes.isShowOutCallList)
      }
    })
  }

  const fetchContactDetail = () => {
    if (attributes.sipCallId) {
      const url = `${API_HOST}contact/detail/${attributes.sipCallId}`;
      setLoading(true);
      setContactDetail(null);
      requestApi(url).then(result => {
        if (!result.error && !result.message) {
          const detail = result.body && result.body.length ? result.body[0] : null;
          setContactDetail(detail);
        }
        setLoading(false);
      })
    }
  }

  const fetchListCallOut = () => {
    const url = `${API_HOST}history?callType=out`;
    setContactDetail(null);
    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        setListCallOut(result);
      }
    })
  }

  const handleUpdateMemo = () => {
    setEditMemo(false);
    if (!data.id || memo === attributes.memo) {
      return;
    }
    setLoading(true);
    const url = `${API_HOST}dial-list`;
    requestApi(url, 'PUT', JSON.stringify({ id: data.id, memo })).then(result => {
      setLoading(false);
      if (!result.error && !result.message) {
        props.onUpdateDialSuccess(data.id, { memo });
        setMemo(undefined);
      }
    })
  }

  const handleChangeShowListOutCall = () => {
    setLoading(true);
    const url = `${API_HOST}dial-list`;
    requestApi(url, 'PUT', JSON.stringify({ id: data.id, isShowOutCallList: !showListOutCall })).then(result => {
      setLoading(false);
      setShowListOutCall(!showListOutCall)
    })
  }

  const windowLocationHref = (remoteNumber) => {
    props.handleDialPhone(remoteNumber, {}, "")
  }

  return (
    <div className="modal-dial-detail">
      <div className="modal-dial-detail__close" onClick={toggle}>{closeIcon()}</div>
      {loading && (
        <div className="text-center" style={{ marginTop: "20px" }}>
          <Spinner animation="grow" variant="info" />
        </div>
      )}
      <div className="modal-dial-detail__contact">
        <div className="modal-dial-detail__contact__left">
          <img src={get(contactDetail, 'attributes.image', '') || AvtDefault} />
          <div className="modal-dial-detail__contact__left__info">
            <div className="modal-dial-detail__contact__left__info__name">{attributes.remoteDisplayName}</div>
            <a href="#"
              onClick={() => { props.handleDialPhone(attributes.remoteNumber, {}, "") }}
              className="modal-dial-detail__contact__left__info__phone">{attributes.remoteNumber}</a>
          </div>
        </div>
        <div className="modal-dial-detail__contact__right">
          <div className="modal-dial-detail__contact__right__talking-date">{attributes.talkingStartDate && moment(attributes.talkingStartDate).format('YYYY/MM/DD HH:mm:ss')}</div>
          <div className="modal-dial-detail__contact__right__talking-duration">{attributes.talkingDuration ? new Date(attributes.talkingDuration * 1000).toISOString().substr(11, 8) : ''}</div>
        </div>
      </div>

      <div className="modal-dial-detail__history">
        {attributes.memo !== undefined && (
          <div className="modal-dial-detail__history__memo form-group">

            <textarea className="form-control" disabled={!isEditMemo} rows={5} value={memo !== undefined ? memo : attributes.memo} onChange={(e) => setMemo(e.target.value)} />
            {!isEditMemo ? (
              <div className="modal-dial-detail__history__memo__icon" onClick={() => setEditMemo(true)}>{edit()}</div>
            ) : (
              <div className="modal-dial-detail__history__memo__icon">
                <Input
                  className="d-inline-block w-auto"
                  type="select"
                  onChange={(e) => {
                    setMemo(e.target.value)
                  }}
                >
                  <>
                    <option>{props.t("fixedPhrase")}</option>
                    {DEFAULT_MEMO.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </>
                </Input>
                <div className="modal-dial-detail__history__memo__icon__save" onClick={handleUpdateMemo}>{save()}</div>
                {/*<div className="close" onClick={handleCancelMemo}>{closeIcon()}</div>*/}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="modal-dial-detail__actions">
        <Button color="link" onClick={() => { props.handleDialPhone(attributes.remoteNumber, {}, "") }}>{props.t("dial")}</Button>
        <div className="modal-dial-detail__actions__toggle">
          {props.t("done")}
          <ToggleSwitch
            checked={showListOutCall}
            onChange={handleChangeShowListOutCall}
          />
        </div>
        <div className="modal-dial-detail__actions__page">
          <Button color="link" onClick={props.handleViewPrevDialDetail} disabled={!hasPrev}>{props.t("forward")}</Button>
          <Button color="link" className="modal-dial-detail__actions__page__next" onClick={props.handleViewNextDialDetail} disabled={!hasNext}>{props.t("next")}</Button>
        </div>
      </div>
      {showListOutCall && (
        <div className="modal-dial-detail__call-out">
          {listCallOut.map((item, i) => {
            const attributes = item && item.attributes || {}

            return (
              <div className="modal-dial-detail__call-out__item">
                <div className="modal-dial-detail__call-out__item__content">
                  <div className="modal-dial-detail__call-out__item__content__info">
                    <div className="modal-dial-detail__call-out__item__content__info__icon">{CALL_TYPE_ICON_MAPPING[attributes.callType]}</div>
                    <div className="modal-dial-detail__call-out__item__content__info__name">
                      <span className={`name ${attributes.callType === 'missed' ? 'missed' : ''}`} onClick={(e) => {
                        e.stopPropagation()
                        windowLocationHref(attributes.remoteNumber)
                      }}>
                        {attributes.remoteDisplayName}
                      </span>
                      <a href="#" onClick={(e) => {
                        e.stopPropagation()
                        windowLocationHref(attributes.remoteNumber)
                      }}>{` (${attributes.remoteNumber})`}</a>
                    </div>
                  </div>
                  <div className="item__content__duration desktop">{attributes.talkingDuration ? new Date(attributes.talkingDuration * 1000).toISOString().substr(11, 8) : ''}</div>
                  <div className="modal-dial-detail__call-out__item__content__">{attributes.talkingStartDate && moment(attributes.talkingStartDate).format('YYYY/MM/DD HH:mm:ss')}</div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  );
}
export default withTranslation('common')(ModalDetail);