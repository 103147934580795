import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/scss/bootstrap.scss';
import { keyBy } from 'lodash';
import { Col, Input, Row } from "reactstrap";
import './Tenant.scss';
import moment from "moment";
import { edit, plusIcon, searchIcon, trashIcon } from "../../../utils/iconStatics";
import {API_HOST, SOCKET_EVENT_TYPE, USER_ROLE, WSS_HOST} from "../../../utils/constants";
import { apiRequestFilePost, requestApi, getToken } from "../../../utils/requestHelper";
import TenantModal from './TenantModal';
import TenantDetail from './TenantDetail';
import ModalDelete from "../../../shared/ModalDelete";
import { jsonToCSV } from "../../../utils/functionHelper";
import Dimmer from "../../../shared/Dimmer";
import { toast } from "react-toastify";
import Avatar from "./Avatar";
import { withTranslation } from 'react-i18next';
import TooltipDenwa from "../../../shared/TooltipDenwa";

const Tenant = (props) => {
  const [loading, setLoading] = useState(false);
  let [tenants, setTenants] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [tenantSelected, setTenantSelected] = useState({});
  const [filterConditions, setFilterConditions] = useState({ query: '' });
  let [tenantsChose, setTenantsChose] = useState([]);
  let [tenantsFiltered, setTenantsFiltered] = useState([]);
  const checkSuperAdmin = !props.user.attributes || (props.user.attributes && props.user.attributes.email.endsWith("@ageet.com") && props.user.attributes.userRole === USER_ROLE.admin)
  if (!checkSuperAdmin) {
    window.location.href = "/contact"
  }
  const tenantId = props.user?.attributes?.tenantId;
  const firstUpdate = useRef(true);
  const tenantsRef = useRef({});
  const socketRef = useRef(null);
  const token = getToken();

  useEffect(() => {
    fetchTenants();
  }, []);

  useEffect(() => {
    fetchTenants();
  }, [props.forceRefreshContact]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    exportTenantToCSV();
  }, [props.onClickExport]);
  
  useEffect(() => {
    if (WSS_HOST && token) {
      const socket = new WebSocket(`${WSS_HOST}?type=tenant&token=${token}`)
      socketRef.current = socket;
      socket.onopen = function(event) {
        // console.log('[TENANT] socket connect successfully: ', { event })
      };

      socket.onmessage = function(message) {
        try {
          handleReceiveSocketEvent(message);
        } catch (e) {
          // console.error('handleReceiveSocketEvent() error: ', e);
        }
      }
    }
    
    return (() => {
      if (socketRef.current) {
        socketRef.current.send('disconnection');
      }
    })
  }, [token]);
  
  const handleReceiveSocketEvent = (message) => {
    const data = JSON.parse(message.data);
    // console.log('socketMessage: ', data);
    switch (data.eventType) {
      case SOCKET_EVENT_TYPE.TENANT_CREATED:
        return handleSocketCreateTenant(data);
      case SOCKET_EVENT_TYPE.TENANT_UPDATED:
        return handleSocketUpdateTenant(data);
      case SOCKET_EVENT_TYPE.TENANT_DELETED:
        return handleSocketDeleteTenant(data);
      case SOCKET_EVENT_TYPE.TENANT_IMPORTED:
        return handleSocketImportTenant();
      default:
        // console.log('Not handle: ', data);
        return;
    }
  }
  
  const handleSocketCreateTenant = (data) => {
    const body = data ? data.body : {};
    if (!tenantsRef.current[body.id]) {
      tenantsRef.current[body.id] = {
        id: body.id,
        attributes: {
          currentUserCount: 1,
          ...body?.attributes,
          image: body?.attributes?.image
        }
      };
      tenants = { ...tenantsRef.current };
      setTenants(tenants);
      tenantsRef.current = tenants;
      filterTenants(tenants)
    }
  }
  
  const handleSocketUpdateTenant = (data) => {
    const body = data ? data.body : {};
    const id = body.id;
    if (tenantsRef.current[id]) {
      tenantsRef.current[id] = {
        ...tenantsRef.current[id],
        attributes: {
          ...tenantsRef.current[id].attributes,
          ...body.attributes,
          image: body?.attributes?.image || tenantsRef.current[tenantId]?.attributes?.image,
        }
      };
      tenants = { ...tenantsRef.current };
      setTenants(tenants);
      tenantsRef.current = tenants;
      filterTenants(tenants);
    }
  }
  
  const handleSocketDeleteTenant = (data) => {
    const ids = data?.body?.ids || [];
    ids.forEach(id => {
      delete tenantsRef.current[id];
    });
    
    tenants = { ...tenantsRef.current };
    setTenants(tenants);
    tenantsRef.current = tenants;
    filterTenants(tenants)
  }
  
  const handleSocketImportTenant = () => {
    fetchTenants();
  }
  
  const filterTenants = (tenantsList) => {
    let items = tenantsList;
    const { query } = filterConditions;

    if (query) {
      items = Object.values(items).filter(item => {
        const { id, attributes: { tenantName, displayName, emailAdmin, adminId } } = item;

        return (tenantName && tenantName.toLowerCase().includes(query.toLowerCase()))
          // || (displayName && displayName.toLowerCase().includes(query.toLowerCase()))
          || (emailAdmin && emailAdmin.toLowerCase().includes(query.toLowerCase()))
          || (id && id.toLowerCase().includes(query.toLowerCase()))
        // || adminId === query;
      });
    }

    setTenantsFiltered(keyBy(items, 'id'));
  }

  const fetchTenants = () => {
    setLoading(true);
    const url = `${API_HOST}tenant`;

    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        const tenantObjects = keyBy(result, 'id');
        setTenants(tenantObjects);
        tenantsRef.current = tenantObjects;
        filterTenants(tenantObjects);
      }
      setLoading(false)
    })
  };

  const createTenant = (data, imageInfo, files) => {
    setLoading(true);
    const url = `${API_HOST}tenant`;
    // const files = imageInfo.file ? { image: [imageInfo.file] } : null;

    apiRequestFilePost(url, files, data, "POST").then(result => {
      if (!result.error && !result.message) {
        const tenantCreated = {
          id: result.id,
          attributes: {
            ...data,
            currentUserCount: 1,
            image: imageInfo.filePath,
          }
        }
        tenants[result.id] = tenantCreated;
        setTenants(tenants)
        tenantsRef.current = tenants;
        fetchResultsOnTyping();
        setTenantSelected(tenantCreated);
        setShowDetailModal(true);
      } else {
        toast.error(props.t(`${result.error}`));
      }
      setLoading(false)
    })
  }

  const updateTenant = (data, tenantId, imageInfo, files) => {
    setLoading(true);
    const url = `${API_HOST}tenant`;
    const requestBody = { id: tenantId, ...data };
    delete requestBody.enableSso;
    // const files = imageInfo.file ? { image: [imageInfo.file] } : null;

    apiRequestFilePost(url, files, requestBody, 'PUT').then(result => {
      if (!result.error && !result.message) {
        tenants[tenantId] = {
          ...tenants[tenantId],
          attributes: {
            ...tenants[tenantId].attributes,
            ...requestBody,
            image: imageInfo.filePath || tenants[tenantId].attributes.image,
          }
        };
        if (props.tenant.id === requestBody.id) {
          props.setTenant({ id: requestBody.id, attributes: { ...requestBody } })
        }
        setTenants(tenants);
        tenantsRef.current = tenants;
        fetchResultsOnTyping();
      } else {
        toast.error(props.t(`${result.error}`));
      }
      setLoading(false)
    })
  }

  const deleteTenant = () => {
    if (tenantsChose.length > 0) {
      setLoading(true);
      const url = `${API_HOST}tenant`;

      requestApi(url, 'DELETE', JSON.stringify({ ids: tenantsChose })).then(result => {
        if (!result.error && !result.message) {
          tenantsChose.forEach(item => {
            delete tenants[item];
          })
          setTenants(tenants);
          tenantsRef.current = tenants;
        } else {
          toast.error(props.t(`${result.error}`));
        }
        setLoading(false)
        setShowConfirmDeleteModal(false)
      })
    }
  }

  const handleAcceptModal = (data, tenantId, imageInfo, files) => {
    const filesUpload = {};
    // const files = imageInfo.file ? { image: [imageInfo.file] } : null;
    if(imageInfo.file) {
      filesUpload.image = [imageInfo.file];
    }
    // filesUpload = imageInfo.file ? { image: [imageInfo.file] } : null;
    if (files.file1) {
      filesUpload.file1 = [files.file1.file];
    }
    if (files.file2) {
      filesUpload.file2 = [files.file2.file];
    }
    if (files.file3) {
      filesUpload.file3 = [files.file3.file];
    }
    if (files.file4) {
      filesUpload.file4 = [files.file4.file];
    }
    if (files.file5) {
      filesUpload.file5 = [files.file5.file];
    }

    tenantId ? updateTenant(data, tenantId, imageInfo, filesUpload) : createTenant(data, imageInfo, filesUpload);
    setTenantsChose([]);
  }

  const exportTenantToCSV = () => {
    const url = `${API_HOST}tenant`;

    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        const csvData = result.map(item => ({
          id: item.id || '',
          tenantName: item.attributes.tenantName || '',
          currentUserCount: item.attributes.currentUserCount || '',
          maxUserCount: item.attributes.maxUserCount || '',
          emailAdmin: item.attributes.emailAdmin || '',
          displayNameAdmin: item.attributes.displayName || '',
        }));

        const csv = jsonToCSV(csvData);
        const fileName = `tenants_${moment().format("YYYY-MM-DD-HH:mm:ss")}`;
        const uri = "data:text/csv;charset=utf-8," + encodeURI(csv);
        const link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(props.t(`${result.error}`));
      }
    })
  }

  const debounce = (executeFunction, wait) => {
    let timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => executeFunction(), wait);
    };
  };

  const fetchResultsOnTyping = debounce(() => filterTenants(tenants), 200)


  return (
    <div className="tenant-page">
      <TenantModal
        isOpen={showModal}
        data={tenantSelected}
        toggle={() => setShowModal(!showModal)}
        user={props.user}
        handleAcceptModal={handleAcceptModal}
      />
      <TenantDetail
        isOpen={showDetailModal}
        data={tenantSelected}
        toggle={() => setShowDetailModal(false)}
      />
      <ModalDelete
        show={showConfirmDeleteModal}
        toggle={() => { setShowConfirmDeleteModal(!showConfirmDeleteModal) }}
        title={props.t("deleteTenantTitle")}
        confirm={deleteTenant}
      />
      {loading && <Dimmer />}
      <>
        <div className="tenant-page__header">
          <div className="tenant-page__header__search">
            <div className="search-input">
              <div className="search-input__icon">{searchIcon()}</div>
              <Input
                placeholder={props.t("nameIdEmailPlaceHolder")}
                onChange={(e) => {
                  setTenantsChose([]);
                  filterConditions.query = e.target.value;
                  setFilterConditions(filterConditions);
                  fetchResultsOnTyping()
                }}
              />
            </div>
          </div>

          <div className="tenant-page__header__right">
            <div className="tenant-page__header__right__total">{props.t("total")}: {Object.values(tenantsFiltered).length}</div>

            <div className="tenant-page__header__right__action">
              <div className="icon" style={{ opacity: tenantsChose.length ? 1 : 0.5 }} onClick={() => {
                tenantsChose.length && setShowConfirmDeleteModal(true);
              }}>
                <TooltipDenwa title={props.t("tooltipRemoveTenant")}>
                  {trashIcon()}
                </TooltipDenwa>
              </div>
              <div className="icon" onClick={() => {
                setShowModal(true)
                setTenantSelected({})
              }}>
                <TooltipDenwa title={props.t("tooltipAddTenant")}>
                  {plusIcon()}
                </TooltipDenwa>
              </div>
            </div>
          </div>
        </div>
        <div className="tenant-page__content">

          <div className="item">
            <Row className="item__content">
              <div className="item__content__checkbox">
                <input
                  type="checkbox"
                  checked={tenantsChose.length === Object.values(tenants).length}
                  onChange={() => {
                    if (tenantsChose.length < Object.values(tenants).length) {
                      tenantsChose = Object.values(tenants).map(c => { return c.id });
                    } else {
                      tenantsChose = [];
                    }
                    setTenantsChose([...tenantsChose])
                  }}
                />
              </div>
              <Col md={3} className="item__content__info">
                <div className="item__content__info__name">{props.t("tenantName")}</div>
              </Col>
              <Col md={3} className="item__content__time">{props.t("tenantId")}</Col>
              <Col md={3} className="item__content__duration">{props.t("adminEmailAddress")} </Col>
              <Col className="item__content__duration">{props.t("userMaximumNumber")}</Col>
              <Col md={1} className="item__content__action" />
            </Row>
          </div>

          {Object.values(tenantsFiltered).map((item, i) => {
            const { attributes } = item;
            return (
              <div className="item" key={i}>
                <Row className="item__content">
                  <div className="item__content__checkbox">
                    <input
                      type="checkbox"
                      checked={tenantsChose.includes(item.id)}
                      onChange={() => {
                        const index = tenantsChose.indexOf(item.id);
                        if (index < 0) {
                          tenantsChose.push(item.id)
                        } else {
                          tenantsChose.splice(index, 1);
                        }
                        setTenantsChose([...tenantsChose])
                      }}
                    />
                  </div>
                  <Col md={3} className="item__content__info">
                    <Avatar data={attributes} filePath={attributes.image || ''} />
                    <div className="item__content__info__name">{attributes.tenantName}</div>
                  </Col>
                  <Col md={3} className="item__content__time">{item.id}</Col>
                  <Col md={3} className="item__content__duration">{attributes.emailAdmin}</Col>
                  <Col className="item__content__duration">{`${attributes.currentUserCount || 0}/${attributes.maxUserCount || 0}`}</Col>
                  <Col md={1} className="item__content__action" onClick={() => {
                    setShowModal(true)
                    setTenantSelected(item)
                  }}>
                    <TooltipDenwa title={props.t("tooltipEditTenant")}>
                      {edit()}
                    </TooltipDenwa>
                  </Col>
                </Row>
              </div>
            )
          })}
        </div>
      </>
    </div>
  );
}

export default withTranslation('common')(Tenant);
