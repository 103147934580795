import Login from "../components/auth/login/Login";
import Detail from "../components/main/detail/Detail";
import Main from "../components/main/Main";

const routes = [
  { path: '/view/:number', exact: true, name: 'Detail', component: Detail },
  { path: '/login', exact: true, name: 'Login', component: Login },
  { path: '/', name: 'Main', component: Main }
];

export default routes;