import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/scss/bootstrap.scss';
import moment from "moment";

import { searchIcon, trashIcon, inCall, outCall, missCall, detail, iconDialCallSmall, plusIcon } from "../../../utils/iconStatics";
import { Input, Spinner, Toast, Row, Col } from "reactstrap";
import './History.scss';
import {API_HOST, CONTACT_CALLING_STORAGE, SOCKET_EVENT_TYPE, WSS_HOST} from "../../../utils/constants";
import {getToken, requestApi} from "../../../utils/requestHelper";
import ModalDetail from "./ModalDetail";
import ModalDelete from "../../../shared/ModalDelete";
import { convertPhoneHref } from "../../../utils/functionHelper";
import { decrypt, encrypt } from '../../../utils/xTea';
import { withTranslation } from 'react-i18next';
import TooltipDenwa from "../../../shared/TooltipDenwa";
import ModalAddHistory from "./ModalAddHistory";

const callTypeIconMapping = {
  'in': inCall(),
  'out': outCall(),
  'missed': missCall(),
  'before': iconDialCallSmall(),
};

const callTypeFilterMapping = {
  'in': ['in', 'missed'],
  'out': ['out'],
  'all': ['in', 'out', 'missed', 'before'],
};

const History = ({ user, setContactDial, tenant, t }) => {

  let [histories, setHistories] = useState([]);
  let [historiesChose, setHistoriesChose] = useState([]);
  const [loading, setLoading] = useState(false);
  const [historyDetail, setHistoryDetail] = useState({});
  const [historyFilterConditions, setHistoryFilterConditions] = useState({ callType: 'all', query: '' });
  let [filterHistories, setFilterHistories] = useState([]);

  const [showDetail, setShowDetail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showNew, setShowNew] = useState(false);
  
  let historiesRef = useRef([]);
  const socketRef = useRef(null);
  const token = getToken();

  useEffect(() => {
    fetchHistories();
  }, [])
  
  useEffect(() => {
    if (WSS_HOST && token) {
      const socket = new WebSocket(`${WSS_HOST}?type=history&token=${token}`)
      socketRef.current = socket;
      
      socket.onopen = function(event) {
        // console.log('[HISTORY] socket connect successfully: ', { event })
      };
      
      socket.onmessage = function(message) {
        try {
          handleReceiveSocketEvent(message);
        } catch (e) {
          // console.error('handleReceiveSocketEvent() error: ', e);
        }
      }
    }
  
    return (() => {
      if (socketRef.current) {
        socketRef.current.send('disconnection');
      }
    })
  }, [token]);
  
  const handleReceiveSocketEvent = (message) => {
    const data = JSON.parse(message.data);
    // console.log('socketMessage: ', data);
    switch (data.eventType) {
      case SOCKET_EVENT_TYPE.HISTORY_CREATED:
        return handleSocketCreateHistory(data);
      case SOCKET_EVENT_TYPE.HISTORY_UPDATED:
        return handleSocketUpdateHistory(data);
      case SOCKET_EVENT_TYPE.HISTORY_DELETED:
        return handleSocketDeleteHistory(data);
      case SOCKET_EVENT_TYPE.DIAL_LIST_IMPORTED:
        return handleSocketImportDialList()
      default:
        // console.log('Not handle: ', data);
        return;
    }
  }
  
  const handleSocketCreateHistory = (data) => {
    const attributesList = data?.body?.attributesList;
    const list = [];
    if (Array.isArray(attributesList)) {
      attributesList.forEach(history => {
        if (historiesRef.current.findIndex(item => item.id === history.id) === -1) {
          const { id, createdAt, updatedAt } = history;
          delete history.id;
          list.push({ id, createdAt, updatedAt, attributes: history });
        }
      });
      
      const newHistories = historiesRef.current.concat(list);
      histories = [...newHistories];
      historiesRef.current = histories;
      setHistories(histories);
      filterHistory(histories);
    }
  }
  
  const handleSocketUpdateHistory = (data) => {
    const body = data?.body || {};
    const updateIndex = historiesRef.current.findIndex(item => item.id === body.id);
    if (updateIndex > -1) {
      historiesRef.current[updateIndex].attributes = {
        ...historiesRef.current[updateIndex].attributes,
        ...body.attributes
      };
      histories = [...historiesRef.current];
      historiesRef.current = histories;
      setHistories(histories);
      filterHistory(histories);
    }
  }
  
  const handleSocketDeleteHistory = (data) => {
    const deletedHistoryIds = data?.body?.ids || [];
    const newHistories = historiesRef.current.filter(history => !deletedHistoryIds.includes(history.id));
    histories = [...newHistories];
    historiesRef.current = histories;
    setHistories(histories);
    filterHistory(histories);
  }

  const handleSocketImportDialList = () => {
    fetchHistories();
  }
  
  const fetchHistories = () => {
    setLoading(true);
    const url = `${API_HOST}history`;

    requestApi(url).then(result => {
      if (!result.error && !result.message) {
        historiesRef.current = result;
        setHistories(result)
        filterHistory(result)
      }
      setLoading(false)
    })
  }

  const filterHistory = (histories) => {
    let items = JSON.parse(JSON.stringify(histories));
    const { callType, query } = historyFilterConditions;

    if (query) {
      items = items.filter(history => {
        return history.attributes.remoteDisplayName.includes(query) ||
          (history.attributes.remoteNumber.includes(query));
      })
    }

    if (callType) {
      items = items.filter(history => {
        return callTypeFilterMapping[callType].includes(history.attributes.callType);
      });
    }
    
    items = items.sort((a, b) => new Date(b.attributes.talkingStartDate) - new Date(a.attributes.talkingStartDate));
    setFilterHistories(items)
  }

  const debounce = (executeFunction, wait) => {
    let timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => executeFunction(), wait);
    };
  };

  const fetchResultsOnTyping = debounce(() => filterHistory(histories), 200);

  const handleViewDetail = (history) => {
    setHistoryDetail(history);
    setShowDetail(!showDetail);
  }

  const deleteHistories = () => {
    if (historiesChose.length === 0) {
      return;
    }

    const url = `${API_HOST}history`;
    const data = {
      ids: historiesChose,
    }

    requestApi(url, 'DELETE', JSON.stringify(data)).then(result => {
      if (!result.error && !result.message) {
        const newHistories = histories.filter(history => { return !historiesChose.includes(history.id) });
        const newHistoriesFiltered = filterHistories.filter(history => { return !historiesChose.includes(history.id) });

        setShowDelete(false);
        historiesRef.current = [...newHistories];
        setHistories([...newHistories]);
        setFilterHistories([...newHistoriesFiltered]);
        setHistoriesChose([]);
      }
    }).catch(e => {
    });
  }

  const handleClickCallTypeFilter = (callType) => {
    historyFilterConditions.callType = callType;
    setHistoryFilterConditions({
      ...historyFilterConditions,
      callType,
    });
    setHistoriesChose([]);
    filterHistory(histories);
  }

  const onUpdateHistorySuccess = (id, updatedData) => {
    const index = histories.findIndex(item => item.id === id);
    if (index === -1) {
      return;
    }

    histories[index].attributes = { ...histories[index].attributes, ...updatedData };
    historiesRef.current = [...histories];
    setHistories([...histories]);
  }

  const handleDialPhone = (number, contact, colorAvatar) => {
    if (tenant.attributes && tenant.attributes.enableConnect && window.ws && window.ws.readyState === 1) {
      setContactDial({ contact, color: colorAvatar })
      localStorage.setItem(CONTACT_CALLING_STORAGE, JSON.stringify({ contact, color: colorAvatar }))
      window.ws.send(encrypt(`dial ${convertPhoneHref(number, user.attributes || {}, true)}`, 'key'));
    } else {
      window.location.href = convertPhoneHref(number, user.attributes || {})
    }
  }

  const windowLocationHref = (remoteNumber) => {
    handleDialPhone(remoteNumber, {}, "")
  }

  return (
    <div className="history-page">
      {showDetail && (
        <ModalDetail
          data={historyDetail}
          toggle={() => setShowDetail(!showDetail)}
          callTypeIconMapping={callTypeIconMapping}
          onUpdateHistorySuccess={onUpdateHistorySuccess}
          user={user}
          handleDialPhone={handleDialPhone}
        />
      )}
      {showNew && <ModalAddHistory toggle={() => { setShowNew(false) }} onSuccess={(history) => {
        histories.push(history)
        historiesRef.current = [...histories];
        setHistories([...histories])
        setShowNew(false);
        fetchResultsOnTyping();
      }}></ModalAddHistory>}
      <ModalDelete
        show={showDelete}
        title={t('deleteHistoryTitle')}
        toggle={() => { setShowDelete(!showDelete) }}
        confirm={deleteHistories} />

      <div className="history-page__header">
        <input
          type="checkbox"
          checked={historiesChose.length === filterHistories.length}
          onChange={() => {
            if (historiesChose.length < filterHistories.length) {
              historiesChose = filterHistories.map(c => { return c.id });
            } else {
              historiesChose = [];
            }
            setHistoriesChose([...historiesChose])
          }}
        />
        <div className="history-page__header__search">
          <div className="search-input">
            <div className="search-input__icon">{searchIcon()}</div>
            <Input placeholder={t("searchHistoryPlaceHolder")} onChange={(e) => {
              historyFilterConditions.query = e.target.value;
              setHistoryFilterConditions(historyFilterConditions);
              setHistoriesChose([])
              fetchResultsOnTyping();
            }} />
          </div>
        </div>

        <div className="history-page__header__call-type btn-group" role="group" aria-label="Call Type">
          <button type="button" className={`btn btn-default ${historyFilterConditions.callType === 'in' ? 'active' : ''}`} onClick={() => handleClickCallTypeFilter('in')}>{t("incomingCall")}</button>
          <button type="button" className={`btn btn-default ml-1 ${historyFilterConditions.callType === 'out' ? 'active' : ''}`} onClick={() => handleClickCallTypeFilter('out')}>{t("outGoingCall")}</button>
          <button type="button" className={`btn btn-default ml-1 ${historyFilterConditions.callType === 'all' ? 'active' : ''}`} onClick={() => handleClickCallTypeFilter('all')}>{t("all")}</button>
        </div>

        <div className="contact-page__right__header__action">
          <div className="icon icon-white" style={{ opacity: historiesChose.length > 0 ? 1 : 0.5 }} onClick={() => {
            historiesChose.length > 0 && setShowDelete(!showDelete)
          }}>
            <TooltipDenwa title={t("tooltipRemoveHistory")}>
              {trashIcon()}
            </TooltipDenwa>
          </div>
          <div className="icon icon-white" onClick={() => { setShowNew(true) }}>
            {plusIcon()}
          </div>
        </div>
      </div>
      <div className="history-page__content">
        {loading && <div className="text-center" style={{ marginTop: "20px" }}>
          <Spinner animation="grow" variant="info" />
        </div>}
        {filterHistories.sort((a, b) => new Date(b.attributes.talkingStartDate) - new Date(a.attributes.talkingStartDate)).map((item, i) => {
          const attributes = item.attributes || {};
          const remoteNumber = attributes.remoteNumber;

          return (
            <div className="item" key={i}>
              <div className="item__content" onClick={() => handleViewDetail(item)}>
                <div className="item__content__checkbox">
                  <input
                    type="checkbox"
                    checked={historiesChose.includes(item.id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      const index = historiesChose.indexOf(item.id);
                      if (index < 0) {
                        historiesChose.push(item.id)
                      } else {
                        historiesChose.splice(index, 1);
                      }
                      setHistoriesChose([...historiesChose])
                    }}
                  />
                </div>
                <div className="item__content__info">
                  <div className="item__content__info__icon">{callTypeIconMapping[attributes.callType]}</div>
                  <div className="item__content__info__name">
                    <span className={`name ${attributes.callType === 'missed' ? 'missed' : ''}`} onClick={(e) => {
                      e.stopPropagation()
                      windowLocationHref(remoteNumber)
                    }}>
                      {attributes.remoteDisplayName}
                    </span>
                    <a href="#" onClick={(e) => {
                      e.stopPropagation()
                      windowLocationHref(remoteNumber)
                    }}>{` (${attributes.remoteNumber})`}</a>
                  </div>
                </div>
                <div className="item__content__time">{moment(attributes.talkingStartDate).format('YYYY年MM月DD日 HH:mm:ss')}</div>
                <div className="item__content__duration desktop">{attributes.talkingDuration ? new Date(attributes.talkingDuration * 1000).toISOString().substr(11, 8) : ''}</div>
                <div className="item__content__memo desktop">{attributes.memo}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default withTranslation('common')(History);
