import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { Input } from "reactstrap";
import { API_HOST } from "../utils/constants";
import { searchIcon } from "../utils/iconStatics";
import { requestApi } from "../utils/requestHelper";
import { withTranslation } from 'react-i18next';

const ModalUserGroup = (props) => {
  const [users, setUsers] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);
  let [query, setQuery] = useState("")
  let [userIds, setUserIds] = useState([...props.group.attributes.userIds] || [])
  const [showMessage, setShowMessage] = useState(false);
  const fetchUserTenant = () => {
    const url = `${API_HOST}user/tenant`;
    requestApi(url, 'GET').then(result => {
      if (!result.error && !result.message) {
        result = result.filter(r => { return r.id !== props.user.id })
        result = result.map(r => {
          r.disabled = false;
          if (props.group.attributes.ownerGroup !== props.user.id && !!userIds.includes(r.id)) {
            r.disabled = true;
          }
          return r;
        })
        setUsers(result)
        setUsersFiltered(result)
      }
    })
  }
  useEffect(() => {
    fetchUserTenant();
  }, [])
  const filterUser = () => {
    let items = users;
    if (query) {
      items = users.filter(user => { return user.attributes.email.includes(query) || user.attributes.displayName.includes(query) });
    }
    setUsersFiltered([...items]);
  }
  const debounce = (executeFunction, wait) => {
    let timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => executeFunction(), wait);
    };
  };

  const fetchResultsOnTyping = debounce(() => filterUser(), 300)

  const checkMessage = () => {
    let isChecked = false;
    let isUnChecked = false;
    const ids = props.group.attributes.userIds;
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      if (!userIds.includes(id)) {
        isUnChecked = true;
        break;
      }
    }
    for (let i = 0; i < userIds.length; i++) {
      const id = userIds[i];
      if (!ids.includes(id)) {
        isChecked = true;
        break;
      }
    }
    if (isChecked && !isUnChecked) {
      return props.t("onlyInvite");
    } else if (!isChecked && isUnChecked) {
      return props.t("onlyDeleteInv");
    } else if (isChecked && isUnChecked) {
      return props.t("bothInvAndDel");
    }
    return "";
  }

  return (
    <Modal show={!!props.group}>
      {!showMessage ?
        <>
          <ModalBody className="modal-user-group">
            <div className="title">{props.t("inviteUsers")}</div>
            <div className="group-name">{props.group.attributes.groupName}</div>
            <div className="search-input">
              <div className="search-input__icon">{searchIcon()}</div>
              <Input placeholder={props.t("nameOrEmailPlaceHolder")} onChange={(e) => {
                query = e.target.value;
                setQuery(e.target.value);
                fetchResultsOnTyping();
              }}></Input>
            </div>
            {usersFiltered.map((user, i) => {
              return (
                <div key={i} className="user-item">
                  <input
                    type="checkbox"
                    disabled={user.disabled}
                    checked={!!userIds.includes(user.id)}
                    onChange={(e) => {
                      const index = userIds.indexOf(user.id);
                      if (index < 0) {
                        userIds.push(user.id);
                      } else {
                        userIds.splice(index, 1);
                      }
                      setUserIds([...userIds]);
                    }}
                  />
                  <div>{user.attributes.displayName}</div>
                  <div>{user.attributes.email}</div>
                </div>
              )
            })}
          </ModalBody>

          <ModalFooter>
            <Button disabled={!checkMessage()} onClick={() => setShowMessage(true)}>{props.t("yesInviteUsers")}</Button>
            <Button variant="secondary" onClick={props.toggle}>{props.t("noInviteUsers")}</Button>
          </ModalFooter>
        </> : <>
          <ModalBody>
            {checkMessage()}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => props.confirm(props.group.id, userIds)}>{props.t("yes")}</Button>
            <Button variant="secondary" onClick={() => { setShowMessage(false) }}>{props.t("no")}</Button>
          </ModalFooter>
        </>}
    </Modal >
  )
}
export default withTranslation('common')(ModalUserGroup);
